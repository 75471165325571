import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PurchaseOrderSummary {
    @keyColumn() id: string;
    @column() number: bigint;
    @column() name: string;
    @column() createdDate?: Date;
    @column() workOrderId: string;
    @column() amount: number;
    @column() email: string;
    @column() approvedDate: Date;
    @column() sentDate: Date;
    @column() distributorId: string;
    @column() workOrderNumber: bigint;
    @column() workOrderItemId: string;
    @column() workOrderItemName: string;
    @column() tradeId: string;
    @column() tradeName: string;
    @column() createdById?: string;
    @column() createdByName: string;
    @column() createdByEmail: string;
    @column() receivedDate: Date;
    @column() approvedById: string;
    @column() sentById: string;
    @column() receivedById: string;
    @column() approvedByName: string;
    @column() sentByName: string;
    @column() receivedByName: string;
    @column() areaId: string;
    @column() areaName: string;
    @column() purchaseOrderNumber: bigint;
    @column() exportDate: Date;
    @column() quickBooksId: string;
    @column() invoiceDate: Date;
    @column() purchaseOrderStatusId: string;
    @column() status: string;
    @column() workOrderCreatedDate: Date;
    @column() holderName: string;
    @column() partNumbers: string;
    @column() notes: string;
    @column() trackingNumber: string;
    @column() assignedToUserId: string;
    @column() assigedToName: string;
    @column() workOrderStatusId: string;
    @column() workOrderStatus: string;
    @column() holderEmail: string;
    @column() contractorName: string;
    @column() note: string;
    @column() contractorEmail: string;
    @column() vendorNumber: string;
    @column() contactEmail: string;
    @column() contactName: string;
    @column() contactPhone: string;
    @column() contactMobile: string;
    @column() catalogNumber: string;
    @column() lastServiceProNotificationDate: Date;
    @column() lastHomeownerNotificationDate: Date;
    @column() estimatedDeliveryDate: Date;
    @column() contractorId: string;
    @column() contractorPhone: string;
    @column() shipping: number;
    @column() tax: number;
    @column() cashOutOfferAmount: number;
    @column() shipperId: string;
    @column() shipperName: string;
    @column() trackingUrl: string;
    @column() followUpAppointmentRequestSentDate: Date;
    @column() followUpAppointmentRequestAcceptedDate: Date;
    @column() contractorContactViaSMSText: boolean;
    @column() contractorContactViaEmail: boolean;
    @column() propertyAddress1: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() deliveryDate: Date;
    @column() isReturn: boolean;
    @column() dispatchJobId: string;
}