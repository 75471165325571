import { SalesItemModel } from "@cogent/shared/models/plans/sales-item.model";
import {  Plan, PolicyAdjustment } from "@upkeeplabs/models/cogent";
import { PlanItemSummaryClient } from "./plan-item-summary-client.model";

export class PlanClient extends Plan {
    calculatedPrice: number;
    calculatedPriceRecurring: number;
    // basePrice: number;
    // basePriceRecurring: number;
    planStyle: string;


    // Non-persisted properties
    standardItems: SalesItemModel[] = [];
    optionalItems: SalesItemModel[] = [];

    planStandardItems: PlanItemSummaryClient[] = [];
    planOptionalItems: PlanItemSummaryClient[] = [];

    selected: boolean;
    coverageType: CoverageType;
    itemsExpanded = false;
    happyHomiversaryDiscount = 0;
    preferredCustomerDiscount = 0;
    


    // client-side only
    private planStyleObjectPrivate: any;
    get planStyleObject() {
        if (this.planStyle && !this.planStyleObjectPrivate) {
            try {
                this.planStyleObjectPrivate = JSON.parse(this.planStyle);
            } catch (e) {
                console.error('cannot parse plan style');
                console.error(e);
                console.error(this.planStyle);
            }
        }

        return this.planStyleObjectPrivate;
    }

    renewalAdjustments: PolicyAdjustment[];
    renewingAdjustmentsAmount: number;
    renewingAdjustmentsAmountRecurring: number;

    bestValue: boolean;

    promotionAmount?: number;
}

export enum CoverageType {
    RealEstate = 0,
    Homeowner = 1,
    Renewal = 2,
    Sellers = 3,
    PrivateLabel = 4,
    PropertyManagement = 5,
}

export enum DwellingType {
    Condominium = 0,
    SingleFamily = 1,
    Duplex = 2,
    Triplex = 3,
    Quadruplex = 4,
    Mobile = 5,
    Unknown = 6,
    Condo = 7,
    Fourplex = 8,
}


export class PlanGridCategoryModel {
    categoryName: string;
    items: PlanGridItemModel[] = [];
    sort: number;
}

export class PlanGridItemModel {
    salesItemName: string;

    planAvailability: PlanGridItemAvailabilityModel[] = [];
    planItemId: string;
    sort: number;
    limitPerOccurence: number;
}

export class PlanGridItemAvailabilityModel {
    constructor(public available: boolean, public plan: PlanClient = null, public limitPerOccurence: number = null) { }
}








export class PlanGridModel {
    plans: PlanClient[];
    categories: PlanGridCategoryModel[] = [];

    public static getPlanGridModel(plans: PlanClient[]): PlanGridModel {
        const planGridModel = new PlanGridModel();

        planGridModel.plans = plans;

        plans.forEach(plan => {
            plan.standardItems.forEach((standardItem: any) => {
                let category = planGridModel.categories.filter(i1 => i1.categoryName === standardItem.category)[0];
                if (!category) {
                    category = new PlanGridCategoryModel();
                    category.categoryName = standardItem.category;
                    category.sort = standardItem.categorySort;
                    planGridModel.categories.push(category);
                }

                let item = category.items.filter(i1 => i1.salesItemName === standardItem.name)[0];
                if (!item) {
                    item = new PlanGridItemModel();
                    item.salesItemName = standardItem.name;
                    item.planItemId = standardItem.planItemId;
                    item.limitPerOccurence = standardItem.limitPerOccurence
                    item.sort = standardItem.sort;
                    category.items.push(item);
                } else {
                  item.limitPerOccurence = standardItem.limitPerOccurence
                }

                let i = item.planAvailability.length;
                const planCount = plans.indexOf(plan);

                while (i < planCount) {
                    item.planAvailability.push(new PlanGridItemAvailabilityModel(false));
                    i++;
                }
                item.planAvailability.push(new PlanGridItemAvailabilityModel(true, plan, item.limitPerOccurence));
            });

            plan.optionalItems.forEach(optionalItem => {
                if (optionalItem.isDefault) {
                    let category = planGridModel.categories.filter(i1 => i1.categoryName === optionalItem.category)[0];
                    if (!category) {
                        category = new PlanGridCategoryModel();
                        category.categoryName = optionalItem.category;
                        category.sort = optionalItem.categorySort;
                        planGridModel.categories.push(category);
                    }

                    let item = category.items.filter(i1 => i1.salesItemName === optionalItem.name)[0];
                    if (!item) {
                        item = new PlanGridItemModel();
                        item.salesItemName = optionalItem.name;
                        item.planItemId = optionalItem.planItemId;
                        item.sort = optionalItem.sort;
                        category.items.push(item);
                    }

                    let i = item.planAvailability.length;
                    const planCount = plans.indexOf(plan);

                    while (i < planCount) {
                        item.planAvailability.push(new PlanGridItemAvailabilityModel(false));
                        i++;
                    }
                    item.planAvailability.push(new PlanGridItemAvailabilityModel(true, plan));
                }
            });
        });

        planGridModel.categories.forEach(category => {
            category.items.forEach(item => {
                for (let i = item.planAvailability.length; i < plans.length; i++) {
                    item.planAvailability.push(new PlanGridItemAvailabilityModel(false));

                }
            });
            category.items = category.items.sort((a, b) => a.sort > b.sort ? 1 : -1);
        });
        planGridModel.categories = planGridModel.categories.sort((a, b) => a.sort > b.sort ? 1 : -1);

        planGridModel.plans.forEach(p => p.selected = true);
        return planGridModel;
    }


}
