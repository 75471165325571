import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountingApiService } from '@cogent/client/shared/services/api/accounting-api.service';
import { StripeCard } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
@Component({
    selector: 'app-update-customer-credit-card',
    templateUrl: './update-customer-credit-card.component.html',
    styleUrls: ['./update-customer-credit-card.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        PaymentMethodEntryModule,
        MatProgressSpinnerModule,
        PaymentMethodEntryComponent,
    ]
})
export class UpdateCustomerCreditCardComponent implements OnInit {

    customerId: string;
    stripeCardId: string;
    selectedCard: StripeCard;
    saving = false;
    selectedIndex = 0;
    noCard = false;
    cardToReplace: StripeCard;
    loading = false;
    isLegacy = true;

    constructor(private activatedRoute: ActivatedRoute,
        private accountingApi: AccountingApiService,
        private missionService: MissionService) { }

    ngOnInit(): void {
        this.loading = true;
        this.activatedRoute.params.subscribe(params => {
            this.customerId = params.customerId;
            this.stripeCardId = params.stripeCardId;
            console.log(params);
            this.accountingApi.getCard(this.stripeCardId, this.customerId).then(card => {
                console.log(card);
                if (!card) {
                    this.noCard = true;
                } else {
                    this.cardToReplace = card;
                }
                this.loading = false;
            });
        });
    }

    async cardUpdated(card: StripeCard) {
        this.saving = true;
        await this.accountingApi.updateCustomerCreditCard(this.stripeCardId,
            this.customerId,
            card);

        this.saving = false;
        this.selectedIndex = 1;
        this.missionService.showSuccessToast('Card Updated');
    }

}
