import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { AuthorizationRepairItem } from "./authorization-repair-item.model";

@dataSource()
export class DefaultAuthorizationRepairItemsGroup {
    @keyColumn() id: string;
    @column() name: string;
    @column() groupConfig: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;

    private _items: DefaultAuthorizationRepairItemsGroupItem[];

    get items(): DefaultAuthorizationRepairItemsGroupItem[] {
        if (!this._items) {
            try {
                this._items = JSON.parse(this.groupConfig);
            } catch (e) {
                this._items = [];
            }
        }

        return this._items;
    }
    set items(value: DefaultAuthorizationRepairItemsGroupItem[]) {
        this._items = value;
        this.groupConfig = JSON.stringify(value);
    }
}

export class DefaultAuthorizationRepairItemsGroupItem {
    item: AuthorizationRepairItem;
    quantity: number;
}