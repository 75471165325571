import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';

@Component({
    selector: 'app-parts-tracking',
    templateUrl: './parts-tracking.component.html',
    styleUrls: ['./parts-tracking.component.css'],
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule, ]
})
export class PartsTrackingComponent implements OnInit {

    purchaseOrderId: string;
    tracking: any;
    isLegacy = true;
    constructor(private activatedRoute: ActivatedRoute,
        private serviceApi: ServiceApiService) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.purchaseOrderId = params.id;
                this.serviceApi.getPurchaseOrderTracking(this.purchaseOrderId).then(tracking => {
                    this.tracking = tracking;
                });
            }
        });
    }
}
