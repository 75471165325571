<div *ngIf="editMode">
<mat-form-field appearance="outline">
    <mat-label>
        <mat-icon>place</mat-icon>
        &nbsp;&nbsp;Address</mat-label>
    <!-- <mat-icon matPrefix>place</mat-icon> -->
    <input matInput autocomplete="do-not-auto-complete" id="address-entry" [(ngModel)]="addressSearch" [formControl]="myControl"
        [matAutocomplete]="auto" (ngModelChange)="addressSearchChange($event)" name="address">

    <mat-autocomplete (closed)="sendPopupOpen(false)" (opened)="sendPopupOpen(true)" (optionSelected)="optionSelected($event)" autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptionsAny" [value]="option">
            <mat-icon>{{option.iconClass}}</mat-icon> {{option.description}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
</div>
<div *ngIf="!editMode" aflex bottommargin30>

    <address>
        {{address.address1}}<br>
        {{address.address2}}<br *ngIf="address.address2">
        {{address.city}}, {{address.state}} {{address.postalCode}}


    </address>

    <button mat-button nogrow (click)="editMode = true; this.editModeChange.emit(true)">
        <mat-icon>edit</mat-icon>
    </button>
</div>
