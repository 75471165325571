<div class="customer-footer-container">
    <hr>
    <div class="footer-flex-container">
        <div>
            <h2>Company</h2>
            <p>
                <a href="https://elevatehomescriptions.com/about/">About</a>
            </p>
            <p>
                <a href="https://elevatehomescriptions.com/blog/">Blog</a>
            </p>
        </div>
        <div>
            <h2>Contact</h2>
            <p>
                <a href="https://elevatehomescriptions.com/contact/">Email Us</a>
            </p>
            <p>801-804-5132</p>
            <p>
                <a class="email-link" href="mailto:CS@ElevateHomescriptions.com">CS{{ '@' }}ElevateHomescriptions.com</a>
            </p>
        </div>
        <div>
            <h2>Resources</h2>
            <p>
                <a href="https://customers.elevatehomescriptions.com/new-order">Build a Plan</a>
            </p>
            <p>
                <a href="https://customers.elevatehomescriptions.com/find-property">Request Service</a>
            </p>
            <p>
                <a href="https://issuu.com/elevatehomescriptions/docs/elevate_brochure_az_web/1?ff">Arizona Brochure</a>
            </p>
            <p>
                <a href="https://issuu.com/elevatehomescriptions/docs/elevate_brochure_utah_web/1?ff">Utah Brochure</a>
            </p>
        </div>
    </div>
    <hr>
    <div class="additional-info">
        <span>Privacy Policy</span>
        <span>Terms & Conditions</span>
        <span>© {{currentYear}} Elevate Homescriptions</span>
    </div>
</div>
