<div class="container fancy-container ">
    <div class="center" *ngIf="!tracking">
        <mat-spinner style="display: inline-block;"></mat-spinner><br>
        <h2 class="sub-heading">Getting Tracking</h2>
    </div>
    <ng-container *ngIf="tracking">
        <div class="padding">
            <div bottommargin30>
                <div class="center">
                    <img style="height: 150px" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/box-orange-blob.svg">
                </div>
                <div topmargin30 *ngIf="tracking.estimatedDeliveryDate">
                    <div class="data-label">Estimated Delivery Date</div>
                    <h2 class="sub-heading">{{tracking.estimatedDeliveryDate | date: 'shortDate'}}</h2>
                    <!-- <app-calendar-day-viewer maxHeight="125px" [date]="tracking.estimatedDeliveryDate">
                    </app-calendar-day-viewer> -->

                </div>
                <div topmargin30 *ngIf="tracking.deliveryDate">
                    <div class="data-label">Delivery Date</div>
                    <h2 class="sub-heading">{{tracking.deliveryDate | date: 'shortDate'}}</h2>
                    <!-- <app-calendar-day-viewer maxHeight="125px" [date]="tracking.deliveryDate"></app-calendar-day-viewer> -->
                </div>
            </div>
            <div class="data-label">Current Status</div>
            <h1 class="sub-heading" bottommargin30>{{tracking.currentStatus}}</h1>

            <table class="table show-gt-sm">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Location</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let trackingEvent of tracking.trackingEvents">
                        <td>{{trackingEvent.date | date: 'short'}}</td>
                        <td>{{trackingEvent.location}}</td>
                        <td>{{trackingEvent.status}}</td>
                    </tr>
                </tbody>
            </table>
            <ul class="hide-gt-sm tracking-list">
                <li *ngFor="let trackingEvent of tracking.trackingEvents">
                    <div aflex>
                        <div nogrow>
                            {{trackingEvent.date | date: 'shortDate'}}<br>
                            {{trackingEvent.date | date: 'shortTime'}}
                        </div>
                        <div>
                            <h3>{{trackingEvent.location}}</h3>
                            <p>{{trackingEvent.status}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </ng-container>
</div>