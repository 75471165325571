<div class="promo-grid-container">
    <div class="promo-info-container">
        <div>
            <h1 [innerHTML]="data.header"></h1>
            <strong [innerHTML]="data.description"></strong>
        </div>
        <div>
            <button mat-raised-button mat-button color="primary" (click)="applyPromoCode()" [innerHTML]="data.callToActionText || 'Sign up now!'"></button>
            @if (data.altActionText) {
                <p class="alternative-option-text" (click)="closePromo()" [innerHTML]="data.altActionText || 'No, I want to pay full price'"></p>

            }
        </div>
        @if(data.disclaimer) {
            <div>
                <p [innerHTML]="data.disclaimer"></p>
            </div>
        }
    </div>
    <div class="promo-img-container"></div>
</div>
