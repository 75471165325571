import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { MaintenanceService } from "./maintenance-service.model";
import { MaintenanceServiceOfferingAddOn } from "./maintenance-service-offering-add-on.model";

@dataSource()
export class MaintenanceServiceOffering {
    @keyColumn() id: string;
    @column() maintenanceServiceId: string;
    @column() marketingTemplateId: string;
    @column() shortSummary: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() frequencyTypes: string;
    //@column() pricingInputs: string;
    @column() coveredGeographies: string;
    @column() name: string;
    @column() maintenanceServicePricingInputId: string;
    @column() contractLanguage: string;
    @column() filters: string;
    @column() schedulingBufferDays: number;
    @column() appointmentInstructions: string;
    @column() appointmentCancellationGracePeriodDays: number;
    @column() addOnFunctionId: string;
    @column() pricingDisclaimer: string;
    @column() noRefundOnCancellation?: boolean;
    @column() brochureUrl?: string;
    @column() lottieAnimationUrl?: string;
    @column() cancellationFee?: number;
    @column() cancellationFeeMonthsInEffect?: number;
    @column() maintenanceServiceContractLanguageId: string;

    maintenanceService: MaintenanceService;
    template: any;
    addOns: MaintenanceServiceOfferingAddOn[];
}