import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';

@Injectable({ providedIn: 'root' })
export class WebsocketApiService {
    constructor(private api: ApiService) { }
    get websocketEndpoint() {
        return ApiService.endPointWebSocket;
        // return ApiService.endPoint.replace("https:", "wss:") + "ws";
        //return ApiService.endPointNode.replace("https:", "wss:").replace("http:", "ws:") + "ws";
        //return "ws://localhost:3000/websocket/notification";

    }
}