import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@cogent/client/auth';
import { map } from 'rxjs/operators';
import { ApiParameters, MissionService } from '@cogent/client/shared/services/mission-service';
import { ApiEndpoints } from '@cogent/shared/models/common/api-endpoints.model';
export enum ResultExtractionType { Array, None, Single, SingleString }

@Injectable({ providedIn: 'root' })
export class ApiService {

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private mission: MissionService,
    ) { }


    // tslint:disable-next-line: max-line-length
    private static regexDateTime = /^((\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d))$/;

    static endPointDotNet: string;
    static endPointNode: string;
    static endPointWebSocket: string;
    static endPoint: string;
    static isLegacy = true;


    async getApiEndpointDotNet(): Promise<string> {
        if (!ApiService.endPointDotNet) {
            try {
                const result = await this.http
                    .get<any>("/api/hostname").toPromise();
                ApiService.endPointDotNet = `https://${result.hostname}/api/`;
                ApiService.endPointNode = `https://${result.hostname.replace("api.", "api2.")}/api/`;
                ApiService.endPointWebSocket = ApiService.endPointNode.replace("https:", "wss:").replace("http:", "ws:") + "ws";

                if (result.hostname.startsWith("api")) {
                    ApiService.endPoint = `https://app.elevateh.com/api/`;
                } else if (result.hostname.startsWith("test")) {
                    ApiService.endPoint = `https://test-app.elevateh.com/api/`;
                } else if (result.hostname.startsWith("dev")) {
                    ApiService.endPoint = `https://dev-app.elevateh.com/api/`;
                }
            } catch (err) {
                ApiService.endPointDotNet = "https://local.upkeeplabs.com:5002/api/";
                ApiService.endPointNode = "http://localhost:3000/api/";
                ApiService.endPoint = "http://localhost:8000/api/;"
                ApiService.endPointWebSocket = ApiService.endPointNode.replace("https:", "wss:").replace("http:", "ws:") + "ws";
            }
        }
        ApiEndpoints.endPointDotNet = ApiService.endPointDotNet;
        ApiEndpoints.endPointNode = ApiService.endPointNode;
        ApiEndpoints.endPointWebSocket = ApiService.endPointWebSocket;
        return ApiService.endPointDotNet;
    }

    async getApiEndpointNode(): Promise<string> {
        if (!ApiService.endPointNode) {
            await this.getApiEndpointDotNet();
        }
        return ApiService.endPointNode;
    }

    async getApiEndpoint(): Promise<string> {
        if (!ApiService.endPoint) {
            await this.getApiEndpointDotNet();
        }
        return ApiService.endPoint;
    }

    private fixDateStrings(value: any) {
        if (Array.isArray(value)) {
            for (const item of value) {
                this.fixDateStrings(item);
            }
        } else {
            for (const property in value) {
                if (value.hasOwnProperty(property)) {
                    if (Array.isArray(value[property])) {
                        this.fixDateStrings(value[property]);
                    } else if (ApiService.regexDateTime.test(value[property])) {
                        value[property] = new Date(value[property]);
                    }
                }
            }
        }
    }

    logError(error, method, url, body, parameters) {
        if (url && url.indexOf('version_check=') > -1) {
            return;
        }
        this.mission.raiseApiError(new ApiParameters(method, url, parameters));
    }

    log(value: any, name?: string, path?: string) {
        return this.postSingleNode(`common/log`, value, { name, path });
    }

    getArrayDotNet<T = any>(url: string, params: any = null, typeCreator: () => T = null, suppressError = false): Promise<T[]> {
        return this.get<T[]>(url, params, typeCreator, suppressError, ResultExtractionType.Array);
    }

    getArrayNode<T = any>(url: string, params: any = null, typeCreator: () => T = null, suppressError = false): Promise<T[]> {
        return this.get2<T[]>(url, params, typeCreator, suppressError, ResultExtractionType.Array);
    }

    getArray<T = any>(url: string, params: any = null, typeCreator: () => T = null, suppressError = false): Promise<T[]> {
        return this.get3<T[]>(url, params, typeCreator, suppressError, ResultExtractionType.Array);
    }


    getSingleDotNet<T = any>(url: string, params: any = null, typeCreator: () => T = null, suppressError = false): Promise<T> {
        return this.get<T>(url, params, typeCreator, suppressError, ResultExtractionType.Single);
    }

    getSingleStringDotNet<T = any>(url: string, params: any = null, typeCreator: () => T = null, suppressError = false): Promise<T> {
        return this.get<T>(url, params, typeCreator, suppressError, ResultExtractionType.SingleString);
    }

    getSingleNode<T = any>(url: string, params: any = null, typeCreator: () => T = null, suppressError = false): Promise<T> {
        return this.get2<T>(url, params, typeCreator, suppressError, ResultExtractionType.Single);
    }

    getSingle<T = any>(url: string, params: any = null, typeCreator: () => T = null, suppressError = false): Promise<T> {
        return this.get3<T>(url, params, typeCreator, suppressError, ResultExtractionType.Single);
    }

    getTextDotNet(url: string, params: any = null, suppressError = false): Promise<string> {
        return this.get<string>(url, params, null, suppressError, ResultExtractionType.None, 'text');
    }

    getTextNode(url: string, params: any = null, suppressError = false): Promise<string> {
        return this.get2<string>(url, params, null, suppressError, ResultExtractionType.None, 'text');
    }

    getText(url: string, params: any = null, suppressError = false): Promise<string> {
        return this.get3<string>(url, params, null, suppressError, ResultExtractionType.None, 'text');
    }

    private async get<T = any>(url: string, params: any = null, typeCreator: () => any = null, suppressError = false, extractionType = ResultExtractionType.None, responseType = null, retryCount = 0): Promise<T> {
        const originalUrl = url;
        try {
            params = this.transformBody(params);
            const options: any = { params, responseType };
            if (!url.startsWith("http")) {
                url = (await this.getApiEndpointDotNet()) + url;
                if (this.auth && this.auth.authorizationHeader) {
                    options.headers = { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' };
                    options.withCredentials = true;
                }
            }
            const value = await this.http
                .get(url, options)
                .pipe(map(result => this.processApiResult(result, typeCreator, extractionType)))
                .toPromise();

            return value as T;
        } catch (error) {
            console.error({ get: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.get(originalUrl, params, typeCreator, suppressError, extractionType, responseType, retryCount + 1);
            } else {
                this.logError(error, "GET", url, null, params);
                throw error;
            }
        }
    }


    private async get2<T = any>(url: string, params: any = null, typeCreator: () => any = null, suppressError = false, extractionType = ResultExtractionType.None, responseType = null, retryCount = 0): Promise<T> {
        const originalUrl = url;
        try {
            params = this.transformBody(params);
            const options: any = { params, responseType };
            if (!url.startsWith("http")) {
                url = (await this.getApiEndpointNode()) + url;
                if (this.auth && this.auth.authorizationHeader) {
                    options.headers = { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' };
                    options.withCredentials = true;
                }
            }
            const value = await this.http
                .get(url, options)
                .pipe(map(result => this.processApiResult(result, typeCreator, extractionType)))
                .toPromise();

            return value as T;
        } catch (error) {
            console.error({ get: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.get(originalUrl, params, typeCreator, suppressError, extractionType, responseType, retryCount + 1);
            } else {
                this.logError(error, "GET", url, null, params);
                throw error;
            }
        }
    }

    private async get3<T = any>(url: string, params: any = null, typeCreator: () => any = null, suppressError = false, extractionType = ResultExtractionType.None, responseType = null, retryCount = 0): Promise<T> {
        const originalUrl = url;
        try {
            params = this.transformBody(params);
            const options: any = { params, responseType };
            if (!url.startsWith("http")) {
                url = (await this.getApiEndpoint()) + url;
                if (this.auth && this.auth.authorizationHeader) {
                    options.headers = { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' };
                    options.withCredentials = true;
                }
            }
            const value = await this.http
                .get(url, options)
                .pipe(map(result => this.processApiResult(result, typeCreator, extractionType)))
                .toPromise();

            return value as T;
        } catch (error) {
            console.error({ get: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.get(originalUrl, params, typeCreator, suppressError, extractionType, responseType, retryCount + 1);
            } else {
                this.logError(error, "GET", url, null, params);
                throw error;
            }
        }
    }

    countDotNet(url: string, params: any = null, suppressError = false): Promise<number> {
        return this.get(url + "/Count", params, null, suppressError, ResultExtractionType.Single);
    }

    countNode(url: string, params: any = null, suppressError = false): Promise<number> {
        return this.get2(url + "/Count", params, null, suppressError, ResultExtractionType.Single);
    }

    count(url: string, params: any = null, suppressError = false): Promise<number> {
        return this.get3(url + "/Count", params, null, suppressError, ResultExtractionType.Single);
    }

    private transformBody(body, includeNullValues = false): any {
        if (body === null || body === undefined) {
            return null;
        }
        if (typeof body === "string") {
            return body;
        }

        if (Array.isArray(body)) {
            const array = [];
            for (const item of body) {
                array.push(this.transformBody(item));
            }
            return array;
        }

        const transformed: any = {};
        for (const property of Object.getOwnPropertyNames(body)) {
            if (includeNullValues || body[property] !== null) {
                if (body[property] === null) {
                    transformed[property] = body[property];
                } else if (body[property] instanceof Date) {
                    transformed[property] = (body[property] as Date).toISOString();
                } else {
                    transformed[property] = body[property];
                }
            }
        }
        return transformed;
    }

    // returns nothing.
    async patchDotNet(url: string, changes: any, retryCount = 0) {
        const originalUrl = url;
        try {
            return await this.http.patch<void>(
                (await this.getApiEndpointDotNet()) + url,
                this.transformBody(changes, true),
                {
                    headers: this.auth.authorizationHeader ? { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' } : { 'ngsw-bypass': 'true' },
                    withCredentials: true
                }).toPromise();
        } catch (error) {
            console.error({ patch: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.patchDotNet(originalUrl, changes, retryCount + 1);
            } else {
                this.logError(error, "PATCH", url, changes, null);
                throw error;
            }
        }
    }

    async patchNode(url: string, changes: any, retryCount = 0) {
        const originalUrl = url;
        try {
            return await this.http.patch<void>(
                (await this.getApiEndpointNode()) + url,
                this.transformBody(changes, true),
                {
                    headers: this.auth.authorizationHeader ? { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' } : { 'ngsw-bypass': 'true' },
                    withCredentials: true
                }).toPromise();
        } catch (error) {
            console.error({ patch: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.patchDotNet(originalUrl, changes, retryCount + 1);
            } else {
                this.logError(error, "PATCH", url, changes, null);
                throw error;
            }
        }
    }


    async patch(url: string, changes: any, retryCount = 0) {
        const originalUrl = url;
        try {
            return await this.http.patch<void>(
                (await this.getApiEndpoint()) + url,
                this.transformBody(changes, true),
                {
                    headers: this.auth.authorizationHeader ? { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' } : { 'ngsw-bypass': 'true' },
                    withCredentials: true
                }).toPromise();
        } catch (error) {
            console.error({ patch: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.patchDotNet(originalUrl, changes, retryCount + 1);
            } else {
                this.logError(error, "PATCH", url, changes, null);
                throw error;
            }
        }
    }

    async patchSingleDotNet<T = any>(url: string, changes: any, typeCreator: () => any = null, errorHandler: (errors: any[]) => {} = null, retryCount = 0): Promise<T> {
        const originalUrl = url;
        try {
            return await this.http.patch<T>(
                url.toLowerCase().indexOf('https://') === -1 ? (await this.getApiEndpointDotNet()) + url : url,
                this.transformBody(changes, true),
                {
                    headers: this.auth.authorizationHeader ? { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' } : { 'ngsw-bypass': 'true' },
                    withCredentials: true
                })
                .pipe(map(result => this.processApiResult(result, typeCreator, ResultExtractionType.Single, errorHandler)))
                .toPromise();
        } catch (error) {
            console.error({ patchSingle: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.patchSingleDotNet(originalUrl, changes, typeCreator, errorHandler, retryCount + 1);
            } else {
                this.logError(error, "PATCH", url, changes, null);
                throw error;
            }

        }
    }

    async patchSingleNode<T = any>(url: string, changes: any, typeCreator: () => any = null, errorHandler: (errors: any[]) => {} = null, retryCount = 0): Promise<T> {
        const originalUrl = url;
        try {
            return await this.http.patch<T>(
                url.toLowerCase().indexOf('https://') === -1 ? (await this.getApiEndpointNode()) + url : url,
                this.transformBody(changes, true),
                {
                    headers: this.auth.authorizationHeader ? { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' } : { 'ngsw-bypass': 'true' },
                    withCredentials: true
                })
                .pipe(map(result => this.processApiResult(result, typeCreator, ResultExtractionType.Single, errorHandler)))
                .toPromise();
        } catch (error) {
            console.error({ patchSingle: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.patchSingleDotNet(originalUrl, changes, typeCreator, errorHandler, retryCount + 1);
            } else {
                this.logError(error, "PATCH", url, changes, null);
                throw error;
            }

        }
    }


    async patchSingle<T = any>(url: string, changes: any, typeCreator: () => any = null, errorHandler: (errors: any[]) => {} = null, retryCount = 0): Promise<T> {
        const originalUrl = url;
        try {
            return await this.http.patch<T>(
                url.toLowerCase().indexOf('https://') === -1 ? (await this.getApiEndpoint()) + url : url,
                this.transformBody(changes, true),
                {
                    headers: this.auth.authorizationHeader ? { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' } : { 'ngsw-bypass': 'true' },
                    withCredentials: true
                })
                .pipe(map(result => this.processApiResult(result, typeCreator, ResultExtractionType.Single, errorHandler)))
                .toPromise();
        } catch (error) {
            console.error({ patchSingle: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.patchSingleDotNet(originalUrl, changes, typeCreator, errorHandler, retryCount + 1);
            } else {
                this.logError(error, "PATCH", url, changes, null);
                throw error;
            }

        }
    }

    postArrayDotNet<T = any>(url: string, body: any = null, params: any = null, typeCreator: () => T = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<T[]> {
        return this.postDotNet<T[]>(url, body, params, typeCreator, suppressError, ResultExtractionType.Array, errorHandler);
    }

    postArrayNode<T = any>(url: string, body: any = null, params: any = null, typeCreator: () => T = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<T[]> {
        return this.postNode<T[]>(url, body, params, typeCreator, suppressError, ResultExtractionType.Array, errorHandler);
    }

    postArray<T = any>(url: string, body: any = null, params: any = null, typeCreator: () => T = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<T[]> {
        return this.post<T[]>(url, body, params, typeCreator, suppressError, ResultExtractionType.Array, errorHandler);
    }

    postSingleDotNet<T = any>(url: string, body: any = null, params: any = null, typeCreator: () => T = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<T> {
        return this.postDotNet<T>(url, body, params, typeCreator, suppressError, ResultExtractionType.Single, errorHandler);
    }

    postSingleAccountsDotNet<T = any>(url: string, body: any = null, params: any = null, typeCreator: () => T = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<T> {
        throw 'not implemented';
    }



    postSingleNode<T = any>(url: string, body: any = null, params: any = null, typeCreator: () => T = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<T> {
        return this.postNode<T>(url, body, params, typeCreator, suppressError, ResultExtractionType.Single, errorHandler);
    }

    postSingle<T = any>(url: string, body: any = null, params: any = null, typeCreator: () => T = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<T> {
        return this.post<T>(url, body, params, typeCreator, suppressError, ResultExtractionType.Single, errorHandler);
    }

    postIdDotNet(url: string, body: any = null, params: any = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<string> {
        return this.postDotNet<string>(url, body, params, null, suppressError, ResultExtractionType.Single, errorHandler);
    }

    postIdNode(url: string, body: any = null, params: any = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<string> {
        return this.postNode<string>(url, body, params, null, suppressError, ResultExtractionType.Single, errorHandler);
    }

    postId(url: string, body: any = null, params: any = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<string> {
        return this.post<string>(url, body, params, null, suppressError, ResultExtractionType.Single, errorHandler);
    }

    postVoidDotNet(url: string, body: any = null, params: any = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<void> {
        return this.postDotNet<void>(url, body, params, null, suppressError, ResultExtractionType.None, errorHandler);
    }

    postVoidNode(url: string, body: any = null, params: any = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<void> {
        return this.postNode<void>(url, body, params, null, suppressError, ResultExtractionType.None, errorHandler);
    }

    postVoid(url: string, body: any = null, params: any = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<void> {
        return this.post<void>(url, body, params, null, suppressError, ResultExtractionType.None, errorHandler);
    }

    postTextDotNet(url: string, body: any = null, params: any = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<string> {
        return this.postDotNet<string>(url, body, params, null, suppressError, ResultExtractionType.None, errorHandler, 'text');
    }

    postTextNode(url: string, body: any = null, params: any = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<string> {
        return this.postNode<string>(url, body, params, null, suppressError, ResultExtractionType.None, errorHandler, 'text');
    }

    postText(url: string, body: any = null, params: any = null, suppressError = false, errorHandler: (errors: any[]) => {} = null): Promise<string> {
        return this.post<string>(url, body, params, null, suppressError, ResultExtractionType.None, errorHandler, 'text');
    }

    async postDotNet<T = void>(url: string, body: any = null, params: any = null, typeCreator: () => any = null, suppressError = false,
        extractionType = ResultExtractionType.None, errorHandler: (errors: any[]) => {} = null, responseType = null, retryCount = 0): Promise<T> {
        const originalUrl = url;
        try {
            params = this.transformBody(params);
            const options: any = { params, responseType };
            if (!url.startsWith("http")) {
                url = (await this.getApiEndpointDotNet()) + url;
                if (this.auth && this.auth.authorizationHeader) {
                    options.headers = { Authorization: this.auth.authorizationHeader };
                    options.withCredentials = true;
                }
            }
            const value = await this.http
                .post(url, this.transformBody(body), options)
                .pipe(map(result => this.processApiResult(result, typeCreator, extractionType, errorHandler)))
                .toPromise();
            return value as unknown as T;
        } catch (error) {
            console.error({ post: error });
            // if (error && error.status == 504 && retryCount <= 5) {
            //     await new Promise(r => setTimeout(r, retryCount * 1000));
            //     return await this.post(originalUrl, body, params, typeCreator, suppressError, extractionType, errorHandler, responseType, retryCount + 1);
            // } else {
            //     if (!suppressError) { this.logError(error, "POST", url, body, params); }
            // }
            if (!suppressError) {
                this.logError(error, "POST", url, body, params);
                throw error;
            }

        }
    }


    async postNode<T = void>(url: string, body: any = null, params: any = null, typeCreator: () => any = null, suppressError = false,
        extractionType = ResultExtractionType.None, errorHandler: (errors: any[]) => {} = null, responseType = null, retryCount = 0, contentType = null): Promise<T> {
        const originalUrl = url;
        try {
            params = this.transformBody(params);
            const options: any = { params, responseType };
            if (!url.startsWith("http")) {
                url = (await this.getApiEndpointNode()) + url;
                if (this.auth && this.auth.authorizationHeader) {
                    options.headers = { Authorization: this.auth.authorizationHeader };
                    options.withCredentials = true;
                }
                if (!options.headers && contentType) {
                    options.headers = {};
                }
                if (contentType) {
                    options.headers["Content-Type"] = contentType;
                }
            }
            const value = await this.http
                .post(url, this.transformBody(body, true), options)
                .pipe(map(result => this.processApiResult(result, typeCreator, extractionType, errorHandler)))
                .toPromise();
            return value as unknown as T;
        } catch (error) {
            console.error({ post: error });
            // if (error && error.status == 504 && retryCount <= 5) {
            //     await new Promise(r => setTimeout(r, retryCount * 1000));
            //     return await this.post(originalUrl, body, params, typeCreator, suppressError, extractionType, errorHandler, responseType, retryCount + 1);
            // } else {
            //     if (!suppressError) { this.logError(error, "POST", url, body, params); }
            // }
            if (!suppressError) {
                this.logError(error, "POST", url, body, params);
                throw error;
            }

        }
    }


    async post<T = void>(url: string, body: any = null, params: any = null, typeCreator: () => any = null, suppressError = false,
        extractionType = ResultExtractionType.None, errorHandler: (errors: any[]) => {} = null, responseType = null, retryCount = 0, contentType = null): Promise<T> {
        const originalUrl = url;
        try {
            params = this.transformBody(params);
            const options: any = { params, responseType };
            if (!url.startsWith("http")) {
                url = (await this.getApiEndpoint()) + url;
                if (this.auth && this.auth.authorizationHeader) {
                    options.headers = { Authorization: this.auth.authorizationHeader };
                    options.withCredentials = true;
                }
                if (!options.headers && contentType) {
                    options.headers = {};
                }
                if (contentType) {
                    options.headers["Content-Type"] = contentType;
                }
            }
            const value = await this.http
                .post(url, this.transformBody(body, true), options)
                .pipe(map(result => this.processApiResult(result, typeCreator, extractionType, errorHandler)))
                .toPromise();
            return value as unknown as T;
        } catch (error) {
            console.error({ post: error });
            // if (error && error.status == 504 && retryCount <= 5) {
            //     await new Promise(r => setTimeout(r, retryCount * 1000));
            //     return await this.post(originalUrl, body, params, typeCreator, suppressError, extractionType, errorHandler, responseType, retryCount + 1);
            // } else {
            //     if (!suppressError) { this.logError(error, "POST", url, body, params); }
            // }
            if (!suppressError) {
                this.logError(error, "POST", url, body, params);
                throw error;
            }

        }
    }
    private processApiResult<T>(result: any, typeCreator: () => any = null, extractionType = ResultExtractionType.None, errorHandler: (errors: any[]) => {} = null) {
        if (!result) { return result; }

        if (result.errors && result.errors.length && errorHandler) {
            errorHandler(result.errors);
        }
        if (extractionType !== ResultExtractionType.SingleString && typeof result !== 'string') {
            this.fixDateStrings(result);
        }

        if (extractionType === ResultExtractionType.None) {
            return this.convertToType<T>(result, typeCreator);
        }

        if (extractionType === ResultExtractionType.Array) {
            const data = (result as any).data as T[];
            if (!typeCreator) {
                return data;
            }
            const typedArray = [] as T[];
            if (data) {
                for (const item of data) {
                    typedArray.push(this.convertToType<T>(item, typeCreator));
                }
            }
            return typedArray;
        }

        if (extractionType === ResultExtractionType.Single && result && result.data && (result as any).data.length > 0) {
            return this.convertToType<T>((result as any).data[0] as T, typeCreator);
        }

        if (extractionType === ResultExtractionType.SingleString) {

            return (result as any).data[0];
        }

        return null as T;
    }



    processSubResult<T>(result: any, typeCreator: () => any = null, extractionType = ResultExtractionType.None, errorHandler: (errors: any[]) => {} = null) {
        if (!result) { return result; }
        this.fixDateStrings(result);

        if (extractionType === ResultExtractionType.Single) {
            return this.convertToType<T>(result, typeCreator);
        }

        if (extractionType === ResultExtractionType.Array) {
            const data = (result as any) as T[];
            if (!typeCreator) {
                return data;
            }
            const typedArray = [] as T[];
            if (data) {
                for (const item of data) {
                    typedArray.push(this.convertToType<T>(item, typeCreator));
                }
            }
            return typedArray;
        }


        return null as T;
    }

    async putDotNet(url: string, body: any, transformBody = false, retryCount = 0) {
        const originalUrl = url;
        try {
            const options: any = {};
            if (this.auth && this.auth.authorizationHeader) {
                options.headers = { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' };
                options.withCredentials = true;
            }
            const result = await this.http.put<void>(
                (await this.getApiEndpointDotNet()) + url,
                transformBody ? this.transformBody(body) : body, options).toPromise();

            return null;
        } catch (error) {
            console.error({ put: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.putDotNet(originalUrl, body, transformBody, retryCount + 1);
            } else {
                this.logError(error, "PUT", url, body, null);
            }
        }
    }


    async putNode(url: string, body: any, transformBody = false, retryCount = 0) {
        const originalUrl = url;
        try {
            const options: any = {};
            if (this.auth && this.auth.authorizationHeader) {
                options.headers = { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' };
                options.withCredentials = true;
            }
            const result = await this.http.put<void>(
                (await this.getApiEndpointNode()) + url,
                transformBody ? this.transformBody(body) : body, options).toPromise();

            return null;
        } catch (error) {
            console.error({ put: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.putDotNet(originalUrl, body, transformBody, retryCount + 1);
            } else {
                this.logError(error, "PUT", url, body, null);
            }
        }
    }

    async deleteDotNet<T = any>(url: string, typeCreator: () => void = null, extractionType: ResultExtractionType = ResultExtractionType.None, retryCount = 0) {
        const originalUrl = url;
        try {
            const options: any = {};
            if (this.auth && this.auth.authorizationHeader) {
                options.headers = { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' };
                options.withCredentials = true;
            }
            const result = await this.http.delete<void>(
                (await this.getApiEndpointDotNet()) + url, options)
                .pipe(map(r => this.processApiResult(r, typeCreator, extractionType))).toPromise();

            return result as T;
        } catch (error) {
            console.error({ delete: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.deleteDotNet(originalUrl, typeCreator, extractionType, retryCount + 1);
            } else {
                this.logError(error, "DELETE", url, null, null);
            }
        }
    }

    async deleteNode<T = any>(url: string, typeCreator: () => void = null, extractionType: ResultExtractionType = ResultExtractionType.None, retryCount = 0) {
        const originalUrl = url;
        try {
            const options: any = {};
            if (this.auth && this.auth.authorizationHeader) {
                options.headers = { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' };
                options.withCredentials = true;
            }
            const result = await this.http.delete<void>(
                (await this.getApiEndpointNode()) + url, options)
                .pipe(map(r => this.processApiResult(r, typeCreator, extractionType))).toPromise();

            return result as T;
        } catch (error) {
            console.error({ delete: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.deleteDotNet(originalUrl, typeCreator, extractionType, retryCount + 1);
            } else {
                this.logError(error, "DELETE", url, null, null);
            }
        }
    }

    async unDeleteDotNet(url: string, retryCount = 0) {
        const originalUrl = url;
        try {
            return await this.http.patch<void>(
                (await this.getApiEndpointDotNet()) + url,
                { deletedDate: null },
                {
                    headers: { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' },
                    withCredentials: true
                }).toPromise();
        } catch (error) {
            console.error({ unDelete: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.unDeleteDotNet(originalUrl, retryCount + 1);
            } else {
                this.logError(error, "PATCH", url, null, null);
            }
        }
    }


    async unDeleteNode(url: string, retryCount = 0) {
        const originalUrl = url;
        try {
            return await this.http.patch<void>(
                (await this.getApiEndpointNode()) + url + '?includeDelete=true',
                { deletedDate: null },
                {
                    headers: { Authorization: this.auth.authorizationHeader, 'ngsw-bypass': 'true' },
                    withCredentials: true
                }).toPromise();
        } catch (error) {
            console.error({ unDelete: error });
            if (error && error.status == 504 && retryCount <= 5) {
                await new Promise(r => setTimeout(r, retryCount * 1000));
                return await this.unDeleteDotNet(originalUrl, retryCount + 1);
            } else {
                this.logError(error, "PATCH", url, null, null);
            }
        }
    }

    convertToType<T>(value: any, typeCreator: () => T = null): T {
        if (value == null || value === undefined) {
            return value;
        }
        if (typeCreator) {
            const typedValue: T = typeCreator();
            for (const property of Object.getOwnPropertyNames(value)) {
                try {
                    typedValue[property] = value[property];
                } catch {
                    // console.error("error copying property " + property);
                }
            }
            return typedValue;
        } else {
            return value;
        }
    }

    public flattenStringArguments(array: string[]) {
        let results = '';
        if (array && array.length > 0) {

            array.forEach(item => {
                if (results !== '') {
                    results += ',';
                }
                results += item;
            });

        }
        return results;
    }
}
