<mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs nopadding">
    <mat-tab label="First">
        <ng-container *ngIf="customerId">
            <div class="container">
                <ng-container *ngIf="loading">
                    <div class="center">
                        <mat-spinner style="display: table; margin: 0 auto"></mat-spinner>
                        <br>
                        Loading...
                    </div>
                </ng-container>
                <ng-container *ngIf="!loading">
                    <ng-container *ngIf="!noCard">
                        <h1 class="page-title white">
                            <span class="large-print">Update</span><br><span class="little">Payment</span>
                        </h1>

                        <div style="text-align: center;">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/credit-card-circle.png"
                                style="text-align: center;height: 150px;margin-bottom: 40px;">
                        </div>
                        <p class="info" *ngIf="cardToReplace">
                            Your {{cardToReplace.brand}} card ending in {{cardToReplace.last4}} is set to expire on
                            {{cardToReplace.exp_month}}/{{cardToReplace.exp_year}}.
                        </p>
                        <app-payment-method-entry *ngIf="!saving" paymentDescription="none" [newCustomerMode]="true"
                            (selectedCardChange)="cardUpdated($event)" newCardButtonText="Update Credit Card"
                            [(selectedCard)]="selectedCard" [paymentAmount]="0" [customerId]="customerId">
                        </app-payment-method-entry>

                        <div *ngIf="saving" style="display: table; margin: 0 auto; text-align: center;">
                            <mat-spinner></mat-spinner>
                            <br>

                            Updating Credit Card
                        </div>
                    </ng-container>
                    <ng-container *ngIf="noCard && !cardToReplace">
                        <div class="center">
                            <img style="height: 150px;" src="angular/projects/customers/src/assets/images/magnifying-glass-circle.png/magnifying-glass-circle.png"><br>
                            <h2 topmargin30>Hmmmm, we couldn't find that card.</h2>
                            <p>It may be that the card is being updated, or we are currently having issues.</p>
                        </div>
                    </ng-container>
                </ng-container>


            </div>
        </ng-container>
    </mat-tab>
    <mat-tab label="Second">
        <div class="container">
            <div id="thankYouCard">
                <h1 class="sub-heading" style="text-align: center;">Your Card Was Updated</h1>
                <img id="thank-you-image" style="height: 150px;" src="angular/projects/customers/src/assets/images/magnifying-glass-circle.png/thumbs-up.png">
                <p id="thank-you-text">Relax, you've go the peace-of-mind that comes with an Elevate Home Warranty.</p>
                <img src="angular/projects/customers/src/assets/images/magnifying-glass-circle.png/wave.svg">
            </div>
        </div>
    </mat-tab>
</mat-tab-group>