import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PolicySummaryV2 {
    @keyColumn() id: string;
    @column() holderName: string;
    @column() coHolderName: string;
    @column() holderEmail: string;
    @column() mobileNumber: string;
    @column() homeNumber: string;
    @column() propertyAddress1: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() mailingAddress1: string;
    @column() mailingAddress2: string;
    @column() mailingCity: string;
    @column() mailingState: string;
    @column() mailingPostalCode: string;
    @column() planName: string;
    @column() planVersionId: string;
    @column() homeownerWaitingPeriod: number;
    @column() policyNumber: bigint;
    @column() serviceFeeAmount: number;
    @column() status: string;
    @column() basePrice: number;
    @column() basePriceRecurring: number;
    @column() totalPrice: number;
    @column() totalPriceRecurring: number;
    @column() isMonthly: boolean;
    @column() planId: string;
    @column() termsAndLimitationId: string;
    @column() effectiveDate: Date;
    @column() expirationDate: Date;
    @column() previousPolicyId: string;
    @column() nextPolicyId: string;
    @column() canceledDate: Date;
    @column() totalCashOuts: number;
    @column() totalContractorInvoices: number;
    @column() totalPurchaseOrders: number;
    @column() totalPayments: number;
    @column() holderId: string;
    @column() coHolderId: string;
    @column() agentId: string;
    @column() sellerAgentId: string;
    @column() escrowNumber: string;
    @column() estimatedClosingDate: Date;
    @column() closingOfficerId: string;
    @column() initiatorType: string;
    @column() coverageType: string;
    @column() coverageTypeDisplay: string;
    @column() stripeCardId: string;
    @column() createdById?: string;
    @column() createdByName: string;
    @column() monthsOfCoverage: number;
    @column() accountExecutiveId: string;
    @column() createdDate?: Date;
    @column() doNotRenew: boolean;
    @column() autoRenew: boolean;
    @column() marketingSourceId: string;
    @column() marketingSourceName: string;
    @column() languageName: string;
    @column() languageId: string;
    @column() titleInvoiceAmount: number;
    @column() expirationDateOverride: Date;
    @column() salesRegionId: string;
    @column() salesRegionName: string;
    @column() salesAreaId: string;
    @column() salesAreaName: string;
    @column() salesTerritoryId: string;
    @column() salesTerritoryName: string;
    @column() areaId: string;
    @column() areaName: string;
    @column() contractPrintDate: Date;
    @column() propertyManagerId: string;
    @column() promotionCodeId: string;
    @column() alwaysSendDocumentsToHomeowner: boolean;
    @column() alwaysSendDocumentsToPropertyManager: boolean;
    @column() propertyId: string;
    @column() altKey: string;
    @column() renewalNote: string;
    @column() additionalTitleInvoiceEmails: string;
    @column() shortCode: string;
    @column() salesReportCoverageType: string;
    @column() agentName: string;
    @column() agentEmail: string;
    @column() closingCompanyId: string;
    @column() closingCompanyName: string;
    @column() accountingAreaId: string;
    @column() serviceProTerritoryId: string;
    @column() serviceProTerritory: string;
    @column() sellerAgentName: string;
    @column() sellerAgentEmail: string;
    @column() buyersAgentTeamId: string;
    @column() sellersAgentTeamId: string;
    @column() buyersAgentBranchId: string;
    @column() buyersAgentBranchName: string;
    @column() buyersAgentCompanyId: string;
    @column() buyersAgentCompanyName: string;
    @column() sellersAgentBranchId: string;
    @column() sellersAgentBranchName: string;
    @column() sellersAgentCompanyId: string;
    @column() sellersAgentCompanyName: string;
    @column() isPaid: boolean;
    @column() holderAndCoHolderEmails: string;
    @column() holderAndCoHolderFirstNames: string;
    @column() renewalUrlShort: string;
    @column() timeZoneId: string;
    @column() pastDuePremiumInvoices: number;
    @column() premiumInvoiceMinimumDueDate: Date;
    @column() pastDueServiceFeeInvoices: number;
    @column() serviceFeeInvoiceMinimumDueDate: Date;
    @column() commissionable?: boolean;
    

    get internationalTimeZone() {

        switch (this.timeZoneId) {
            case 'Mountain Standard Time':
                return 'America/Denver';
            case 'US Mountain Standard Time': //AZ
                return 'America/Phoenix';
            case 'Central Standard Time':
                return 'America/Chicago';
            case 'Eastern Standard Time':
                return 'America/New_York';
            case 'US Eastern Standard Time': // indianapolis
                return 'America/Fort_Wayne';
            case 'Pacific Standard Time':
                return 'America/Los_Angeles';
        }
        return 'America/Denver';
    }
}


