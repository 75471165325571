
<div>
    <div class="container " style="overflow-x: hidden;padding-top: 160px">


        <div style="    position: fixed;
        width: 100%;
        z-index: 500;
        background-color: #fbf8f4;
        left: 0;
        top: 60px;">
            <div class="container" >
                <div flex topmargin30 bottommargin30 style="padding-left: 20px;padding-right: 20px;">
                    <div>
                        <div class="data-label">
                            Total Payment
                        </div>



                        <h1 class="big-data">{{totalPayment | currency}}</h1>
                    </div>
                    <div nogrow>

                        <button mat-button mat-raised-button mobile-block (click)="makePayment()"
                            [disabled]="!canMakePayment || saving" color="primary" large>
                            <mat-spinner *ngIf="saving" thirty></mat-spinner>
                            Make Payment
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="unpaidInvoices && unpaidInvoices.length > 0" style="margin-top: 80px">
            <app-payment-method-entry [customerId]="customerId" paymentDescription="Invoice Payments"
                [paymentAmount]="totalPayment" [(selectedCard)]="selectedCard"
                (selectedCardChange)="paymentValid($event)">
            </app-payment-method-entry>
        </div>

        <div class="padding">
            <div aflex class="invoice-item" *ngFor="let invoice of unpaidInvoices" >

                <div>
                    <mat-checkbox [(ngModel)]="invoice.selected">
                        <h3>{{invoice.description}} - {{invoice.policyAddress1}}</h3>
                        Amount Due: {{invoice.amountDue | currency}} - Due Date: {{invoice.dueDate | date: 'shortDate'}}
                    </mat-checkbox>
                </div>
                <div nogrow>
                    <a mat-button target="_blank" [href]="getInvoiceUrl(invoice)" title="Download">
                        <mat-icon>description</mat-icon>
                    </a>
                </div>
            </div>

        </div>

        <div class="center fade-in-up" *ngIf="unpaidInvoices && unpaidInvoices.length === 0">

            <img style="height: 150px;" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/paid-invoice-orange-blob.svg">
            <div>
                You're all paid up
            </div>
        </div>


    </div>
</div>
