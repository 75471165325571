import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceOfferingSummaryWithPostalCode {
    @column() maintenanceServiceId: string;
    @column() maintenanceServiceOfferingId: string;
    @column() pricingAreaId: string;
    @column() maintenanceServiceName: string;
    @column() maintenanceServiceOfferingName: string;
    @column() shortSummary: string;
    @column() frequencyTypes: string;
    @column() price: number;
    @column() cost: number;
    @column() geographyId: string;
    @column() rangeStart: number;
    @column() rangeEnd: number;
    @column() marketingTemplateId: string;
    @column() pricingInputName: string;
    @column() pricingUnitType: string;
    @column() zipCode: string;
    @column() frequencyType: string;
    @column() visitsPerYear: number;
    @column() contractLanguage: string;
    @column() maintenanceServiceFilters: string;
    @column() offeringFilters: string;
    @column() pricingFilterOptions: string;
    @column() color: string;
    @column() secondaryUnitPrice: number;
    @column() secondaryUnitCost: number;
    @column() includedSecondaryUnits: number;
    @column() maintenanceServicePricingInputId: string;
    @column() maintenanceServicePricingId: string;
    @column() schedulingBufferDays: number;
    @column() isOneTime: boolean;
    @column() costPerMonth: number;
    @column() pricingDisclaimer: string;
    @column() dynamicPricingType: string;
    @column() dynamicPricingAddition: number;
    @column() brochureUrl: string;
    @column() lottieAnimationUrl: string;
    @column() tertiaryUnitsPrice: number;
    @column() tertiaryUnitsCost: number;
    @column() includedTertiaryUnits: number;
    @column() cancellationFee?: number;
    @column() cancellationFeeMonthsInEffect?: number;
    @column() maintenanceServiceContractLanguageId: string;


    preferredDay: number;
    preferredWeek: number;
    startDate: Date;
    approved = false;
    initialVisitPrice: number;

    private _pricingFilterOptionsIds: string[];
    loading: boolean;
    pricingFilterOptionsId(): string[] {
        if (!this._pricingFilterOptionsIds) {
            if (this.pricingFilterOptions) {
                try {
                    this._pricingFilterOptionsIds = JSON.parse(this.pricingFilterOptions);
                } catch { }
            }
        }
        return this._pricingFilterOptionsIds;
    }

    secondaryUnitCount: number;
    tertiaryUnitCount: number;

    get caluclatedPrice() {
        if (!this.secondaryUnitPrice) {
            return this.price;
        }

        const secondaryUnitPrice = ((this.secondaryUnitCount ?? 0) - (this.includedSecondaryUnits ?? 0)) * (this.secondaryUnitPrice ?? 0);
        const tertiaryUnitPrice = ((this.tertiaryUnitCount ?? 0) - (this.includedTertiaryUnits ?? 0)) * (this.tertiaryUnitsPrice ?? 0);

        return this.price + secondaryUnitPrice + tertiaryUnitPrice;

        // const unitsToBill = (this.secondaryUnitCount ?? 0) - (this.includedSecondaryUnits ?? 0);
        // if (unitsToBill > 0) {
        //     return this.price + unitsToBill * this.secondaryUnitPrice;
        // }
        // return this.price;
    }

    get monthlyPrice() {
        if (this.isOneTime) {
            return this.caluclatedPrice;
        }

        return (this.visitsPerYear * this.caluclatedPrice) / 12;
    }

    get priceToShow() {
        return this.caluclatedPrice < this.monthlyPrice ? this.caluclatedPrice : this.monthlyPrice;
    }

    get showMonthly() {
        return this.caluclatedPrice > this.monthlyPrice;
    }
}