import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderItemType {
    @keyColumn() id: string;
    @column() workOrderItemId: string;
    @column() name: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() sort: number;
    @column() cashOutThresholdAmount?: number;
    
    isNew: boolean;
    isEdited: boolean;
}