import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CustomFunction {
    @keyColumn() id: string;
    @column() name: string;
    @column() functionJson: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() iconClass: string;
    @column() category: string;
    @column() envVariables: string;
    @column() triggers: string;
    @column() schedule: string;
    @column() availableOnCogentMaintenanceService: boolean;
    @column() availableOnCustomerPortalJob: boolean;
    @column() availableOnCogentPolicy: boolean;
    @column() availableOnCogentJobDetail: boolean;  
    @column() isPrimary: boolean;
    @column() functionKey: string;
    @column() description: string;
    @column() iconUrl: string;
    @column() screenAvailabilityFilter: string;
    @column() availableOnMaintenanceServicePropertyService: boolean;
    @column() availableOnCogentTask: boolean;
    @column() availableOnCogentPurchaseOrder: boolean;
    @column() hideOnUI: boolean;
    @column() runOnScreenSave: boolean;
    @column() availableOnCogentAuth: boolean;

    currentJson: string;
    envVariableObj: any;
    envVariableKeys: string[];
    triggersObj: FunctionTrigger[];
    scheduleObj: FunctionSchedule[];
    hideFromUI: boolean;
    showOnMainToolbar: boolean;
    showOnlyOnMultiSelect: boolean;
    shareWithDetailScreen: boolean;
    imageUrl: string;
    running = false;

}

export class FunctionSchedule {
    createdDate: string;
    type: "Not Scheduled" | "Minutes" | "Hourly" | "Daily" | "Weekly" | "Monthly" | "Yearly";
    minute: number;
    hour: number;
    day: number;
    month: number;
    id: string;
    procedure: string;
    manuallyRunning: boolean;
    passEntireDataSet: boolean;
}
export class FunctionTrigger {
    tableName: string;
    onInsert: boolean;
    onUpdate: boolean;
    onDelete: boolean;
}

@dataSource()
export class CustomFunctionScheduleStatus {
    @keyColumn() id: string;
    @column() lastRun: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() customFunctionId: string;
}
@dataSource()
export class CustomFunctionSchedule {
    @keyColumn() id: string;
    @column() customFunctionId: string;
    @column() type: string;
    @column() procedure: string;
    @column() minute: number;
    @column() hour: number;
    @column() day: number;
    @column() month: number;
    @column() lastRun: Date;
    @column() nextRun: Date;
    @column() passEntireDataSet?: boolean;
}

@dataSource()
export class DynamicObject {
    
}