export class MaintenanceServiceBaseOffering {
    price: number;
    maintenanceServiceName: string;
    frequencyType: string;
    visitsPerYear: number;
    lottieAnimationUrl: string;
    brochureUrl: string;

    get pricePerMonth() {
        return (this.price * this.visitsPerYear) / 12;
    }

    get displayPrice() {
        return this.pricePerMonth < this.price ? this.pricePerMonth : this.price;
    }

    get priceLabel() {
        return this.pricePerMonth < this.price ? 'month' : 'visit';
    }
}