import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class InventoryPart {
    @keyColumn() id: string;
    @column() partNumber: string;
    @column() purchaseOrderId: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() location: string;
}