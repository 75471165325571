import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { PolicyRenewalSummary, StripeCard } from '@upkeeplabs/models/cogent';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
@Component({
    standalone: true,
    templateUrl: 'auto-renew.component.html',
    styleUrls: ['auto-renew.component.css'],
    selector: 'auto-renew-component',
    imports: [MatCardModule, MatSlideToggleModule, FormsModule, PaymentMethodEntryModule, PaymentMethodEntryComponent,CommonModule, MatButtonModule, MatProgressSpinnerModule, MatExpansionModule]
})
export class AutoRenewComponent implements OnInit {
    @Input() policySummary: PolicySummary
    @Input() policyRenewalSummary: PolicyRenewalSummary
    @Output() showOptions: EventEmitter<boolean> = new EventEmitter();

    selectedStripeCard: StripeCard
    savingPaymentMethod: boolean;
    saving = false
    panelOpenState = false;

    constructor(private policyApi: PolicyApiService) { }

    ngOnInit() {
        this.initialize()
    }

    async showPlanOptions() {
        this.showOptions.emit(true);
    }

    async selectedCardChange(card: StripeCard) {
        this.selectedStripeCard = card;
        if (card.id != this.policyRenewalSummary.stripeCardId) {
            this.policyRenewalSummary.stripeCardId = card.id;
            this.save();
        }
    }

    async initialize() {
        this.policyRenewalSummary = await this.policyApi.getRenewalPolicySummary2(this.policySummary.id);
    }

    async save() {
        this.saving = true;

        if (this.policyRenewalSummary.autoRenew) {
          await this.policyApi.updateAutoRenewStatus(this.policySummary.id, true, this.selectedStripeCard);
        } else {
          await this.policyApi.updateAutoRenewStatus(this.policySummary.id, false, null/*, this.cancelAutoRenewReason*/)
        }

        this.saving = false;
    }

}
