import { Address } from "./address.model";
import { Entity } from "./entity.model";
import { GroupedProductOfferings } from "./grouped-product-offering.model";
import { PhoneNumber } from "./phone-number.model";
import { Plan } from "./plan.model";
import { PolicyAdjustment } from "./policy-adjustment.model";
import { PromotionCodeSummary } from "./promotion-code-summary.model";
import { SalesItem } from "./sales-item.model";
import { StripeCard } from "./stripe-card.model";
import { WebQuote } from "./web-quote.model";

export class ShoppingCartItem {
    constructor() {
        this.quantity = 1;
    }
    id: string;
    type: string;
    description: string;
    price: number;
    pricePerMonth: number;
    backingObj?: any;
    quantity: number;

    displayPrice() {
        return this.price * this.quantity;
    }

    displayPricePerMonth() {
        return this.pricePerMonth * this.quantity;
    }
}

export class AdditionalEmail {
    email: string;
    type: string;
}

export class QuoteAndOrderState {
    quote: WebQuote;
    plans: Plan[] | any[];
    promotionCode: PromotionCodeSummary;
    isMonthly = false;
    shoppingCartItems: ShoppingCartItem[];
    customerId: string;
    maintenanceOfferings: GroupedProductOfferings[];
    oneTimeServices: SalesItem[] | any[];
    selectedPlan?: Plan;
    coverageType: string;
    mailingAddress: Address;
    propertyAddress: Address;
    usePropertyMailingAddress = true;
    password: string;
    passwordConfirm: string;
    card: StripeCard;
    customer: Entity;
    code: string;
    initiatorType: string;
    selectedTitleInfo: any;
    isNewTitle = false;
    newEscrowName: string;
    agentIdSetFromQuery = false;
    isNewAgent = false;
    isNewSeller = false;
    isNewTitleCompany = false;
    isNewAgentCompany = false;
    isNewSellerCompany = false;
    newEscrowCompany: string;
    selectedTitleCompanyInfo: any;
    newEscrowPhone: string;
    newEscrowEmail: string;
    additionalEmailAddresses: AdditionalEmail[];
    sellersAdditionalEmails: AdditionalEmail[];
    agentAdditionalEmails: AdditionalEmail[];
    escrowNumber: string;
    estimatedClosingDate: Date;
    newSellerName: string;
    selectedSellerInfo: any;
    newSellerCompany: any;
    selectedSellerCompany: any;
    newSellerPhone: string;
    newSellerEmail: string;
    billTo: string;
    newAgentName: string;
    selectedAgentInfo: any;
    newAgentCompany: any;
    selectedAgentCompany: any;
    newAgentPhone: string;
    newAgentEmail: string;
    phoneNumber: PhoneNumber;
    adjustments: PolicyAdjustment[];
    notes: string;

    constructor() {
        // this.quote = new WebQuote();
        // this.quote.id = UtilitiesService.newid();
        // this.quote.address = new Address();
        // this.quote.address.id = UtilitiesService.newid();
        // this.quote.addressId = this.quote.address.id;
        // this.shoppingCartItems = [];
        // this.customerId = UtilitiesService.newid();
        // this.isMonthly = true;

        // this.mailingAddress = new Address();
        // this.mailingAddress.id = UtilitiesService.newid();
    }

    get hasWarrantySelected() {
        return this.shoppingCartItems.find(i => i.type === 'plan');
    }
}