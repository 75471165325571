<div class="new-quote-container" [id]="id" (scroll)="setScrollButtons()">
    <div class="new-quote-container-header">
        <div class="logo-container">
            <a href="https://elevatehomescriptions.com/"><img
                    src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/elevate-logo-homescriptions.svg"
                    alt="Elevate Logo"></a>
        </div>
        <div class="plan-offering-navigation">
            <p class="{{selectedIndex == 0 ? 'selected' : ''}} plan-offering-navigation-item" (click)="moveToIndex(0)">
                <span>1</span> {{layout == 1 ? 'Enter Your Information' : 'Enter Your Address'}} </p>
            <p class="{{selectedIndex == 1 ? 'selected' : ''}} plan-offering-navigation-item" (click)="moveToIndex(1)">
                <span>2</span> {{layout == 1 ? 'See Your Quote' : 'Contact Info'}}</p>
            <p class="{{selectedIndex == 2 ? 'selected' : ''}} plan-offering-navigation-item" *ngIf="layout == 2"
                (click)="moveToIndex(2)"><span>3</span> See Your Quote</p>
        </div>
    </div>
    <mat-tab-group class="hide-tabs" style="margin-top: -18px" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">
            <div class="scroll-step quote-first new-quote-form-container">
                @if (bgImage) {
                <div class="new-quote-form-image-container" [style.background-image]="bgImage"></div>
                } @else {
                <div class="new-quote-form-image-container"></div>
                }
                <div class="form">
                    <header>
                        @if(quoteConfig?.jsonValue.heading && !isGiveaway) {
                        <h2 [innerHTML]="quoteConfig.jsonValue.heading"></h2>
                        }@else if(quoteConfig?.jsonValue.giveAwayHeading && isGiveaway) {
                        <h2 [innerHTML]="quoteConfig.jsonValue.giveAwayHeading"></h2>
                        } @else {
                        <h2>Simplifying Homeownership</h2>
                        }
                    </header>

                    @if(quoteConfig?.jsonValue.text && !isGiveaway) {
                    <p [innerHTML]="quoteConfig.jsonValue.text"></p>
                    } @else if(quoteConfig?.jsonValue.giveAwayText && isGiveaway) {
                    <p [innerHTML]="quoteConfig?.jsonValue.giveAwayText"></p>
                    }
                    @else {
                    <p>With our Home Warranty plans, you won't have to stress about fixing things around the house.
                        We've got your main systems and appliances covered, and you can even add on services like pest
                        control, lawn maintenance, or house cleaning for a complete Homescription.</p>
                    }
                    <div style="position: relative; max-width: 500px;">
                        <div>
                            <app-single-address-entry #addressEntry [loadPropertyMeta]="true"
                                (addressChange)="addressCompleteChange($event)"
                                [(address)]="quote.address"></app-single-address-entry>
                        </div>
                        <ng-container *ngIf="layout == 1;">
                            <form>
                                <mat-form-field appearance="outline">
                                    <mat-label>Your Name *</mat-label>
                                    <input matInput #nameEl id="customer-name" [(ngModel)]="quote.name" name="name"
                                        (change)="pixelTrackCustom('ContactInformationName')">
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Email *</mat-label>
                                    <input #emailEl matInput [(ngModel)]="quote.email" type="email" name="email"
                                        (change)="pixelTrackCustom('ContactInformationEmail')">
                                </mat-form-field>

                                @if (!isGiveaway) {
                                <mat-form-field appearance="outline">
                                    <mat-label>Phone Number *</mat-label>
                                    <input #phoneEL matInput [(ngModel)]="quote.phone" type="tel" mask="(000) 000-0000"
                                        name="phone" (change)="pixelTrackCustom('ContactInformationPhone')">
                                </mat-form-field>
                                }

                                <button class="get-quote-button" mat-button mat-raised-button color="primary" [disabled]="saving"
                                    (click)="getQuoteNext()">
                                    Get Your Quote Now <mat-icon iconPositionEnd
                                        class="plan-select-button-icon">navigate_next</mat-icon>
                                </button>
                            </form>
                        </ng-container>
                        <ng-container *ngIf="layout == 2;">
                            <button class="get-quote-button" mat-button mat-raised-button color="primary"
                                (click)="selectedIndex = 1">
                                I'm ready for my quote! <mat-icon iconPositionEnd
                                    class="plan-select-button-icon">navigate_next</mat-icon>
                            </button>
                        </ng-container>
                        <div topmargin20>
                            <span (click)="currentCustomerRedirect()" class="redirect-link">Current Customers</span> |
                            <span (click)="realEstateRedirect()" class="redirect-link">Real Estate Agents</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--            <button mat-raised-button mat-button class="quote-scroll-button" (click)="scrollToSecondStep()">Learn More!<mat-icon iconPositionEnd>expand_more</mat-icon></button>-->

            <div class="scroll-step quote-second">
                <div class="homescription-info-container">
                    <h1>What is a Homescription?</h1>
                    <h5>Home Warranty + Home Maintenance Services</h5>
                    <p>Our comprehensive Home Warranty plans cover your main systems and appliances so you never have to
                        worry about calling a handy-man. Add on maintenance services like pest control, house cleaning,
                        or lawn maintenance to complete your Homescription.</p>
                </div>
            </div>

            <div class="scroll-step quote-third">

                <div>
                    <h2 class="center" style="line-height: 1.25em" bottommargin40>Covered Appliances and Systems</h2>
                    <div class="covered-item-container">
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-hvac.svg"><br>
                            Heating &amp; Cooling
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-appliance.svg"><br>
                            Major Kitchen Appliances
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-plumbing.svg"><br>
                            Plumbing
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-water-heater.svg"><br>
                            Water Heaters
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-sprinker.svg">
                            <br>
                            Outdoor Sprinkler Systems
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-electrical.svg"><br>
                            Electrical
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-garage.svg"><br>
                            Garage Door Openers
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-washer.svg"><br>
                            Washer/Dryer
                        </div>
                    </div>

                    <div class="center" topmargin40>
                        <mat-icon>add</mat-icon>
                        <h2>Maintenance Services</h2>
                    </div>


                    <div class="on-demand-services-list">
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-orange-check.svg">
                            Re-Key Locks
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-orange-check.svg">
                            Pest Control
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-orange-check.svg">
                            Pre-Season HVAC Tune-Ups
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-orange-check.svg">
                            Lawn Fertilization
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-orange-check.svg">
                            Carpet Cleaning
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-orange-check.svg">
                            TV Wall Mounting Service
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-orange-check.svg">
                            Window Cleaning
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-orange-check.svg">
                            Smart Home Tech Install
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-orange-check.svg">
                            Dryer Vent Cleaning
                        </div>
                        <div>
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-orange-check.svg">
                            Garbage Can Cleaning
                        </div>
                    </div>
                </div>

            </div>

            <div class="scroll-step quote-fourth">
                <div>
                    <h2>How it Works</h2>
                    <div class="quote-card-container">
                        <div class="quote-card">
                            <div class="img-container">
                                <img
                                    src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-appliance-2.svg">
                            </div>
                            <h6>Step 1</h6>
                            <h4>Build Your Plan.</h4>
                            <p>Home warranties are not a-one-size-fits-all. We offer plans for homes and budgets of all
                                sizes.</p>
                            <hr>
                            <button mat-raised-button mat-button color="primary" (click)="scrollToTop()"
                                class="build-quote-button quote-select-button quote-card-button">Build Plan <mat-icon
                                    iconPositionEnd class="plan-select-button-icon">navigate_next</mat-icon></button>
                        </div>
                        <div class="quote-card">
                            <div class="img-container">
                                <img
                                    src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-appliance-1.svg">
                            </div>
                            <h6>Step 2</h6>
                            <h4>Contact us when a covered system or appliance fails.</h4>
                            <p>Use the app to streamline requesting service or give us a call.</p>
                            <hr>
                            <button mat-raised-button mat-button color="primary" (click)="scrollToTop()"
                                class="build-quote-button quote-select-button quote-card-button">Build Plan <mat-icon
                                    iconPositionEnd class="plan-select-button-icon">navigate_next</mat-icon></button>
                        </div>
                        <div class="quote-card">
                            <div class="img-container">
                                <img
                                    src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/icon-house.svg">
                            </div>
                            <h6>Step 3</h6>
                            <h4>Sit back and relax. We've got you covered!</h4>
                            <p>You have more important things to do with your time.</p>
                            <hr>
                            <button mat-raised-button mat-button color="primary" (click)="scrollToTop()"
                                class="build-quote-button quote-select-button quote-card-button">Build Plan <mat-icon
                                    iconPositionEnd class="plan-select-button-icon">navigate_next</mat-icon></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="scroll-step quote-fifth">
                <div class="quote-coverage-2-container">
                    <div class="quote-coverage-2-image-container">
                        <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/elevate-phone.png"
                            alt="phone">
                    </div>
                    <div class="quote-coverage-2-information">
                        <h2>From Broken to Fixed in Just a Few Clicks!</h2>
                        <p>✓ Request service using our all-in-one mobile app</p>
                        <p>✓ We are here for you 24/7</p>
                        <p>✓ Simplified Contracts</p>
                        <p>✓ No long hold times like old school home warranty companies</p>
                        <p>✓ Save $5 off your service call fee when you use the app!</p>
                        <p>Not an app person? <a href="#">Call us instead.</a></p>
                        <button mat-raised-button mat-button color="primary" (click)="scrollToTop()"
                            class="build-quote-button">Build Plan <mat-icon iconPositionEnd
                                class="plan-select-button-icon">navigate_next</mat-icon></button>
                    </div>
                </div>
            </div>

            <div class="scroll-step quote-sixth">
                <div class="quote-coverage-container">
                    <div class="quote-coverage-image-container">
                        <img
                            src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/comprehensive-coverage2.jpg">
                    </div>
                    <div class="quote-coverage-information">
                        <h3>The most comprehensive coverage. <br> Period.</h3>
                        <p>Say goodbye to breakdown headaches! With our Totally Elevated Plan, enjoy claim limits up to
                            $5,000.</p>
                        <button mat-raised-button mat-button color="primary" (click)="scrollToTop()"
                            class="build-quote-button">Get Your Quote Now <mat-icon iconPositionEnd
                                class="plan-select-button-icon">navigate_next</mat-icon></button>
                    </div>
                </div>
            </div>

            <div class="scroll-step quote-seventh">
                <div class="quote-coverage-3-container">
                    <div class="quote-coverage-3-information">
                        <h1>We're Different</h1>
                        <p>Our exceptional customer care team, coupled with our user-friendly app, and extensive claim
                            limits will take the stress out of home maintenance. Say goodbye to worries and hello to an
                            elevated lifestyle where homeownership is hassle-free.</p>
                        <p>Plus, our plans are <span style="color: black;">simple to buy, simple to understand, and
                                simple to use.</span></p>
                        <button mat-raised-button mat-button color="primary" (click)="scrollToTop()"
                            class="build-quote-button">Build Plan <mat-icon iconPositionEnd
                                class="plan-select-button-icon">navigate_next</mat-icon></button>
                    </div>
                </div>
            </div>

            <div class="scroll-step quote-eighth">
                <div class="quote-faqs-container">
                    <h1 bottommargin30>FAQs</h1>

                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                How does an Elevate Homescription work?
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ol>
                            <li>An Elevate Homescription allows you to combine your Home Warranty + On-Demand
                                Services, like house cleaning, lawn care, pest control, and more.</li>
                            <li>Our customizable plans help ensure you never have any worries about what needs
                                fixing around the house. You get everything you need when it’s convenient for you,
                                thanks to our app! There’s no need to waste time looking up repair or service
                                providers anymore but your appliances and systems can go from broken to fixed in
                                just a few clicks on your phone.</li>
                        </ol>

                    </mat-expansion-panel>
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                How is Elevate different than a traditional home warranty?
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ol>
                            <li>We’ve eliminated over 80% of our fine print so you know exactly what is covered
                                under your plan and what’s outside our plans.</li>
                            <li>Requesting a service is fast! The Elevate Homeowners app makes it easy to file a
                                claim and choose 3 times that you would be available for our Service Pros to come
                                out and help you! </li>
                            <li>Stay informed every step of the way with SMS and App notifications. Once we accept
                                your claim, you can communicate directly and easily with your contractor, ensuring
                                that you stay informed and avoid any unexpected surprises. </li>
                            <li>24/7 support - call or message us anytime with any questions! </li>
                            <li>With our Inspection Protection, we cover UNKNOWN pre-existing conditions. Simply
                                send us a copy of your home inspection report and relax - we've got you covered.
                            </li>
                            <li>In addition to repairs and replacements, we also prioritize prevention services to
                                ensure your home's systems and appliances stay in top shape. Our Home Warranty plans
                                include A/C and Heating System tune-ups, so you can catch minor issues early and
                                avoid expensive replacements in the future. With Elevate Homescriptions, you can
                                enjoy peace of mind knowing that we're not just fixing problems but preventing them
                                from happening in the first place.You can also add on semi-annual appliance
                                tune-ups, dryer vent cleanings, and a garage door system tune-up onto your
                                Homescription today. </li>
                            <li>With our Homescription you can add on on-demand maintenance services such as house
                                cleaning, pest control, lawn mowing, pool maintenance, window cleaning and more.
                                This makes life simpler so you don't have to manage multiple contractors, but can
                                control all your home maintenance needs through one platform, Elevate. You can click
                                here to start building your Homescription today. </li>
                        </ol>

                    </mat-expansion-panel>
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                Can I talk to real person?
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <p>Of course! Although using the app to open a new service request will save you $5, you can
                            call us 24/7/365 and we’ll be ready to help get one of our service pros out to you as
                            soon as we can. </p>

                    </mat-expansion-panel>
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                What’s the difference between home insurance and a home warranty?
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ol>
                            <li>Homeowners insurance is designed to cover damages caused by unexpected events such
                                as fire, theft, and natural disasters. </li>
                            <li>A home warranty does cover the repair or replacement of maintained major appliances
                                and systems in your home, such as HVAC, plumbing, and electrical systems. It
                                provides peace of mind knowing that when these systems fail due to normal wear and
                                tear, you won't have to pay for the repairs or replacements out of pocket.  </li>
                        </ol>

                    </mat-expansion-panel>
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                Why do I need an Elevate Homescription?
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <p>Being a homeowner isn’t easy. That’s why we’re here to help eliminate the hassles of
                            homeownership. You’ll need to expect things to break down in your home eventually and in
                            those moments when it feels like the world is falling down, we are here to help. We’ll
                            get a Service Pro out to you as soon as you can so you can spend more time doing what
                            you love. </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                What is Elevate Homescriptions?
                            </mat-panel-description>
                        </mat-expansion-panel-header>


                        <ol>
                            <li>After years in the Home Warranty and Title industry, we realized that the Home
                                Warranty industry was a little broken. There was too much fine print and not enough
                                coverage. And really what we found is that homeowners, especially brand-new
                                homeowners just needed more support. </li>
                            <li>Owning a home is a lot more work than one might expect and so that’s why we created
                                Elevate Homescriptions, to fix an industry and help homeowners everywhere to help
                                cover costs for unexpected problems in their home and give them more time to focus
                                on cultivating a home you love. </li>
                        </ol>
                    </mat-expansion-panel>
                </div>
            </div>

        </mat-tab>
        <mat-tab *ngIf="layout == 2" label="{{layout == 2 ? 'Second' : 'Third'}}">
            <div class="new-quote-form-container" *ngIf="plans && plans.length; else searchingForPlans">
                <div class="new-quote-form-image-container-2" [style.background-image]="bgImage2"></div>
                <form>
                    <header>
                        @if(quoteConfig?.jsonValue.heading2 && !isGiveaway) {
                        <h2 [innerHTML]="quoteConfig.jsonValue.heading2"></h2>
                        } @else if(quoteConfig?.jsonValue.giveAwayHeading2 && isGiveaway) {
                        <h2 [innerHTML]="quoteConfig?.jsonValue.giveAwayHeading2"></h2>
                        }
                        @else {
                        <h2>Good News!</h2>
                        <h4>We service your area!</h4>
                        }

                        @if(quoteConfig?.jsonValue.text2 && !isGiveaway) {
                        <p [innerHTML]="quoteConfig.jsonValue.text2"></p>
                        } @else if(quoteConfig?.jsonValue.giveAwayText2 && isGiveaway) {
                        <p [innerHTML]="quoteConfig?.jsonValue.giveAwayText2"></p>
                        }
                        @else {
                        <p>Drop us your contact info, and we'll send over your customized quote to keep handy</p>
                        }
                    </header>
                    <div style="position: relative; max-width: 500px;">
                        <mat-form-field appearance="outline">
                            <mat-label>Your Name *</mat-label>
                            <input matInput #nameEl id="customer-name" [(ngModel)]="quote.name" name="name"
                                (change)="pixelTrackCustom('ContactInformationName')">
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Email *</mat-label>
                            <input #emailEl matInput [(ngModel)]="quote.email" type="email" name="email"
                                (change)="pixelTrackCustom('ContactInformationEmail')">
                        </mat-form-field>

                        @if(!isGiveaway) {
                        <mat-form-field appearance="outline">
                            <mat-label>Phone Number *</mat-label>
                            <input #phoneEL matInput [(ngModel)]="quote.phone" type="tel" mask="(000) 000-0000"
                                name="phone" (change)="pixelTrackCustom('ContactInformationPhone')">
                        </mat-form-field>
                        }
                        <button class="get-quote-button" mat-button mat-raised-button color="primary" (click)="save()">
                            Get Your Quote Now <mat-icon iconPositionEnd
                                class="plan-select-button-icon">navigate_next</mat-icon>
                        </button>
                    </div>
                </form>
            </div>
            <ng-template #searchingForPlans>
                <div *ngIf="loading; else elseBlock" class="center" style="margin-top: 60px">

                    <mat-spinner style="display: inline-block;"></mat-spinner><br>
                    Loading...
                </div>
                <ng-template #elseBlock>
                    <div topmargin40 class="center">
                        <h1>Sorry,</h1>
                        <p bottommargin40>It doesn't look like we have coverage in your area.</p>

                        <button mat-button mat-raised-button (click)="selectedIndex = 0">
                            <mat-icon>chevron_left</mat-icon> Enter A Different Address
                        </button>
                    </div>
                </ng-template>
            </ng-template>
        </mat-tab>
        <mat-tab label="{{layout == 1 ? 'Second' : 'Third'}}">

            <div *ngIf="!plans || loading" class="center" style="margin-top: 60px">

                <mat-spinner style="display: inline-block;"></mat-spinner><br>
                Loading Plans...
            </div>

            <div topmargin40 class="center" *ngIf="plans && plans.length === 0 && !saving">
                <h1>Sorry,</h1>
                <p bottommargin40>It doesn't look like we have coverage in your area.</p>

                <button mat-button mat-raised-button (click)="selectedIndex = 0">
                    <mat-icon>chevron_left</mat-icon> Enter A Different Address
                </button>
            </div>

            <div style="margin-top: 70px"></div>
            <app-new-plan-offerings *ngIf="plans && plans.length" [(isMonthly)]="isMonthly" [plans]="plans"
                [quote]="quote">
            </app-new-plan-offerings>
        </mat-tab>
    </mat-tab-group>
    <app-customer-footer></app-customer-footer>
</div>

<div *ngIf="showRealEstateOrHomeowner" (click)="showRealEstateOrHomeowner = false" class="real-estate-or-homeowner-bg">

</div>
<div *ngIf="showRealEstateOrHomeowner" class="real-estate-or-homeowner center">


    <h1 bottommargin40>How long have you been in your home?</h1>
    <button mat-button mat-raised-button (click)="setCoverageType('Homeowner')" style="margin-right: 20px;">
        More than 30 days
    </button>

    <button mat-button mat-raised-button  (click)="setCoverageType('RealEstate')">
        30 Days or Less
    </button>
</div>