import { Component, OnInit, HostListener } from '@angular/core';
import { ServiceRepositoryService } from '@cogent/client/shared/services/api/service-repository';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { ObjectCommand } from '@cogent/client/shared/models/object-command.model';
import { QueueCommand, QueueQuery } from '@cogent/client/shared/models/object-queue.model';
import { ApiService } from '@cogent/client/api';
import { CommandRunnerService } from '@cogent/client/shared/services/command-runner.service';
import { MissionService, ShellMenuItemModel } from '@cogent/client/shared/services/mission-service';
import { MatDialog } from '@angular/material/dialog';
import { ChangeAutoRenewalComponent } from '@cogent/client/shared/components/sales/change-auto-renewal/change-auto-renewal/change-auto-renewal.component';
// import { AccountingItem, PrepaidServiceFee, Tag } from '@upkeeplabs/models/cogent';
import { UpdateWarrantyPaymentMethodComponent } from '@cogent/client/apps/homeowner/accounting/update-warranty-payment-method/update-warranty-payment-method.component';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CircleWidgetComponent } from '@cogent/client/shared/components/data-visualization/circle-widget/circle-widget.component';
import { ClosingDocumentUploadModule } from '@cogent/client/shared/components/sales/closing-document-upload/closing-document-upload.module';
 
import { AccountingItem, Entity, PrepaidServiceFee, Tag } from '@upkeeplabs/models/cogent';
// import { UpdateWarrantyPaymentMethodComponent } from '../update-warranty-payment-method/update-warranty-payment-method.component';
// import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { EntityApiService } from '@cogent/client/shared/services/api/entity-api.service';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PhoneLinkModule } from '@cogent/client/shared/components/misc/phone-link/phone-link.module';

@Component({
    selector: 'app-view-policy',
    templateUrl: './view-policy.component.html',
    styleUrls: ['./view-policy.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        CircleWidgetComponent,
        ClosingDocumentUploadModule,
        FormsModule,
        MatProgressSpinnerModule,
        PhoneLinkModule
    ]
})
export class ViewPolicyComponent implements OnInit {

    policyId: string;
    policySummary: PolicySummary;
    workOrders: WorkOrderSummaryClient[];
    selectedIndex = 0;
    noUnpaidInvoices = false;
    accountingItems: AccountingItem[];
    showRenewalMessage = false;
    tags: Tag[];
    objectCommands: ObjectCommand[];
    extraCommands: QueueCommand[];
    showWorkingMessage = false;
    workingMessage: string;
    prepaidServiceFees: PrepaidServiceFee[];
    currentStripeCard: any;
    currentStripeCardLoaded = false;
    tenants: Entity[];
    tenantsToDelete: Entity[];
    savingTenants = false;
    isLegacy = true;

    private closedStatuses = ['Complete', 'Invoiced', 'Paid', 'Completed', 'Cancelled', 'Canceled'];

    constructor(
        private serviceRepository: ServiceRepositoryService,
        private activeRoute: ActivatedRoute,
        private missionService: MissionService,
        private customerRepository: CustomerRepositoryService,
        private policyApi: PolicyApiService,
        private entityApi: EntityApiService,
        private commandRunner: CommandRunnerService,
        private settingsApi: SettingsApiService,
        private dialog: MatDialog,
    ) { }

    getStreetViewUrl(policyId: string) {
        return this.policyApi.getStreetViewUrl(policyId);
    }

    get getContractUrl() {
        return ApiService.endPointNode + 'policy/contract/pdf/' + this.policyId;
    }

    ngOnInit() {

        setTimeout(() => {
            if (window.location.hash) { this.watchUrlHash(null); } else {
                window.location.hash = 'overview';
                this.watchUrlHash(null);
            }
            this.setupMenuItems();
        }, 50);
        this.settingsApi.getObjectCommandsForCustomerPortal('Policy').then(commands => {
            this.objectCommands = commands;
            this.setupCommands();
        });

        this.activeRoute.params.subscribe(params => {

            this.policyId = params.id;

            this.policyApi.getPolicyTenants(this.policyId).then(results => {
                this.tenants = results;
                for (const tenant of results) {
                    this.entityApi.getEntityPhoneNumbers(tenant);
                }
            });

            this.serviceRepository.getPolicySummary(this.policyId).then(policySummary => {
                this.policySummary = policySummary;

                if (this.policySummary.stripeCardId) {
                    this.customerRepository.getStripeCardForPolicy(policySummary.holderId, policySummary.stripeCardId).then(card => {
                        this.currentStripeCard = card;
                        this.currentStripeCardLoaded = true;
                    });
                } else {
                    this.currentStripeCardLoaded = true;
                }
                this.refreshPrepaidServiceFees();
                this.refreshTags();
                if (this.policySummary.canRenew) {

                    setTimeout(() => this.showRenewalMessage = true, 2000);
                }
                this.customerRepository.getWorkOrdersForCustomerAndProperty(policySummary.holder.id, policySummary.propertyAddress.id).then(workOrders => {
                    this.workOrders = workOrders;
                });


                this.customerRepository.getPolicyAccountingItems(this.policyId).then(accountingItems => {
                    this.accountingItems = accountingItems;
                });
            });

        });
    }

    editPaymentMethod() {
        const ref = this.dialog.open(UpdateWarrantyPaymentMethodComponent, { data: this.policySummary });
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.policySummary.stripeCardId = results.id;
                this.customerRepository.getStripeCardForPolicy(this.policySummary.holderId, this.policySummary.stripeCardId).then(card => {
                    this.currentStripeCard = card;
                    this.currentStripeCardLoaded = true;
                });
                this.missionService.showSuccessToast('Your payment method was updated');
            }
        });
    }

    addTenant() {
        const tenant = new Entity();
        tenant.id = UtilitiesService.newid();
        tenant.type = 'Tenant';
        tenant.parentId = this.policySummary.holderId;
        tenant.policyId = this.policySummary.id;
        if (!this.tenants) {
            this.tenants = [];
        }

        this.tenants.push(tenant);
    }

    saveTenants() {
        this.savingTenants = true;

        const promises = [];

        for (const tenant of this.tenants) {
            promises.push(this.entityApi.updateEntity(tenant, false));
        }
        if (this.tenantsToDelete) {
            for (const toDelete of this.tenantsToDelete) {
                promises.push(this.entityApi.deleteEntity(toDelete));
            }
        }

        Promise.all(promises).then(() => {
            this.savingTenants = false;
        });
    }

    deleteTenant(tenant: Entity) {
        this.tenants.splice(this.tenants.indexOf(tenant), 1);
        if (!this.tenantsToDelete) this.tenantsToDelete = [];
        this.tenantsToDelete.push(tenant);
    }

    private refreshPrepaidServiceFees() {
        if (!this.prepaidServiceFees) {
            this.policyApi.getPolicyPrepaidServiceFees(this.policySummary.id).then(prepaidServiceFees => {
                this.prepaidServiceFees = prepaidServiceFees;
            });
        }
    }


    get availablePrepaidServiceFees() {
        if (!this.prepaidServiceFees) {
            return 0;
        }

        return this.prepaidServiceFees.filter(i => !i.redeemedDate).length;
    }

    get availablePrepaidServiceFeesS() {
        return this.availablePrepaidServiceFees.toString();
    }

    get availablePrepaidServiceFeesPct() {
        if (!this.prepaidServiceFees || this.prepaidServiceFees.length === 0) {
            return 0;
        }

        return (this.availablePrepaidServiceFees / this.prepaidServiceFees.length) * 100;
    }

    private async setupCommands() {
        if (!this.objectCommands || !this.policySummary || !this.tags) {
            return;
        }

        this.extraCommands = [];

        outerLoop:
        for (const objectCommand of this.objectCommands) {
            const queueQuery = objectCommand.queueQuery;

            // Plans
            if (queueQuery.selectedPlans && queueQuery.selectedPlans.length > 0 && queueQuery.selectedPlans.filter(i => i.id === this.policySummary.planId).length === 0) {
                continue;
            }
            if (queueQuery.selectedPlansExcluded && queueQuery.selectedPlansExcluded.length > 0 && queueQuery.selectedPlansExcluded.filter(i => i.id === this.policySummary.planId).length > 0) {
                continue;
            }
            // Statuses
            if (queueQuery.selectedPolicyStatuses && queueQuery.selectedPolicyStatuses.length > 0 && queueQuery.selectedPolicyStatuses.indexOf(this.policySummary.status) === -1) {
                continue;
            }
            if (queueQuery.selectedPolicyStatusesExcluded && queueQuery.selectedPolicyStatusesExcluded.length > 0 && queueQuery.selectedPolicyStatusesExcluded.indexOf(this.policySummary.status) > -1) {
                continue;
            }
            // Coverage Type
            if (queueQuery.selectedCoverageTypes && queueQuery.selectedCoverageTypes.length > 0 && queueQuery.selectedCoverageTypes.indexOf(this.policySummary.coverageType) === -1) {
                continue;
            }
            if (queueQuery.selectedCoverageTypesExcluded && queueQuery.selectedCoverageTypesExcluded.length > 0 && queueQuery.selectedCoverageTypesExcluded.indexOf(this.policySummary.coverageType) > -1) {
                continue;
            }
            // Marketing Sources
            if (queueQuery.selectedMarketingSources && queueQuery.selectedMarketingSources.length > 0 && queueQuery.selectedMarketingSources.filter(i => i.id === this.policySummary.marketingSourceId).length === 0) {
                continue;
            }
            if (queueQuery.selectedMarketingSourcesExcluded && queueQuery.selectedMarketingSourcesExcluded.length > 0 && queueQuery.selectedMarketingSourcesExcluded.filter(i => i.id === this.policySummary.marketingSourceId).length > 0) {
                continue;
            }
            // Created By
            if (queueQuery.selectedEmployees && queueQuery.selectedEmployees.length > 0 && queueQuery.selectedEmployees.filter(i => i.id === this.policySummary.createdById).length === 0) {
                continue;
            }
            if (queueQuery.selectedEmployeesExcluded && queueQuery.selectedEmployeesExcluded.length > 0 && queueQuery.selectedEmployeesExcluded.filter(i => i.id === this.policySummary.createdById).length > 0) {
                continue;
            }
            // Account Executive
            if (queueQuery.selectedAccountExecutives && queueQuery.selectedAccountExecutives.length > 0 && queueQuery.selectedAccountExecutives.filter(i => i.id === this.policySummary.accountExecutiveId).length === 0) {
                continue;
            }
            if (queueQuery.selectedAccountExecutivesExcluded && queueQuery.selectedAccountExecutivesExcluded.length > 0 && queueQuery.selectedAccountExecutivesExcluded.filter(i => i.id === this.policySummary.accountExecutiveId).length > 0) {
                continue;
            }
            // Areas
            if (queueQuery.selectedRegions && queueQuery.selectedRegions.length > 0 && queueQuery.selectedRegions.filter(i => i.id === this.policySummary.areaId).length === 0) {
                continue;
            }
            if (queueQuery.selectedRegionsExcluded && queueQuery.selectedRegionsExcluded.length > 0 && queueQuery.selectedRegionsExcluded.filter(i => i.id === this.policySummary.areaId).length > 0) {
                continue;
            }
            // Regions
            if (queueQuery.selectedSalesRegions && queueQuery.selectedSalesRegions.length > 0 && queueQuery.selectedSalesRegions.filter(i => i.id === this.policySummary.salesRegionId).length === 0) {
                continue;
            }
            if (queueQuery.selectedSalesRegionsExcluded && queueQuery.selectedSalesRegionsExcluded.length > 0 && queueQuery.selectedSalesRegionsExcluded.filter(i => i.id === this.policySummary.salesRegionId).length > 0) {
                continue;
            }
            // Tags
            if (queueQuery.selectedTags && queueQuery.selectedTags.length > 0) {
                for (const tag of queueQuery.selectedTags) {
                    if (this.tags.filter(i => i.id === tag.id).length === 0) {
                        continue outerLoop;
                    }
                }
            }

            if (queueQuery.selectedTagsExcluded && queueQuery.selectedTagsExcluded.length > 0) {
                for (const tag of queueQuery.selectedTagsExcluded) {
                    if (this.tags.filter(i => i.id === tag.id).length > 0) {

                        continue outerLoop;
                    }
                }
            }

            this.extraCommands = this.extraCommands.concat(objectCommand.queueQuery.commands.filter(i => !i.hideFromUI));

        }
    }

    private refreshTags() {
        this.policyApi.getPolicyTags(this.policySummary.id).then(tags => {
            this.tags = tags;
            this.setupCommands();
        });
    }

    get mainToolbarExtraCommands(): QueueCommand[] {
        if (!this.extraCommands) {
            return null;
        }

        return this.extraCommands.filter(i => i.showOnMainToolbar);
    }

    get pocketExtraCommands(): QueueCommand[] {
        if (!this.extraCommands) {
            return null;
        }

        return this.extraCommands.filter(i => !i.showOnMainToolbar);
    }

    executeCommand(command: QueueCommand) {
        let queueQuery: QueueQuery;
        for (const objectCommand of this.objectCommands) {
            if (objectCommand.queueQuery.commands.indexOf(command) > -1) {
                queueQuery = objectCommand.queueQuery;
            }
        }
        this.commandRunner.startDoCommand(command.actions, null, null, null, queueQuery, () => this.refreshFromCommand(), () => this.refreshFromCommand(), (show, message) => {
            this.showWorkingMessage = show;
            this.workingMessage = message;
        }, this.policySummary, null, null, null, () => { }, null, null);
    }

    refreshFromCommand() {
        this.refreshTags();
        this.setupCommands();
    }

    changeAutoRenewal() {
        if (!this.policySummary.autoRenew && this.policySummary.doNotRenew) return

        const ref = this.dialog.open(ChangeAutoRenewalComponent, { data: this.policySummary });
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.policySummary.autoRenew = !this.policySummary.autoRenew;
            }
        });
    }


    private setupMenuItems() {
        const menuItems: ShellMenuItemModel[] = [];
        menuItems.push(new ShellMenuItemModel('Home', '', null, '/'));
        menuItems.push(new ShellMenuItemModel('Property Overview', '', () => this.selectedIndex = 0));
        menuItems.push(new ShellMenuItemModel('Service Requests', '', () => this.selectedIndex = 1));
        menuItems.push(new ShellMenuItemModel('Accounting', '', () => this.selectedIndex = 2));
        menuItems.push(new ShellMenuItemModel('Settings', null, null, '/settings'));

        if (menuItems[this.selectedIndex + 1]) {
            menuItems[this.selectedIndex + 1].active = true;
        }
        this.missionService.showOverrideMenuItems(menuItems);
    }

    get pastDueInvoices() {
        if (!this.accountingItems) {
            return [];
        }

        return this.accountingItems.filter(i => i.type === 'Invoice' && i.amountDue > 0 && i.dueDate < new Date());
    }

    get invoices(): AccountingItem[] {
        if (!this.accountingItems) {
            return [];
        }

        return this.accountingItems.filter(i => i.type === 'Invoice');
    }

    get paidInvoices(): AccountingItem[] {
        if (!this.accountingItems) {
            return [];
        }
        return this.accountingItems.filter(i => i.type === 'Invoice' && i.amountDue <= 0);
    }

    get unpaidInvoices() {
        if (!this.accountingItems) {
            return [];
        }

        const ids = this.paidInvoices.map(i => i.id);
        ids.concat(this.paidInvoices.map(i => i.id));

        return this.accountingItems.filter(i => i.type === 'Invoice' && ids.indexOf(i.id) === -1);

    }

    get payments(): AccountingItem[] {
        if (!this.accountingItems) {
            return [];
        }

        return this.accountingItems.filter(i => i.type === 'Payment');
    }

    swipeLeft() {
        if (this.selectedIndex < 2) {
            this.selectedIndex++;
        } else {
            this.selectedIndex = 0;
        }
    }

    swipeRight() {
        if (this.selectedIndex > 0) {
            this.selectedIndex--;
        } else {
            this.selectedIndex = 2;
        }
    }

    getInvoiceHtmlUrl(invoice: AccountingItem) {
        return `${ApiService.endPointDotNet}invoice/${invoice.id}/html`;
    }

    getPaymentHtmlUrl(payment: AccountingItem) {
        return `${ApiService.endPointDotNet}payment/${payment.altKey}/html`;
    }

    get statementUrl() {
        return `${ApiService.endPointDotNet}policy/${this.policyId}/statement/html`;
    }

    setHash(index) {
        if (index === 0) {
            document.location.hash = 'overview';
        } else if (index === 1) {
            document.location.hash = 'service-requests';
        } else if (index === 2) {
            document.location.hash = 'accounting';
        }
        setTimeout(() => {
            this.setupMenuItems();
        });
    }

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash(event) {
        const hash = window.location.hash;

        if (hash === '#overview') {
            this.selectedIndex = 0;
        } else if (hash === '#service-requests') {
            this.selectedIndex = 1;
        } else if (hash === '#accounting') {
            this.selectedIndex = 2;
        } else {
            this.selectedIndex = 0;
        }
    }

    get balanceDue() {
        if (this.pastDueInvoices.length === 0) {
            return 0;
        }

        return this.pastDueInvoices.map(i => i.amountDue).reduce((a, b) => a + b);
    }

    getThumbnailMargin(line, workOrder: WorkOrderSummaryClient) {
        return (workOrder.lines.indexOf(line) * 25) + 'px';
    }

    getThumbnailUrl(workOrderLine: any) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${workOrderLine.itemId}/photo`;
    }

    get openWorkOrderCount() {
        if (!this.workOrders) {
            return 0;
        }

        return this.workOrders.filter(i => this.closedStatuses.indexOf(i.status) === -1).length;
    }
}
