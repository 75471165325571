import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, AfterViewInit } from '@angular/core';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Address, WebQuote } from '@upkeeplabs/models/cogent';
import { PlanClient as Plan, PlanGridModel } from '@cogent/client/shared/models/plans/plan-client.model';
import { GoogleTagManagerService } from '@cogent/client/shared/services/google-tag-manager.service';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { Router } from '@angular/router';
import { ApiService } from '@cogent/client/api';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { ViewCoverageDialogComponent } from '@cogent/client/shared/components/plans-and-coverage/view-coverage/view-coverage.component';
import {
    MatDialog
} from '@angular/material/dialog';

declare var fbq;
@Component({
    selector: 'app-new-plan-offerings',
    templateUrl: './new-plan-offerings.component.html',
    styleUrls: ['./new-plan-offerings2.component.scss'],
})
export class NewPlanOfferingsComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() plans: Plan[];
    @Input() selectedPlan: Plan;
    @Output() selectedPlanChange: EventEmitter<Plan> = new EventEmitter();
    @Input() isMonthly: boolean;
    @Output() isMonthlyChange: EventEmitter<boolean> = new EventEmitter();
    @Input() titleBig = 'Plan';
    @Input() titleSmall = 'Selection';
    @Input() hideMonthlySelection: boolean;
    @Input() hideTitle = false;
    @Input() callToActionText = 'SELECT';
    @Input() address: Address;
    @Input() coverageType: any;
    @Input() scaleSmall = false;
    @Input() newConstruction = false;
    @Input() quote: WebQuote
    @Input() inline: boolean;
    @Input() hideOneTimeServices: boolean;
    @Input() newConfig = false;

    planGrid: PlanGridModel
    plansLength: number
    id = UtilitiesService.newid();
    highlightedPlan: Plan;

    paymentType: 'monthly' | 'yearly' = 'monthly';

    constructor(
        private googleTag: GoogleTagManagerService,
        private customerRepository: CustomerRepositoryService,
        private router: Router,
        private missionService: MissionService,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
    }


    ngAfterViewInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.plans) {

            this.plans.sort(this.sortPlans)
            if (this.hideOneTimeServices) {
                for (const p of this.plans) {
                    const services = p.standardItems.filter(i => i.category === 'Services');
                    for (const service of services) {
                        const i = p.standardItems.indexOf(service);
                        p.standardItems.splice(i, 1);
                    }
                }
            }

            this.planGrid = PlanGridModel.getPlanGridModel(this.plans);
            this.plansLength = this.plans.length

            this.planGrid.categories.sort((a, b) => {
                if (a.categoryName == 'Heating/Cooling Systems') return -1;
                if (b.categoryName == 'Heating/Cooling Systems') return 1;
                if (a.categoryName == 'Services') return 1;
                if (b.categoryName == 'Services') return -1;
                return 0;
            })
        }
    }

    get newApp() {
        return !ApiService.isLegacy;
    }

    getMaxWidth() {
        if (this.plans.length === 3) return '80rem'
        else if (this.plans.length === 2) return '65rem'
        else return '55rem'
    }

    getColumns() {
        let columnTemplate = window.innerWidth >= 470 ? '1.5fr ' : '1fr ';
        for (let i = 0; i < this.plans.length; i++) {
            columnTemplate += '1fr ';
        }
        return columnTemplate.trim()
    }

    sortPlans(a, b) {
        const order = { 'Essential': 0, "Elevated": 1, "Totally Elevated": 2 }
        const nameA = a.name || '';
        const nameB = b.name || '';
        if (nameA in order && nameB in order) {
            return order[nameA] - order[nameB];
        } else if (nameA in order) {
            return -1;
        } else if (nameB in order) {
            return 1;
        } else {
            return 0;
        }
    }

    selectedPlanChanged(selectedPlan: Plan) {
        this.selectedPlan = selectedPlan;
        this.selectedPlanChange.emit(selectedPlan);
    }

    pixelTrackCustom(value, additionalParams?) {
        try {
            if (fbq) {
                fbq("trackCustom", value, additionalParams);
            }
        } catch (err) {
            console.error(err);
        }
    }

    showCoverage(item) {
        this.dialog.open(ViewCoverageDialogComponent, { data: item.planItemId });
    }

    async planSelected(plan) {
        if (this.quote && !this.quote.name) {
            this.missionService.showWarningToast('Insufficient Information');
            return
        }

        if (this.inline) {
            this.selectedPlanChange.emit(plan);
            return;
        }

        this.quote.selectedPlanId = plan.id;
        this.pixelTrackCustom('PlanSelected');
        this.googleTag.sendData('quotePlanSelected');
        await this.customerRepository.saveWebQuote(this.quote);
        this.router.navigateByUrl(`/new-order?quoteId=${this.quote.id}`);
    }

    showSummaries() { //if one plan does not have a summary, do not show any plan summaries (consistency)
        const checkPlanSummaries = this.plans.filter(plan => plan.summary !== null && plan.summary !== undefined)
        if (checkPlanSummaries.length !== this.plans.length) return false
        return true
    }

    goToHomeSite() {
        this.router.navigateByUrl("https://elevatehomescriptions.com/")
    }


    displayPrice(originalPrice: string) {
        if (originalPrice.includes('.00')) {
            return originalPrice.split('.')[0]
        } else {
            return originalPrice
        }
    }

    getSampleContractUrl(item: any) {
        return ApiService.endPointNode + `Plan/contract/pdf/${item.id}`;
    }
}
