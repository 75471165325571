<!-- <div id="home-image"></div> -->
<div>
    <div class="container">

        <h1 topmargin40 style="font-size: 26px;">Property Selection
        </h1>

        <p class="info">Select the property that needs service</p>



            <div class="property-container">
                <a *ngFor="let policy of myPolicies" mat-button routerLink="{{slashBase}}request-service/{{policy.id}}"
                    class="property-button">
                    <img src="{{baseUrl}}policy/{{policy.id}}/StreetView"
                        style="">
                    <h3 topmargin40 bottommargin30>{{policy.propertyAddress.address1}}</h3>
                    <p>{{policy.propertyAddress.city}}, {{policy.propertyAddress.state}}
                        {{policy.propertyAddress.postalCode}}
                        <br> {{policy.planName}}
                    </p>

                </a>
            </div>

    </div>
</div>