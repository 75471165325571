import { Component, Inject } from '@angular/core';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {Clipboard} from '@angular/cdk/clipboard';
import { MissionService } from '@cogent/client/shared/services/mission-service';

@Component({
  selector: 'app-quote-promo',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './quote-promo.component.html',
  styleUrl: './quote-promo.component.scss'
})
export class QuotePromoComponent {
    constructor(
        // private dialog: MatDialog,
        private dialogRef: MatDialogRef<QuotePromoComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private missionService: MissionService,
        private clipboard: Clipboard,
    ) { }

    applyPromoCode() {
        this.clipboard.copy('MAYSAVINGS100');
        this.missionService.showSuccessToast('Code copied to clipboard!');
        this.dialogRef.close(true)
    }

    closePromo() {
        this.dialogRef.close(false)
    }
}
