import { Component, OnInit, HostListener, EventEmitter, ViewChild } from '@angular/core';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { NewPolicyModel } from '@cogent/shared/models/sales/new-policy-legacy.model';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewCoverageDialogComponent } from '@cogent/client/shared/components/service/view-coverage/view-coverage.component';
import { SalesItemModel } from '@cogent/shared/models/plans/sales-item.model';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { SearchResultModel } from '@cogent/shared/models/common/search-result.model';
import { PropertyMetaModel } from '@cogent/shared/models/common/property-meta.model';
import { Language, PromotionCodeSummary } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
import { GoogleTagManagerService } from '@cogent/client/shared/services/google-tag-manager.service';
import { Address, Entity, PhoneNumber } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Login } from '@cogent/shared/models/user/login.model';
import { EntityFindModalComponent } from '@cogent/client/apps/homeowner/general/entity-find/entity-find-modal.component';
import { CommonModule, DatePipe, PlatformLocation } from '@angular/common';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { StripeCard } from '@upkeeplabs/models/cogent';
import { CoverageType, PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';
import { VerifyHomeSqftComponent } from '@cogent/client/shared/components/customer-portal/verify-home-sqft/verify-home-sqft.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AddressEditorComponent } from '@cogent/client/shared/components/misc/address-editor/address-editor.component';
import { MatSelectModule } from '@angular/material/select';
import { FrownyFaceComponent } from '@cogent/client/shared/components/misc/frowny-face/frowny-face.component';
import { PlanOfferingsModule } from '@cogent/client/shared/components/plans-and-coverage/plan-offerings1/plan-offerings.module';
import { BasicEntityEntryModule } from '@cogent/client/shared/components/entities/basic-entity-entry/basic-entity-entry.module';
import { MatRadioModule } from '@angular/material/radio';
import { SearchChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/search-chip-selection/search-chip-selection.module';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';
import { MatCardModule } from '@angular/material/card';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { BasicEntitySummaryModule } from '@cogent/client/shared/components/entities/basic-entity-summary/basic-entity-summary.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { OrderSummaryComponent } from '@cogent/client/apps/homeowner/sales/order-summary/order-summary.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
    selector: 'app-new-order',
    templateUrl: './new-order.component.html',
    styleUrls: ['./new-order.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        AddressEditorComponent,
        MatSelectModule,
        FrownyFaceComponent,
        PlanOfferingsModule,
        BasicEntityEntryModule,
        MatRadioModule,
        SearchChipSelectionModule,
        MatCheckboxModule,
        PaymentMethodEntryModule,
        PaymentMethodEntryComponent,
        MatAutocompleteModule,
        ReactiveFormsModule,
        DatePickerWrapperComponent,
        MatCardModule,
        DisplayTotalModule,
        BasicEntitySummaryModule,
        MatDatepickerModule,
        CheckCircleAnimatedComponent,
        OrderSummaryComponent,
        NgxMaskDirective
    ]
})
export class NewOrderComponent implements OnInit {

    settingValues: boolean;
    coverageType: CoverageType = CoverageType.Homeowner;
    newPolicy: NewPolicyModel = new NewPolicyModel();
    selectedPlan: PlanClient;
    selectedPlanDelayed: PlanClient;
    plans: PlanClient[];
    selectedIndex = 0;
    buyersEntity: Entity;
    canCoverAddress: boolean;
    validatingAddress: boolean;
    numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    saving = false;
    newCustomerId: string = UtilitiesService.newid();
    newCoCustomerId: string = UtilitiesService.newid();
    minStartDate: Date = new Date();
    customerId: string;
    selectedCard: StripeCard;
    creditCardValid = false;
    submitting = false;
    loading = false;
    propertyMeta: PropertyMetaModel;
    promotionCode: PromotionCodeSummary;
    promotionCodeSearch: string;
    newCustomer: Entity;
    newCoCustomer: Entity;
    isCustomerValid = false;
    marketingSources: any[];
    selectedMarketingSource;
    savingCustomer = false;
    loggedInUser: Entity;
    doCreateLogin = true;
    newPassword: string;
    passwordConfirm: string;
    usePropertyAddressAsMailingAddress = true;
    mailingAddressComplete = false;
    mailingAddress: Address = new Address();
    amountToSpend: number;
    selectedCoverageType: string = 'RealEstate';
    defaultPlanId: string;
    customerPortalEnabled: boolean;
    isSellersCoverage = false;
    filteredItems: any;
    myControl = new UntypedFormControl();
    myClosingControl = new UntypedFormControl();
    mySellerControl = new UntypedFormControl();
    myClosingCompanyControl = new UntypedFormControl();
    myAgentCompanyControl = new UntypedFormControl();
    mySellerCompanyControl = new UntypedFormControl();
    myPropertyManagerControl = new UntypedFormControl();
    showCoBuyer = false;
    agentIdSetFromQuery = false;
    transactionType: string;
    embedded = false;
    isLegacy = true;

    showAgentStuff = false;
    selectedReferringAgent: SearchResultModel[];

    selectedReferringAe: string;
    showAeStuff = false;
    accountExecs: Entity[];

    searchForText: string;
    searchForTextChange: EventEmitter<string> = new EventEmitter<string>();
    searching = false;
    allSearchItems: any[];
    allTitleSearchItems: any[];
    allPropertyManagerSearchItems: any[];
    allSellerSearchItems: any[];
    allTitleCompanySearchItems: any[];
    allAgentCompanySearchItems: any[];
    allSellerCompanySearchItems: any[];
    isNewAgent: boolean = true;
    isNewTitle = true;
    isNewTitleCompany = true;
    isNewSeller = true;
    isNewSellerCompany = true;
    selectedAgentInfo: any;
    selectedTitleInfo: any;
    selectedSellerInfo: any;
    selectedTitleCompanyInfo: any;
    selectedSellerCompany: any;
    selectedPropertyManagerInfo: any;
    createdPolicy: any;

    isNewConstruction = false;

    languages: Language[];

    newClosingOfficerEmail: string;
    newClosingOfficerPhone: string;

    apiUrl: string;
    apiUrl2: string;

    isAgentPanelOpen: boolean;

    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    constructor(
        private customerRepository: CustomerRepositoryService,
        private dialog: DialogsService,
        private mdDialog: MatDialog,
        private missionService: MissionService,
        private entityApi: EntityApiService,
        private location: PlatformLocation,
        private activatedRoute: ActivatedRoute,
        private googleTag: GoogleTagManagerService,
        private planApi: PlanApiService,
        private apiService: ApiService,
        private dataAccessService: DataApiService,
    ) {
        this.apiService.getApiEndpointDotNet().then(url => this.apiUrl = url);
        this.apiService.getApiEndpointNode().then(url2 => this.apiUrl2 = url2);

        this.filteredItems = this.myControl.valueChanges.pipe(debounceTime(100))
            .subscribe(value => {

                if (typeof value === 'string') {
                    this.searchForText = value;
                    this.searchForTextChange.emit(this.searchForText);
                }
                if (value && !value.id) {
                    this.getData('Agent');
                }
            });

        const urlParams = new URLSearchParams(window.location.search);

        const embeddedParam = urlParams.get('embedded');
        if (embeddedParam === 'true') {
            try {
                this.embedded = true;
                document.getElementById('appHeader').style.display = 'none';
                document.getElementById('appHeader').style.height = '0px';

                document.getElementById('app-content').style.paddingTop = '3px';
            } catch (e) { }
        }
        const entityIdParam = urlParams.get('entityId');
        if (entityIdParam) {
            this.entityApi.getEntityUnAuthenticated(entityIdParam).then(async agent => {
                let parent: Entity = null;
                const selectedAgent = new SearchResultModel();
                selectedAgent.id = entityIdParam;
                if (agent.parentId) {
                    parent = await this.entityApi.getEntityUnAuthenticated(agent.parentId);
                    if (parent) {
                        selectedAgent.additionalInfo = parent.name;
                    }
                }
                selectedAgent.name = agent.name;

                this.newPolicy.newAgentName = selectedAgent.name;
                this.isNewAgent = false;
                selectedAgent.email = agent.email;
                selectedAgent.mobileNumber = agent.mobileNumberNumber;

                this.selectedAgentInfo = selectedAgent;
                this.agentIdSetFromQuery = true;
                this.newPolicy.policy.initiatorType = 'Buyer';
            });
        }

        this.filteredItems = this.myClosingControl.valueChanges.pipe(debounceTime(100))
            .subscribe(value => {

                if (typeof value === 'string') {
                    this.searchForText = value;
                    this.searchForTextChange.emit(this.searchForText);
                }
                if (value && !value.id) {
                    this.getData('ClosingOfficer');
                }
            });

        this.filteredItems = this.mySellerControl.valueChanges.pipe(debounceTime(100))
            .subscribe(value => {

                if (typeof value === 'string') {
                    this.searchForText = value;
                    this.searchForTextChange.emit(this.searchForText);
                }
                if (value && !value.id) {
                    this.getData('Seller');
                }
            });

        this.filteredItems = this.myClosingCompanyControl.valueChanges.pipe(debounceTime(100))
            .subscribe(value => {

                if (typeof value === 'string') {
                    this.searchForText = value;
                    this.searchForTextChange.emit(this.searchForText);
                }
                if (value && !value.id) {
                    this.getData('Title_Company');
                }
            });

        this.filteredItems = this.myAgentCompanyControl.valueChanges.pipe(debounceTime(100))
            .subscribe(value => {

                if (typeof value === 'string') {
                    this.searchForText = value;
                    this.searchForTextChange.emit(this.searchForText);
                }
                if (value && !value.id) {
                    this.getData('Branch');
                }
            });

        this.filteredItems = this.mySellerCompanyControl.valueChanges.pipe(debounceTime(100))
            .subscribe(value => {

                if (typeof value === 'string') {
                    this.searchForText = value;
                    this.searchForTextChange.emit(this.searchForText);
                }
                if (value && !value.id) {
                    this.getData('SellerBranch');
                }
            });

        this.filteredItems = this.myPropertyManagerControl.valueChanges.pipe(debounceTime(100))
            .subscribe(value => {
                if (typeof value === 'string') {
                    this.searchForText = value;
                    this.searchForTextChange.emit(this.searchForText);
                }
                if (value && !value.id) {
                    this.getData('PropertyManager');
                }
            });
    }

    private fromQuote = false;

    recordStepProgress(step: string, eventData: any = null) {

        const prefix = this.fromQuote ? '' : 'DIRECT-';
        this.googleTag.sendData(prefix + step, eventData);

    }

    async ngOnInit() {
        this.loadEmployees();

        this.loggedInUser = await this.entityApi.getLoggedInUser();

        this.newCustomer = new Entity();
        this.newCustomer.id = UtilitiesService.newid();
        this.newCustomer.type = 'Customer';
        this.newCustomer.preferredLanguageId = '31808049-f042-4c4c-a541-b77174e90b65';
        this.mailingAddress.id = UtilitiesService.newid();

        this.newCoCustomer = new Entity();
        this.newCoCustomer.id = UtilitiesService.newid();
        this.newCoCustomer.type = 'Customer';
        this.newCoCustomer.preferredLanguageId = '31808049-f042-4c4c-a541-b77174e90b65';

        this.addAdditionalFieldIfNeeded('Closing');
        this.addAdditionalFieldIfNeeded('Agent');
        this.addAdditionalFieldIfNeeded('Sellers');
        this.newPolicy.billTo = 'Title';

        document.location.hash = '0';
        this.customerRepository.getMarketingSources().then(marketingSources => {
            this.marketingSources = marketingSources.filter((ms) => ms.allowManualPick);
        });
        this.customerPortalEnabled = await this.customerRepository.getCustomerPortalEnabled();
        if (!this.customerPortalEnabled) {
            this.doCreateLogin = false;
        }

        this.entityApi.getLanguages().then(languages => this.languages = languages);

        this.activatedRoute.queryParams.subscribe(async params => {
            if (params.quoteId) {

                this.missionService.publish({
                    type: 'show-header',
                    messageBody: ''
                });
                const quoteId = params.quoteId;
                const quote = await this.customerRepository.getWebQuote(quoteId);
                if (quote) {
                    this.fromQuote = true;
                    this.newPolicy.property = UtilitiesService.copyObject(quote.address, null, () => new Address());
                    this.newPolicy.webQuoteId = quoteId;
                    if (quote.coverageType === 'RealEstate') {
                        this.setCoverageType('RealEstate');
                        this.coverageType = CoverageType.RealEstate;
                        this.setTransactionType('RealEstate');
                        this.doCreateLogin = false;

                    } else {
                        this.setTransactionType('Homeowner');
                        this.setCoverageType('Homeowner');
                        this.selectHomeownerBase();
                        this.doCreateLogin = true;
                    }
                    await this.updateSelection();
                    this.newCustomer.name = quote.name;
                    this.newCustomer.email = quote.email;


                    if (!this.newCustomer.phoneNumbers) this.newCustomer.phoneNumbers = [];
                    this.newCustomer.phoneNumbers.push(new PhoneNumber(quote.phone, 'Home'));
                    if (quote.promotionCode) {
                        this.customerRepository.getPromotionCode(quote.promotionCode, CoverageType.Homeowner).then(code => {
                            this.promotionCode = code;
                        });
                    }

                    const plan = this.plans.find(i => i.id === quote.selectedPlanId);
                    if (plan) {
                        this.selectedPlan = plan;
                        this.planSelected(plan);
                        this.selectedIndex = 3;
                    } else {
                        this.selectedIndex = 2;

                    }
                    if (quote.source) {
                        const marketingSources = await this.customerRepository.getMarketingSourceByName(quote.source);
                        if (marketingSources && marketingSources.length) {
                            this.newPolicy.policy.marketingSourceId = marketingSources[0].id;
                            this.selectedMarketingSource = marketingSources[0].id;
                        }
                    }
                }
            }
            if (params.customertype) {
                const customerType = params.customertype;
                if (customerType && customerType.toLowerCase() === 'homeowner') {
                    this.setTransactionType('Homeowner');
                } else if (customerType && customerType.toLowerCase() === 'realestate') {
                    this.setTransactionType('RealEstate');
                }
            }
        });
    }

    async loadEmployees() {
        this.accountExecs = await this.entityApi.getAccountExecutives();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent) {
        if (this.selectedIndex === 0 && this.selectedCoverageType) {
            event.cancelBubble = true;
            event.preventDefault();
        }

    }

    setTransactionType(transactionType: string) {
        this.transactionType = transactionType;
        if (transactionType === 'Homeowner') {
            this.selectHomeownerBase();
        }
    }

    goToStepIfAllowed(index: number) {
        if (this.selectedIndex > index) {
            this.selectedIndex = index;
        }
    }

    get showAddCoHolder() {
        return this.coverageType !== CoverageType.Homeowner;
    }

    setCoverageType(coverageType: string) {
        if (coverageType === 'Homeowner') {
            this.selectedCoverageType = coverageType;
            this.newPolicy.policy.isMonthly = true;
            this.doCreateLogin = this.selectedCoverageType !== 'RealEstate' && this.customerPortalEnabled;
            this.coverageType = this.selectedCoverageType !== 'RealEstate' ? CoverageType.Homeowner : CoverageType.RealEstate;

            if (this.selectedCoverageType === 'Homeowner') {
                this.selectBase();
            }
            return;
        }

        this.newPolicy.billTo = coverageType === 'Homeowner' ? 'Customer' : 'Agent';

        this.selectedCoverageType = null;
        setTimeout(() => {
            this.selectedCoverageType = coverageType;
            this.doCreateLogin = this.selectedCoverageType !== 'RealEstate' && this.customerPortalEnabled;
            this.coverageType = this.selectedCoverageType !== 'RealEstate' ? CoverageType.Homeowner : CoverageType.RealEstate;

            if (this.selectedCoverageType === 'Homeowner') {
                this.selectBase();
            }
        });
        //this.location.pushState('', '', 'new-order#0');
    }


    get contractUrl() {
        if (!this.createdPolicy) {
            return null;
        }

        return `${this.apiUrl2}Policy/contract/pdf/${this.createdPolicy.id}`;
    }

    get invoiceUrl() {
        if (!this.createdPolicy) {
            return null;
        }

        return `${this.apiUrl}Policy/${this.createdPolicy.id}/title-invoice/pdf`;
    }

    get certificateUrl() {
        if (!this.createdPolicy) {
            return null;
        }

        return `${this.apiUrl}Policy/${this.createdPolicy.id}/certificate/pdf?showContent=true`;
    }

    selectMostPopular() {
        this.selectedPlan = null;
        this.defaultPlanId = 'bf8e9e1c-037d-4126-b483-0267cc86e01f';
        this.amountToSpend = 0;
        this.selectedIndex = this.propertySelectedIndex;
        this.isSellersCoverage = false;
        this.coverageType = this.selectedCoverageType !== 'RealEstate' ? CoverageType.Homeowner : CoverageType.RealEstate;
        this.doCreateLogin = false;
        this.isNewConstruction = false;
    }

    selectedTotallyElevated() {
        this.selectedPlan = null;
        this.defaultPlanId = '76EF18FD-BB8E-4142-A698-1AF2DA0609C3';
        this.amountToSpend = 0;
        this.selectedIndex = this.propertySelectedIndex;
        this.isSellersCoverage = false;
        this.coverageType = this.selectedCoverageType !== 'RealEstate' ? CoverageType.Homeowner : CoverageType.RealEstate;
        this.doCreateLogin = false;
        this.isNewConstruction = false;
    }

    agentSearchOpen: boolean;
    agentSearchOpened() {
        this.agentSearchOpen = true;
    }

    agentSearchClosed() {
        this.agentSearchOpen = false;
    }

    propertyManagerSearchOpen: boolean;
    propertyManagerSearchOpened() {
        this.propertyManagerSearchOpen = true;
    }

    propertyManagerSearchClosed() {
        this.propertyManagerSearchOpen = false;
    }


    sellerSearchOpen: boolean;
    sellerSearchOpened() {
        this.sellerSearchOpen = true;
    }

    sellerSearchClosed() {
        this.sellerSearchOpen = false;
    }

    titleSearchOpen: boolean;
    titleSearchOpened() {
        this.titleSearchOpen = true;
    }

    titleSearchClosed() {
        this.titleSearchOpen = false;
    }

    titleSearchCompanyOpen = false;


    titleCompanySearchOpened() {
        this.titleSearchCompanyOpen = true;
    }
    titleCompanySearchClosed() {
        this.titleSearchCompanyOpen = false;
    }
    sellerCompanySearchOpened() {

    }
    sellerCompanySearchClosed() { }


    createNewAgent() {
        this.autocomplete.closePanel();
        this.isNewAgent = true;
        setTimeout(() => {
            document.getElementById('new-agent-company').focus();
        }, 200);
    }

    createNewSeller() {
        this.autocomplete.closePanel();
        this.isNewSeller = true;
        setTimeout(() => {
            document.getElementById('new-seller-company').focus();
        }, 200);
    }

    createNewTitle() {
        this.autocomplete.closePanel();
        this.isNewTitle = true;
        setTimeout(() => {
            document.getElementById('title-company-name').focus();
        }, 200);
    }

    selectBase() {
        this.selectedPlan = null;
        this.defaultPlanId = null;
        this.amountToSpend = 0;
        this.selectedIndex = this.propertySelectedIndex;
        this.isSellersCoverage = false;
        this.coverageType = this.selectedCoverageType !== 'RealEstate' ? CoverageType.Homeowner : CoverageType.RealEstate;
        this.newPolicy.isMonthly = false;
        this.newPolicy.policy.isMonthly = false;
        this.doCreateLogin = false;
        this.isNewConstruction = false;
    }

    selectHomeownerBase() {
        this.selectedPlan = null;
        this.defaultPlanId = null;
        this.amountToSpend = 0;
        this.selectedIndex = this.propertySelectedIndex;
        this.isSellersCoverage = false;
        this.selectedCoverageType = 'Homeowner';
        this.coverageType = CoverageType.Homeowner;
        this.newPolicy.isMonthly = true;
        this.newPolicy.policy.isMonthly = true;
        this.isNewConstruction = false;
    }

    selectAmountToSpend() {
        this.selectedPlan = null;
        this.defaultPlanId = null;
        if (!this.amountToSpend || this.amountToSpend < 300) {
            this.missionService.showToast('Please enter an amount that is at lease $400');
            document.getElementById('amount-to-spend-box').focus();
            return;
        }
        this.selectedIndex = this.propertySelectedIndex;
        this.isSellersCoverage = false;
        this.coverageType = this.selectedCoverageType !== 'RealEstate' ? CoverageType.Homeowner : CoverageType.RealEstate;
        this.newPolicy.isMonthly = false;
        this.newPolicy.policy.isMonthly = false;
        this.doCreateLogin = false;
        this.isNewConstruction = false;
    }

    selectSellersCoverage() {
        this.selectedPlan = null;
        this.defaultPlanId = null;
        this.amountToSpend = 0;
        this.selectedIndex = this.propertySelectedIndex;
        this.isSellersCoverage = true;
        this.coverageType = CoverageType.Sellers;
        this.newPolicy.isMonthly = false;
        this.newPolicy.policy.isMonthly = false;
        this.newPolicy.policy.initiatorType = "Seller";
        this.doCreateLogin = false;
        this.isNewConstruction = false;
    }

    selectNewConstruction() {
        this.selectedPlan = null;
        this.defaultPlanId = null;
        this.amountToSpend = 0;
        this.selectedIndex = this.propertySelectedIndex;
        this.isSellersCoverage = false;
        this.isNewConstruction = true;
        this.coverageType = CoverageType.RealEstate;
        this.newPolicy.isMonthly = false;
        this.newPolicy.policy.isMonthly = false;
        this.doCreateLogin = false;
    }

    getSampleContractUrl(item: any) {
        return ApiService.endPointNode + `Plan/contract/pdf/${item.id}`;
    }

    get totalSteps() {
        return 7;
    }

    completeClosingInformation() {
        this.newPolicy.policy.effectiveDate = this.minStartDateCalculated;
        this.selectedIndex = this.reviewSelectedIndex;
    }

    // if (!this.isCustomerValid) {
    //     return false;
    // }
    // if (this.showHowDidYouHearAboutUs && !this.selectedMarketingSource) {
    //     return false;
    // }
    // if (!this.usePropertyAddressAsMailingAddress && !this.mailingAddressComplete) {
    //     return false;
    // }

    // if (this.doCreateLogin) {
    //     if (!this.newPassword) {
    //         return false;
    //     }
    //     if (this.newPassword !== this.passwordConfirm) {
    //         return false;
    //     }
    // }




    async saveCustomerAndGoNext() {

        if (!this.newCustomer.name) {
            this.dialog.alert('Required', 'Sorry, the customer name is required');
            return;
        }
        if (this.coverageType === CoverageType.Homeowner) {
            if (!this.newCustomer.email || !UtilitiesService.validateEmail(this.newCustomer.email)) {

                this.dialog.alert('Required', 'The email address is required.');
                return;
            }
            if (!this.newCustomer.homeNumberNumber || !UtilitiesService.validatePhoneNumber(this.newCustomer.homeNumberNumber)) {

                this.dialog.alert('Required', 'The phone number is required.');
                return;
            }
            if (this.doCreateLogin) {
                if (!this.newPassword || this.newPassword !== this.passwordConfirm) {
                    this.dialog.alert('No Match', 'The password is required and must match the confirmation.');
                    return;
                }
            }
        }
        this.savingCustomer = true;
        if (this.doCreateLogin) {
            const userExists = await this.customerRepository.loginExists(this.newCustomer.email);
            if (userExists) {
                this.dialog.confirm('Login Exists', `It looks like the email address: ${this.newCustomer.email} has already been registered.<br><br>
                Would you like to continue without creating a new account?`).subscribe(results => {
                    if (results) {
                        this.doCreateLogin = false;
                        this.saveCustomerAndGoNext();
                    } else {
                        this.savingCustomer = false;
                    }
                });

                return;
            } else {
                const login = new Login();
                login.userName = this.newCustomer.email;
                login.passwordHash = this.newPassword;
                login.entityId = this.newCustomer.id;
                login.entityType = 'Customer';
                login.id = UtilitiesService.newid();
                if (this.newCustomer.phoneNumbers) {
                    for (const phoneNumber of this.newCustomer.phoneNumbers) {
                        if (!phoneNumber.id) {
                            phoneNumber.id = UtilitiesService.newid();
                        }
                        phoneNumber.entityId = this.newCustomer.id;
                    }
                }

                await this.customerRepository.completeNewRegistration({
                    login,
                    entity: this.newCustomer,
                    entityAddress: this.newCustomer.address,
                    entityPhoneNumbers: this.newCustomer.phoneNumbers,
                });
                this.newCustomer.loginId = login.id;

                this.savingCustomer = false;
                this.selectedIndex = this.selectedCoverageType === 'RealEstate' ? this.closingInfoSelectedIndex : this.paymentSelectedIndex;
                this.recordStepProgress('customerComplete');
            }
        } else {
            await this.customerRepository.saveCustomerAndPhone(this.newCustomer);
            if (this.newCoCustomer && this.newCoCustomer.name) {
                await this.customerRepository.saveCustomerAndPhone(this.newCoCustomer);
            }
            this.savingCustomer = false;
            this.selectedIndex = this.selectedCoverageType === 'RealEstate' ? this.closingInfoSelectedIndex : this.paymentSelectedIndex;
            this.recordStepProgress('customerComplete');
        }
    }


    get closingInfoComplete() {
        if (!this.newPolicy) {
            return false;
        }

        if (this.newPolicy.policy.initiatorType === 'Buyer') {
            return this.newPolicy.buyersAgent;
        }
        if (this.newPolicy.policy.initiatorType === 'Seller') {
            return this.newPolicy.sellersAgent;
        }
        if (this.newPolicy.policy.initiatorType === 'Title') {
            return this.newPolicy.closingOfficer;
        }
        return true;
    }

    loadingPlans = false;
    async updateSelection() {
        if (this.settingValues) {
            return;
        }
        this.loadingPlans = true;
        this.plans = [];

        const age = new Date().getFullYear() - this.newPolicy.property.yearBuilt;
        console.log(this.coverageType);
        this.plans = await this.planApi.getOfferedPlans(this.coverageType,
            this.newPolicy.property.dwellingType as any,
            this.newPolicy.property.postalCode,
            this.newPolicy.property.squareFeet, age, null, this.newPolicy.property.latitude, this.newPolicy.property.longitude, null, this.isNewConstruction);

        if (!this.isNewConstruction) {
            this.plans = this.plans.filter(i => i.showOnWeb);
        }

        this.loadingPlans = false;
        for (const plan of this.plans) {
            if (plan.planOptionalItems) {
                plan.planOptionalItems = plan.planOptionalItems.filter(i => i.showOnWeb);
            }
            if (plan.optionalItems) {
                plan.optionalItems = plan.optionalItems.filter(i => i.showOnWeb);
            }
        }

        if (this.amountToSpend > 0) {
            this.selectedPlan = this.plans.filter(i => i.price <= this.amountToSpend).sort((a, b) => a.price > b.price ? -1 : 1)[0];
            if (this.selectedPlan && this.selectedIndex === this.planSelectionIndex) {
                this.planSelected(this.selectedPlan);
            }
        } else if (this.defaultPlanId) {
            this.selectedPlan = this.plans.find(i => i.id.toLocaleLowerCase() === this.defaultPlanId.toLocaleLowerCase());
            if (this.selectedPlan && this.selectedIndex === this.planSelectionIndex) {
                this.planSelected(this.selectedPlan);
            }
        }
        if (this.isSellersCoverage && this.plans.length === 1) {
            this.selectedPlan = this.plans[0];
            this.planSelected(this.selectedPlan);
        }
    }

    async findPromotionCode() {
        this.promotionCode = null;
        const code = await this.customerRepository.getPromotionCode(this.promotionCodeSearch, this.coverageType);
        if (code) {
            let msg = "";

            // CHECK IF CODE IS EXPIRED
            if (code.expirationDate) {
                const today = new Date();
                let expired = false;
                if (code.expirationDate.getFullYear() < today.getFullYear()) {
                    expired = true;
                }
                else if (code.expirationDate.getFullYear() === today.getFullYear()) {
                    if (code.expirationDate.getMonth() < today.getMonth()) {
                        expired = true;
                    }
                    else if (code.expirationDate.getMonth() === today.getMonth()) {
                        if (code.expirationDate.getUTCDate() < today.getUTCDate()) {
                            expired = true;
                        }
                    }
                }

                if (expired) {
                    const expYear = code.expirationDate.getFullYear();
                    const expMonth = code.expirationDate.getMonth() + 1;
                    const expDate = code.expirationDate.getUTCDate();
                    msg += 'Sorry, that promotion code expired on ' + expMonth + '/' + expDate + '/' + expYear + '<br>';
                }
                else {
                    this.promotionCode = code;
                }
            }

            if (msg != "") {
                this.dialog.alert('Expired', msg);
            }
            else {

                if (this.selectedPlan.coverageType === 1) {
                    // CHECK IF CODE DOES NOT APPLY TO HOMEOWNER
                    if (!code.appliesToD2C) {
                        this.promotionCode = null;
                        if (code.appliesToRealEstate) {
                            msg += 'Sorry, that promotion code is for Real Estate plans';
                        }
                        else if (code.appliesToRenewal) {
                            msg += 'Sorry, that promotion code is for Renewals';
                        }
                        this.dialog.alert('Invalid Code', msg);
                    } else {
                        this.promotionCode = code;
                    }
                } else if (this.selectedPlan.coverageType === 0) {
                    // CHECK IF CODE DOES NOT APPLY TO REAL ESTATE
                    if (!code.appliesToRealEstate) {
                        this.promotionCode = null;
                        if (code.appliesToD2C) {
                            msg += 'Sorry, that promotion code is for Homeowner plans';
                        }
                        else if (code.appliesToRenewal) {
                            msg += 'Sorry, that promotion code is for Renewals';
                        }
                        this.dialog.alert('Invalid Code', msg);
                    } else {
                        this.promotionCode = code;
                    }
                }
            }
        }
        else {
            this.dialog.alert('Not Found', 'Sorry, that promotion code could not be found');
            this.promotionCodeSearch = '';
        }
    }

    get showHowDidYouHearAboutUs() {
        //return false;
        if (this.selectedCoverageType === 'RealEstate') {
            return false;
        }
        if (this.selectedCoverageType === 'Sellers') {
            return false;
        }
        if (this.fromQuote) {
            return false;
        }
        return (!this.loggedInUser || this.loggedInUser.type !== 'Agent');
    }

    marketingSourceChanged(id: string) {
        this.showAgentStuff = this.marketingSources.find(i => i.id === id)?.promptForAgent;

        if (this.marketingSources.find(i => i.id === id)?.name === "Account Executive") this.showAeStuff = true;
        else this.showAeStuff = false;
    }

    get theReferringAe() {
        if (!this.showAeStuff) return null;
        return this.selectedReferringAe;
    }

    get theReferringAgent() {
        if (!this.showAgentStuff) {
            return null;
        }

        return this.selectedReferringAgent ? this.selectedReferringAgent[0] : null;
    }

    get adjustmentAmount() {
        if (!this.promotionCode || !this.selectedPlan) {
            return 0;
        }

        if (this.promotionCode.priceAdjustmentType === 'Flat') {
            return this.newPolicy.policy.isMonthly ? this.promotionCode.priceAdjustment / this.selectedPlan.monthsOfCoverage : this.promotionCode.priceAdjustment;
        } else if (this.promotionCode.priceAdjustmentType === 'Percent') {
            return parseFloat((this.promotionCode.priceAdjustment * this.planBasePrice).toFixed(2));
        }
        else if (this.promotionCode.priceAdjustmentType === "Months") {
            this.newPolicy.policy.isMonthly = true;
            return 0;
        }
    }

    get addressValid() {
        return this.newPolicy.property.address1 &&
            this.newPolicy.property.city &&
            this.newPolicy.property.state &&
            this.newPolicy.property.postalCode &&
            this.newPolicy.property.dwellingType &&
            this.newPolicy.property.squareFeet;
    }

    get canSaveCustomer() {
        if (this.savingCustomer) {
            return false;
        }

        return true;
    }

    get selectedCardTypeUrl() {
        if (!this.selectedCard) {
            return null;
        }

        if (this.selectedCard.brand === 'Visa') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/visa.png';
        } else if (this.selectedCard.brand === 'Discover') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/discover.png';
        } else if (this.selectedCard.brand === 'American Express') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/americanexpress.png';
        } else if (this.selectedCard.brand === 'MasterCard') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mastercard.png';
        }
    }

    get addressFilledOut() {
        return this.newPolicy.property.address1 &&
            this.newPolicy.property.city &&
            this.newPolicy.property.state &&
            this.newPolicy.property.postalCode;
    }

    async validateAddress() {
        this.validatingAddress = true;
        const result = await this.customerRepository.checkForCoverage(this.newPolicy.property);

        this.canCoverAddress = result.canCover;

        if (this.canCoverAddress) {
            this.selectedIndex = this.planSelectionIndex;
            if (this.selectedPlan) {
                this.planSelected(this.selectedPlan);
            }
        }

        if (result.outOfArea) {
            this.dialog.alert('Out of area', 'Sorry, the postal code ' + this.newPolicy.property.postalCode
                + ' is out of the coverage area.');
        } else if (result.existingCoverage) {
            this.dialog.alert('Warning', 'There is already an active subscription at that address');
            this.canCoverAddress = true;
            this.selectedIndex = this.planSelectionIndex;
            if (this.selectedPlan) {
                this.planSelected(this.selectedPlan);
            }
        }
        this.validatingAddress = false;
        this.updateSelection();
    }

    async useMailingAddress() {
        const user = await this.entityApi.getLoggedInUser();
        const address: Address = await this.customerRepository.getCustomerMailingAddress(user.id);

        this.newPolicy.property.address1 = address.address1;
        this.newPolicy.property.address2 = address.address2;
        this.newPolicy.property.city = address.city;
        this.newPolicy.property.state = address.state;
        this.newPolicy.property.postalCode = address.postalCode;

        this.propertySelected();
    }

    planSelected(plan: PlanClient) {
        this.newPolicy.policy.planId = plan.id;
        this.selectedIndex = (this.amountToSpend > 0 || plan.optionalItems.length === 0) ? this.customerSelectedIndex : this.optionsSelectedIndex;
        const effectiveDate = new Date();
        effectiveDate.setDate(effectiveDate.getDate() + plan.homeownerWaitingPeriod);
        this.newPolicy.policy.effectiveDate = effectiveDate;
        this.minStartDate = new Date(effectiveDate);
        setTimeout(() => this.selectedPlanDelayed = plan, 1000);
    }

    get minStartDateCalculated() {
        if (this.selectedCoverageType === 'RealEstate') {
            if (this.newPolicy.policy.estimatedClosingDate) {
                return this.newPolicy.policy.estimatedClosingDate;
            } else {
                return new Date();
            }
        } else {
            return this.minStartDate;
        }
    }

    get canSubmit() {
        return this.newPolicy
            && this.newPolicy.property
            && this.newPolicy.property.isFilledOut
            && (this.selectedCard || this.selectedCoverageType === 'RealEstate')
            && this.selectedPlan;
    }

    paymentValid(isValid) {
        this.creditCardValid = isValid;
        if (isValid) {
            this.selectedIndex = this.reviewSelectedIndex;
            this.recordStepProgress('payEntered');
        }
    }



    @HostListener('window:hashchange', ['$event'])
    watchUrlHash() {
        let hash = window.location.hash;
        if (hash) {
            hash = hash.replace('#', '');
        }


        let hashNumber = parseInt(hash, 10);

        if (hashNumber === 0) {
            this.selectedCoverageType = 'RealEstate';
        }
        if (isNaN(hashNumber)) {
            hashNumber = 0;
        }

        if (!isNaN(hashNumber) && !this.isConfirmationStep) {
            this.selectedIndex = hashNumber;
        }
        if (this.selectedIndex === 0) {
            this.transactionType = null;
        }

    }

    setHash(index) {
        document.location.hash = index;
        if (index <= 1) {
            this.selectedPlanDelayed = null;

        }
        if (index === 6 && this.coverageType == CoverageType.RealEstate) {
            if (this.selectedAgentInfo) {
                setTimeout(() => {
                    (document.getElementById('new-agent-name') as any).value = this.selectedAgentInfo.name;
                });
            }
        }
        if (index === 6 && this.coverageType == CoverageType.Sellers) {
            //
            if (this.selectedAgentInfo) {
                setTimeout(() => {
                    (document.getElementById('new-seller-agent-name') as any).value = this.selectedAgentInfo.name;
                });
            }
        }
        if (index === 4 && this.coverageType === CoverageType.Sellers) {
            this.setupEntities();
        }
        if (index === 7 && this.coverageType !== CoverageType.Homeowner) {
            this.setupEntities();
        }
    }

    get isConfirmationStep() {
        return this.selectedIndex === this.confirmationStepNumber;
    }

    get coverageIndex() {
        return 0;
    }

    get propertySelectedIndex() {
        return 1;
    }

    get planSelectionIndex() {
        return 2;
    }

    get optionsSelectedIndex() {
        return 3;
    }

    get customerSelectedIndex() {
        return 4;
    }

    get paymentSelectedIndex() {
        return 5;
    }

    get closingInfoSelectedIndex() {
        return 6;
    }

    get reviewSelectedIndex() {
        return 7;
    }

    get confirmationStepNumber() {
        return 8;
    }


    get planBasePrice(): number {
        if (!this.selectedPlan) {
            return 0;
        }

        let amount = 0;
        if (this.newPolicy.policy.isMonthly) {
            amount = this.selectedPlan.priceRecurring;
            this.selectedPlan.optionalItems.forEach(optionalItem => {
                if (optionalItem.isDefault && !optionalItem.selected) {
                    amount -= optionalItem.priceRecurring;
                }
            });
        } else {
            amount = this.selectedPlan.price;
            this.selectedPlan.optionalItems.forEach(optionalItem => {
                if (optionalItem.isDefault && !optionalItem.selected) {
                    amount -= optionalItem.price;
                }
            });
        }
        return amount;
    }

    get optionsPrice(): number {
        if (!this.selectedPlan) {
            return;
        }
        let total = 0;
        this.selectedPlan.optionalItems.filter(i => i.selected && !i.isDefault).forEach(item => total += item.quantity
            * (this.newPolicy.policy.isMonthly ? item.priceRecurring : item.price));
        if (this.amountToSpend > 0 && this.amountToSpend > (this.planBasePrice + this.adjustmentAmount + total)) {
            return this.amountToSpend - (this.planBasePrice + this.adjustmentAmount);
        }


        return total;
    }

    get calculatedOptionsPrice(): number {
        if (!this.selectedPlan) {
            return;
        }
        let total = 0;
        this.selectedPlan.optionalItems.filter(i => i.selected && !i.isDefault).forEach(item => total += item.quantity
            * (this.newPolicy.policy.isMonthly ? item.priceRecurring : item.price));
        return total;
    }

    get totalPlanPrice(): number {
        return this.planBasePrice + this.adjustmentAmount + this.optionsPrice;
    }

    get totalPriceDisplay(): number {
        if (!this.amountToSpend || this.amountToSpend <= this.totalPlanPrice) {
            return this.totalPlanPrice;
        }

        return this.amountToSpend;
    }

    get showGenericPlanAndOptions() {
        return this.amountToSpend && this.amountToSpend >= this.totalPlanPrice;
    }

    get selectedOptions(): SalesItemModel[] {
        const results: SalesItemModel[] = [];

        if (this.selectedPlan) {
            this.selectedPlan.optionalItems.filter(i => i.selected && i.quantity > 0).forEach(i => results.push(i));
        }
        return results;
    }

    get selectedOptionsList() {
        if (!this.selectedPlan) {
            return null;
        }

        return this.selectedOptions.map(i => i.name).join(', ');
    }

    get selectedOptionsNoneDefault() {
        if (!this.selectedOptions) {
            return [];
        }

        return this.selectedOptions.filter(i => !i.isDefault);
    }

    optionSelected(option: SalesItemModel) {
        if (option.selected && option.quantity === 0) {
            option.quantity = 1;
        }
        if (!option.selected) {
            option.quantity = 0;
        }
    }

    addressCompleteChange(complete: boolean) {
        if (complete && !this.propertyMeta && !this.gettingMeta) {
            this.propertySelected();
        }
    }

    findClosingOfficer() {
        EntityFindModalComponent.FindEntity(
            this.mdDialog, 'ClosingOfficer', entity => {
                this.newPolicy.closingOfficer = entity;
            });
    }

    clearClosingOfficer() {
        this.newPolicy.closingOfficer = null;
    }

    findBuyersAgent() {
        EntityFindModalComponent.FindEntity(this.mdDialog, 'Agent', entity => {
            this.newPolicy.buyersAgent = entity;
        });
    }

    findSellersAgent() {
        EntityFindModalComponent.FindEntity(this.mdDialog, 'Agent', entity => {
            this.newPolicy.sellersAgent = entity;
        });
    }

    clearSellersAgent() {
        this.newPolicy.sellersAgent = null;
    }

    clearBuyersAgent() {
        this.newPolicy.buyersAgent = null;
    }

    gettingMeta: boolean;
    async propertySelected() {
        this.gettingMeta = true;
        this.propertyMeta = await this.customerRepository.getPropertyMeta(this.newPolicy.property.address1, this.newPolicy.property.postalCode);
        this.gettingMeta = false;
        this.settingValues = true;
        this.newPolicy.property.squareFeet = this.propertyMeta.sqft;
        this.newPolicy.property.dwellingType = this.propertyMeta.useCode;
        this.newPolicy.property.latitude = this.propertyMeta.lat;
        this.newPolicy.property.longitude = this.propertyMeta.lon;
        this.newPolicy.property.yearBuilt = this.propertyMeta.yearBuilt;
        this.settingValues = false;
        // this.updateSelection();
        if (this.propertyMeta.squareFeetNotFound) {
            //this.missionService.showToast('Property Information Not Found\nPlease enter the square footage for the property.');
            this.mdDialog.open(VerifyHomeSqftComponent, { data: this.newPolicy.property });
            // setTimeout(() => {
            //     document.getElementById('property-square-footage').focus();
            // })
        }
    }

    get propertyStreetView(): string {
        if (!this.newPolicy.property || !this.newPolicy.property.address1) {
            return null;
        }

        const url = 'https://maps.googleapis.com/maps/api/streetview?size=150x150&location='
            + this.newPolicy.property.address1 +
            ' ' + this.newPolicy.property.city + ' ' + this.newPolicy.property.state
            + ' ' + this.newPolicy.property.postalCode + '&key=AIzaSyB1xEizJ3bh0fvxTQkB-Pit7cPkzgr-tl0';

        return encodeURI(url);
    }

    showCoverage(item) {
        this.mdDialog.open(ViewCoverageDialogComponent, { data: item.planItemId });
    }

    get amountToSpendLessBase() {
        if (!this.selectedPlan) {
            return 0;
        }

        return this.amountToSpend - this.planBasePrice - this.calculatedOptionsPrice - this.adjustmentAmount;
    }



    get canCreatePolicy() {
        if (!this.newPolicy.policy.planId) {
            return false;
        }
        if (!this.newPolicy.holder.name) {
            return false;
        }
        if (!this.newPolicy.holder.address.isFilledOut) {
            return false;
        }
        if (!this.newPolicy.property.isFilledOut) {
            return false;
        }

        return true;
    }

    async setupEntities() {
        if (!this.isNewTitle) {
            const titleEntity = await this.entityApi.getEntityUnAuthenticated(this.selectedTitleInfo.id);
            this.newPolicy.closingOfficer = titleEntity;
        } else {
            if (this.newPolicy.newEscrowName) {
                this.newPolicy.closingOfficer = new Entity();
                this.newPolicy.closingOfficer.type = 'ClosingOfficer';
                this.newPolicy.closingOfficer.id = UtilitiesService.newid();
                if (this.newPolicy.newEscrowName && (this.newPolicy.newEscrowName as any).name) {
                    this.newPolicy.closingOfficer.name = (this.newPolicy.newEscrowName as any).name;
                } else {
                    this.newPolicy.closingOfficer.name = this.newPolicy.newEscrowName;
                }
                this.newPolicy.closingOfficer.email = this.newPolicy.newEscrowEmail;
                this.newPolicy.closingOfficer.phoneNumbers = [];
                if (this.newPolicy.newEscrowPhone) {
                    const phoneNumber = new PhoneNumber();
                    phoneNumber.type = 'Work';
                    phoneNumber.id = UtilitiesService.newid();
                    phoneNumber.number = this.newPolicy.newEscrowPhone;
                    this.newPolicy.closingOfficer.phoneNumbers.push(phoneNumber);

                }
                if (this.isNewTitleCompany) {
                    if (this.newPolicy.newEscrowCompany) {
                        const parent = new Entity();
                        parent.type = 'Title_Company';
                        parent.id = UtilitiesService.newid();
                        parent.name = this.newPolicy.newEscrowCompany;
                        this.newPolicy.closingOfficer.parent = parent;
                        this.newPolicy.closingOfficer.parentId = parent.id;
                    }
                } else if (this.selectedTitleCompanyInfo) {
                    const titleCompany = await this.entityApi.getEntityUnAuthenticated(this.selectedTitleCompanyInfo.id);
                    this.newPolicy.closingOfficer.parent = titleCompany;
                    this.newPolicy.closingOfficer.parentId = titleCompany.id;
                }
            }
        }

        if (!this.isNewAgent) {
            const agent = await this.entityApi.getEntityUnAuthenticated(this.selectedAgentInfo.id);
            if (this.coverageType === CoverageType.Sellers) {
                this.newPolicy.sellersAgent = agent;
            } else {
                this.newPolicy.buyersAgent = agent;
            }
        } else {
            if (this.newPolicy.newAgentName) {
                this.newPolicy.buyersAgent = new Entity();
                this.newPolicy.buyersAgent.id = UtilitiesService.newid();
                this.newPolicy.buyersAgent.name = this.newPolicy.newAgentName;
                this.newPolicy.buyersAgent.email = this.newPolicy.newAgentEmail;
                this.newPolicy.buyersAgent.type = 'Agent';
                this.newPolicy.buyersAgent.phoneNumbers = [];
                if (this.newPolicy.newAgentName && (this.newPolicy.newAgentName as any).name) {
                    this.newPolicy.buyersAgent.name = (this.newPolicy.newAgentName as any).name;
                } else {
                    this.newPolicy.buyersAgent.name = this.newPolicy.newAgentName;
                }
                if (this.newPolicy.newAgentPhone) {
                    const phoneNumber = new PhoneNumber();
                    phoneNumber.type = 'Work';
                    phoneNumber.id = UtilitiesService.newid();
                    phoneNumber.number = this.newPolicy.newAgentPhone;
                    this.newPolicy.buyersAgent.phoneNumbers.push(phoneNumber);

                }
                if (this.newPolicy.newAgentCompany) {
                    if (this.isNewAgentCompany) {
                        const parent = new Entity();
                        parent.id = UtilitiesService.newid();
                        parent.name = this.newPolicy.newAgentCompany;
                        parent.type = 'Branch';
                        this.newPolicy.buyersAgent.parent = parent;
                        this.newPolicy.buyersAgent.parentId = parent.id;

                    } else if (this.selectedAgentCompany) {
                        const branch = await this.entityApi.getEntityUnAuthenticated(this.selectedAgentCompany.id);
                        this.newPolicy.buyersAgent.parentId = branch.id;
                        this.newPolicy.buyersAgent.parent = branch;
                    }
                }
            }
        }

        if (!this.isNewSeller) {
            const agent = await this.entityApi.getEntityUnAuthenticated(this.selectedSellerInfo.id);
            this.newPolicy.sellersAgent = agent;
        } else {
            if (this.newPolicy.newSellerName) {
                this.newPolicy.sellersAgent = new Entity();
                this.newPolicy.sellersAgent.id = UtilitiesService.newid();
                this.newPolicy.sellersAgent.name = this.newPolicy.newSellerName;
                this.newPolicy.sellersAgent.email = this.newPolicy.newSellerEmail;
                this.newPolicy.sellersAgent.type = 'Agent';
                this.newPolicy.sellersAgent.phoneNumbers = [];
                if (this.newPolicy.newSellerName && (this.newPolicy.newSellerName as any).name) {
                    this.newPolicy.sellersAgent.name = (this.newPolicy.newSellerName as any).name;
                } else {
                    this.newPolicy.sellersAgent.name = this.newPolicy.newSellerName;
                }

                if (this.newPolicy.newSellerPhone) {
                    const phoneNumber = new PhoneNumber();
                    phoneNumber.type = 'Work';
                    phoneNumber.id = UtilitiesService.newid();
                    phoneNumber.number = this.newPolicy.newSellerPhone;
                    this.newPolicy.sellersAgent.phoneNumbers.push(phoneNumber);

                }
                if (this.newPolicy.newSellerCompany) {
                    if (this.isNewSellerCompany) {
                        const parent = new Entity();
                        parent.id = UtilitiesService.newid();
                        parent.name = this.newPolicy.newSellerCompany;
                        parent.type = 'Branch';
                        this.newPolicy.sellersAgent.parent = parent;
                        this.newPolicy.sellersAgent.parentId = parent.id;

                    } else if (this.selectedSellerCompany) {
                        const branch = await this.entityApi.getEntityUnAuthenticated(this.selectedSellerCompany.id);
                        this.newPolicy.sellersAgent.parentId = branch.id;
                        this.newPolicy.sellersAgent.parent = branch;
                    }
                    // const parent = new Entity();
                    // parent.id = UtilitiesService.newid();
                    // parent.name = this.newPolicy.newSellerCompany;
                    // parent.type = 'Branch';
                    // this.newPolicy.sellersAgent.parent = parent;
                    // this.newPolicy.sellersAgent.parentId = parent.id;
                }
            }
        }
    }

    async createOrder() {
        if (this.newPolicy.policy.effectiveDate < this.minStartDateCalculated && this.selectedCoverageType === 'Homeowner') {

            this.newPolicy.policy.effectiveDate = new Date(this.minStartDateCalculated);
            const datePipe = new DatePipe('en-US');
            this.dialog.alert('Effective Date', `Sorry, this subscription can\'t go effective before ${datePipe.transform(this.minStartDateCalculated, 'shortDate')}`);
            return;
        }
        this.submitting = true;




        if (this.isNewTitle && this.newPolicy.closingOfficer) {

            // Somehow masked email addresses are getting saved
            if (this.newPolicy.closingOfficer.parent && (!this.newPolicy.closingOfficer.parent.email || this.newPolicy.closingOfficer.parent.email.indexOf('xxxxxxx') === -1)) {
                await this.entityApi.updateEntityUnAuthenticated(this.newPolicy.closingOfficer.parent);
            }
            if (!this.newPolicy.closingOfficer.email || this.newPolicy.closingOfficer.email.indexOf('xxxxxxx') === -1) {
                await this.entityApi.updateEntityUnAuthenticated(this.newPolicy.closingOfficer);
            }
        }
        if (this.isNewAgent && this.newPolicy.buyersAgent) {
            if (this.newPolicy.buyersAgent.parent) {
                await this.entityApi.updateEntityUnAuthenticated(this.newPolicy.buyersAgent.parent);
            }
            await this.entityApi.updateEntityUnAuthenticated(this.newPolicy.buyersAgent);
        }
        if (this.isNewSeller && this.newPolicy.sellersAgent) {
            if (this.newPolicy.sellersAgent.parent) {
                await this.entityApi.updateEntityUnAuthenticated(this.newPolicy.sellersAgent.parent);
            }
            await this.entityApi.updateEntityUnAuthenticated(this.newPolicy.sellersAgent);
        }

        if (!this.newPolicy.policy.id) {
            this.newPolicy.policy.id = UtilitiesService.newid();
        }

        this.newPolicy.holder = this.newCustomer;
        if (!this.usePropertyAddressAsMailingAddress) {
            if (!this.mailingAddress || !this.mailingAddress.address1 || this.mailingAddress.address1.trim() === "") {
                this.usePropertyAddressAsMailingAddress = true;
            }
        }

        if (this.usePropertyAddressAsMailingAddress) {
            this.newPolicy.holder.address = JSON.parse(JSON.stringify(this.newPolicy.property));
            this.newPolicy.holder.address.id = UtilitiesService.newid();
            this.newPolicy.holder.addressId = this.newPolicy.holder.address.id;
            this.newPolicy.holderAddress = this.newPolicy.holder.address;
        } else {
            this.newPolicy.holder.address = this.mailingAddress;
            this.newPolicy.holder.addressId = this.mailingAddress.id;
            this.newPolicy.holderAddress = this.newPolicy.holder.address;
        }

        this.newPolicy.holderPhoneNumbers = this.newPolicy.holder.phoneNumbers;
        this.newPolicy.options = this.selectedOptions;
        if (this.selectedMarketingSource !== 'none') {
            this.newPolicy.policy.marketingSourceId = this.selectedMarketingSource;
        } else {
            delete this.newPolicy.policy.marketingSourceId;
        }
        this.newPolicy.coverageType = 'Homeowner';
        this.newPolicy.selectedCard = this.selectedCard;
        if (this.newCoCustomer && this.newCoCustomer.name) {
            this.newPolicy.policy.coHolderId = this.newCoCustomer.id;
        }

        if (this.selectedCard) {
            this.newPolicy.holder.stripeId = this.selectedCard.customer;
        }
        if (this.promotionCode) {
            this.newPolicy.promotionCode = this.promotionCode.code;
        }
        if (this.loggedInUser && this.loggedInUser.type === 'Agent') {
            this.newPolicy.property.propertyManagerId = this.loggedInUser.id;
        }

        this.newPolicy.policy.createdSource = 'CUSTOMER_PORTAL';

        this.newPolicy.policy.holderId = this.newCustomer.id;
        if (this.selectedCoverageType === 'RealEstate') {

            this.newPolicy.coverageType = 'RealEstate';
            if (this.isSellersCoverage) {
                this.newPolicy.coverageType = 'Sellers';
            }
            if (this.newPolicy.closingOfficer) {
                this.newPolicy.policy.closingOfficerId = this.newPolicy.closingOfficer.id;
                this.newPolicy.policy.closingCompanyId = this.newPolicy.closingOfficer.parentId;
            } else {
                this.newPolicy.policy.closingOfficerId = null;
                this.newPolicy.policy.closingCompanyId = null;
            }
            if (this.newPolicy.buyersAgent) {
                this.newPolicy.policy.agentId = this.newPolicy.buyersAgent.id;
            } else {
                this.newPolicy.policy.agentId = null;
            }
            if (this.newPolicy.sellersAgent) {
                this.newPolicy.policy.sellerAgentId = this.newPolicy.sellersAgent.id;
            } else {
                this.newPolicy.policy.sellerAgentId = null;
            }
            if (this.amountToSpend && this.amountToSpend >= this.totalPlanPrice) {
                this.newPolicy.policy.titleInvoiceAmount = this.amountToSpend;
            }
        } else {
            this.newPolicy.closingOfficer = null;
            this.newPolicy.buyersAgent = null;
            this.newPolicy.sellersAgent = null;
            this.newPolicy.policy.estimatedClosingDate = null;
            this.newPolicy.policy.escrowNumber = null;
        }
        if (!this.newPolicy.holderPhoneNumbers) {
            this.newPolicy.holderPhoneNumbers = [];
        }
        if (this.newPolicy.holderPhoneNumbers && this.newPolicy.holderPhoneNumbers.length > 0) {
            if (!this.newPolicy.holderPhoneNumbers[0].number) {
                this.newPolicy.holderPhoneNumbers = [];
            }
        }
        for (const phoneNumber of this.newPolicy.holder.phoneNumbers) {
            if (!phoneNumber.id || phoneNumber.id === "") {
                phoneNumber.id = UtilitiesService.newid();
            }
            phoneNumber.entityId = this.newPolicy.holder.id;
        }
        if (this.newPolicy.additionalEmailAddresses && this.newPolicy.additionalEmailAddresses.length > 0 && this.newPolicy.additionalEmailAddresses.filter(i => i.email).length > 0) {
            this.newPolicy.policy.additionalTitleInvoiceEmails = this.newPolicy.additionalEmailAddresses.filter(i => i.email).map(i => i.email).join(',');

        }

        if (this.theReferringAgent) {
            this.newPolicy.policy.agentId = this.theReferringAgent.id;
            this.newPolicy.policy.initiatorType = 'Buyer';
        }

        if (this.theReferringAe) {
            this.newPolicy.policy.accountExecutiveId = this.selectedReferringAe;
            this.newPolicy.policy.initiatorType = 'AccountExecutive';
        }

        if (this.selectedPropertyManagerInfo) {
            this.newPolicy.property.propertyManagerId = this.selectedPropertyManagerInfo.id;
        }
        if (this.coverageType === CoverageType.Sellers) {
            this.newPolicy.policy.initiatorType = 'Seller';
        }

        const newPolicy = await this.customerRepository.saveNewPolicy(this.newPolicy);

        if (!newPolicy) {
            this.missionService.showWarningToast('Sorry, it looks like something went wrong');
            return;
        }
        if (newPolicy.policyNumber === -1) {
            this.dialog.alert('Payment Failed', 'Sorry, it appears as if the credit card payment didn\'t go through.');
            this.submitting = false;
            return;
        }
        this.submitting = false;
        this.missionService.showSuccessToast('Your coverage has been created.');
        this.selectedIndex = this.confirmationStepNumber;
        this.createdPolicy = newPolicy;
        this.recordStepProgress('confirmationComplete', {
            price: this.totalPlanPrice,
            monthly: this.newPolicy.policy.isMonthly,
            basePrice: this.planBasePrice,
            optionsPrice: this.optionsPrice,
            adjustmentAmount: this.adjustmentAmount,
            id: this.newPolicy.policy.id,
            planName: this.selectedPlan?.name,
            currencyCode: 'USD'
        });

    }

    timeouts: any = {

    };
    trackFormEntry(field: string) {
        clearTimeout(this.timeouts[field]);
        this.timeouts[field] = setTimeout(() => this.recordStepProgress(`data-entered: ${field}`), 1000);
    }

    addAdditionalFieldIfNeeded(type: string, currentEmail = null) {
        if (currentEmail && this.newPolicy.additionalEmailAddresses) {
            const blankEmails = this.newPolicy.additionalEmailAddresses.filter(i => i.email === '' && i !== currentEmail && i.type === type);
            for (const emailToDelete of blankEmails) {
                this.newPolicy.additionalEmailAddresses.splice(this.newPolicy.additionalEmailAddresses.indexOf(emailToDelete), 1);
            }
        }
        if (!this.newPolicy.additionalEmailAddresses) {
            this.newPolicy.additionalEmailAddresses = [];
        }
        if (!this.newPolicy.additionalEmailAddresses.find(i => i.email === '' && i.type === type)) {
            this.newPolicy.additionalEmailAddresses.push({ type, email: '' });
        }
    }

    get closingAdditionalEmails() {
        if (!this.newPolicy.additionalEmailAddresses) {
            return null;
        }

        return this.newPolicy.additionalEmailAddresses.filter(i => i.type === 'Closing');
    }

    get agentAdditionalEmails() {
        if (!this.newPolicy.additionalEmailAddresses) {
            return null;
        }

        return this.newPolicy.additionalEmailAddresses.filter(i => i.type === 'Agent');
    }

    get sellersAdditionalEmails() {
        if (!this.newPolicy.additionalEmailAddresses) {
            return null;
        }

        return this.newPolicy.additionalEmailAddresses.filter(i => i.type === 'Sellers');
    }



    get customerTypeName() {
        if (this.selectedCoverageType === 'Homeowner') {
            return 'Customer';
        }
        if (this.selectedCoverageType === 'RealEstate' && !this.isSellersCoverage) {
            return 'Buyer';
        }
        if (this.selectedCoverageType === 'RealEstate' && this.isSellersCoverage) {
            return 'Seller';
        }

        return 'Customer';
    }


    displayFn(user?: any): string | undefined {
        return user ? user.name : undefined;
    }


    selected(event: MatAutocompleteSelectedEvent): void {
        this.selectedAgentInfo = event.option.value;
        // this.selectedItemChange.emit(this.selectedItem);
        this.isNewAgent = false;
    }

    selectedAgentCompany: any;
    isNewAgentCompany = false;

    agentCompanySelected(event: MatAutocompleteSelectedEvent) {
        this.selectedAgentCompany = event.option.value;
        this.isNewAgentCompany = false;
    }

    sellerCompanySelected(event: MatAutocompleteSelectedEvent) {
        this.selectedSellerCompany = event.option.value;
        this.isNewSellerCompany = false;
    }


    closingOfficerSelected(event: MatAutocompleteSelectedEvent): void {
        this.selectedTitleInfo = event.option.value;

        this.isNewTitle = false;
    }

    closingOfficerCompanySelected(event: MatAutocompleteSelectedEvent) {
        this.selectedTitleCompanyInfo = event.option.value;
        this.isNewTitleCompany = false;
    }


    propertyManagerSelected(event: MatAutocompleteSelectedEvent) {
        this.selectedPropertyManagerInfo = event.option.value;
    }
    sellerSelected(event: MatAutocompleteSelectedEvent): void {
        this.selectedSellerInfo = event.option.value;
        // this.selectedItemChange.emit(this.selectedItem);

        this.isNewSeller = false;
    }
    reset() {
        this.myControl.setValue(null);
    }



    async getData(entityType: string) {


        if (entityType === 'Agent') {
            if (!this.agentIdSetFromQuery) {
                this.isNewAgent = true;
            } else {
                this.agentIdSetFromQuery = false;
                return;
            }

        } else if (entityType === 'ClosingOfficer') {
            this.isNewTitle = true;
        } else if (entityType === 'Seller') {
            this.isNewSeller = true;
        } else if (entityType === 'Title_Company') {
            this.isNewTitleCompany = true;
        } else if (entityType === 'Branch') {
            this.isNewAgentCompany = true;
        } else if (entityType === 'SellerBranch') {
            this.isNewSellerCompany = true;
        }
        this.searching = true;
        // this.typeAheadApi.getSearchResults(this.apiEndpoint, this.apiEndpointParameter, this.searchForText).subscribe(results => {
        //     this.allSearchItems = results;
        //     this.searching = false;
        // });

        let calculatedEntityType = entityType;
        if (entityType === 'Seller') {
            calculatedEntityType = 'Agent';
        }
        if (entityType === 'SellerBranch') {
            calculatedEntityType = 'Branch';
        }
        if (entityType === "PropertyManager") {
            calculatedEntityType = 'Agent';
        }

        this.dataAccessService.searchForTypeSubtypeAndField('Entity', calculatedEntityType, this.searchForText, 'Name').then(searchResults => {
            this.searching = false;
            if (searchResults && searchResults.length === 0) {
                searchResults.push({ name: 'No Results Found' } as any);
            }
            if (entityType === 'ClosingOfficer') {
                this.allTitleSearchItems = searchResults;

            } else if (entityType === 'PropertyManager') {
                this.allPropertyManagerSearchItems = searchResults;
            } else if (entityType === 'Seller') {
                this.allSellerSearchItems = searchResults;
            } else if (entityType === 'Title_Company') {
                this.allTitleCompanySearchItems = searchResults;
            } else if (entityType === 'Branch') {
                this.allAgentCompanySearchItems = searchResults;
            } else if (entityType === 'SellerBranch') {
                this.allSellerCompanySearchItems = searchResults;
            } else {
                this.allSearchItems = searchResults;
            }
        });
    }

}
