import { Address } from "./address.model";
import { Entity } from "./entity.model";
import { MaintenanceServiceOfferingSummaryWithPostalCode } from "./maintenance-service-offering-summary-with-postal-code.model";
import { StripeCard } from "./stripe-card.model";

export class MaintenanceServiceOrderArgs {
 
    property: Address;
    orderedServices: MaintenanceServiceOfferingSummaryWithPostalCode[];
    customer: Entity;
    fromPortal = false;

    password: string;
    card: StripeCard;
    promotionId: string;
    source?: string;
    marketingSourceId?: string;
}