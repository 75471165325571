<div id="otherContactsPane" topmargin20>
    <div aflex style="max-width: 650px;">
        <div nogrow>
            <i class="material-icons" style="font-size: 36px">contact_mail</i>
        </div>
        <div>

            <mat-form-field appearance="outline">
                <mat-label>
                    <mat-icon>face</mat-icon> Contact Name
                </mat-label>
                <input matInput placeholder="Contact Name" class="soft" [(ngModel)]="contact.name"
                    (ngModelChange)="setIsValid()">
                <mat-hint align="end">

                    the contact Name</mat-hint>
            </mat-form-field>
            <div flex topmargin20>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>
                            <mat-icon>phone</mat-icon> Contact Phone / SMS
                        </mat-label>
                        <input inputmode="tel" matInput  [(ngModel)]="contact.homeNumber"
                            (ngModelChange)="setIsValid()" mask="(000) 000-0000">
                        <mat-hint align="end">(123) 456-7890</mat-hint>
                    </mat-form-field>
                </div>
                <div>
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>
                            <mat-icon>phone_iphone</mat-icon> SMS Contact
                        </mat-label>
                        <input inputmode="tel" matInput [(ngModel)]="contact.mobileNumber"
                            (ngModelChange)="setIsValid()" mask="(000) 000-0000">
                        <mat-hint align="end">
                            <mat-icon>phone_iphone</mat-icon> mobile: (123) 456-7890
                        </mat-hint>
                    </mat-form-field> -->
                </div>
            </div>


            <mat-form-field appearance="outline" topmargin20>
                <mat-label>
                    <mat-icon>mail</mat-icon> Email
                </mat-label>
                <input matInput inputmode="email" placeholder="Email" [(ngModel)]="contact.email"
                    (ngModelChange)="setIsValid()">
                <mat-hint align="end">email&#64;domain.com</mat-hint>
            </mat-form-field>

        </div>
    </div>
</div>

<ng-container *ngIf="contactList && contactList.length > 1">
    <h2 class="sub-heading" topmargin30>Available Contacts</h2>
    <div #otherContactsPanel id="otherContactsPanel" [class.collapsed]="otherContactsVisible">
        <button class="toggle-button" [class.selected]="isContactSelected(contact)" mat-button
            *ngFor="let contact of contactList" marginbottom10 (click)="selectContact(contact)">
            <mat-icon *ngIf="!isContactSelected(contact)">contact_mail</mat-icon>
            <mat-icon *ngIf="isContactSelected(contact)">check</mat-icon>
            Use {{contact.name}}

        </button>
    </div>
</ng-container>