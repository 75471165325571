import { keyColumn, column, dataSource, readOnlyColumn } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrder {
    @keyColumn() id: string;
    @readOnlyColumn() number: bigint;
    @column() type: string;
    @column() sentDate: Date;
    @column() contractorId: string;
    @column() itemId: string;
    @column() deletedDate?: Date;
    @column() dispatchExportDate?: Date;
    @column() defaultContractorId: string;
    @column() overrideReasonId: string;
    @column() qandA: string;
    @column() claimId: string;
    @column() contactName: string;
    @column() contactPhone: string;
    @column() contactEmail: string;
    @column() contactMobile: string;
    @column() additionalNotes: string;
    @column() sendViaEmail: boolean;
    @column() sendViaText: boolean;
    @column() sendEmail: string;
    @column() sendText: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() authorizationLimit: number;
    @column() dateClosed: Date;
    @column() scheduledDate: Date;
    @column() scheduledStartWindow: string;
    @column() scheduledEndWindow: string;
    @column() authorizationForm: string;
    @column() dateCompleted: Date;
    @column() resolution: string;
    @column() dispatchedDate: Date;
    @column() whiteGlove: boolean;
    @column() sendCustomerDispatchNotification: boolean;
    @column() technicianId: string;
    @column() customerAccessToken: string;
    @column() technicianLatitude: number;
    @column() technicianLongitude: number;
    @column() cancelledDate: Date;
    @column() cancelledSentDate: Date;
    // @column() version: timestamp;
    @column() technicianContactInfo: string;
    @column() totalCashOuts: number;
    @column() totalContractorInvoices: number;
    @column() totalPurchaseOrders: number;
    @column() statusDate: Date;
    @column() firstVisitResolved: boolean;
    @column() workOrderStatusId: string;
    @column() authorizationApprovedDate: Date;
    @column() expensedAuthorizationAmount: number;
    @column() authorizationExportDate: Date;
    @column() quickBooksJournalId: string;
    @column() onMyWayNotificationSentDate: Date;
    @column() assignedToUserId: string;
    @column() authorizationDeniedDate: Date;
    @column() authorizationDeniedForm: string;
    @column() dispatchStatusToSend: string;
    @column() internalNote: string;
    @column() laborTotal: number;
    @column() dispatchJobId: string;
    @column() applicationSource: string;
    @column() transferReasonId: string;
    @column() transferredFromWorkOrderId: string;
    @column() alternateTimeSlots: string;
    @column() cashOutOfferAmount: number;
    @column() tripFeeAllowance: number;
    @column() contactCustomerViaText: boolean;
    @column() userDefined: string;
    @column() preferredContactMethod: string;
}