
import { AuthorizationLine } from "@cogent/shared/models/authorizations/authorization-line.model";
import { Question } from "@cogent/shared/models/common/question-parser.model";

import { AuthorizationReviewTrigger, CostLine, PolicyServiceOffering, WorkOrderLineSummary } from "@upkeeplabs/models/cogent";

export class WorkOrderLineSummaryClient extends WorkOrderLineSummary {
    calculateAuthorizationLimit = false;
    isService: boolean;
    // Client-side only
    planDetails: any;
    deleting = false;
    parsedQAndA: any;
    parsedAuthoQAndA: any;
    item: PolicyServiceOffering;
    selected = false;
    originalId: string;
    parent: any;
    lines: AuthorizationLine[];
    questionsComplete = false;
    questionStack: Question[] = [];
    limitPerOccurrence: number;
    authorizations?: Authorization[];
    url: string;

    get authComplete() {
        if (!this.authorizations) {
            return false;
        }

        return this.authorizations.filter(i => i.completeDate).length > 0;
    }

    get questionsAndAnswers(): any {
        if (!this.qandA) {
            return null;
        }
        if (!this.parsedQAndA) {
            this.parsedQAndA = JSON.parse(this.qandA);

        }
        return this.parsedQAndA;
    }
    set questionsAndAnswers(value: any) {
        this.parsedAuthoQAndA = value;
    }

    get authoQuestionsAndAnswers(): any {
        if (!this.authorizationForm) {
            return null;
        }
        if (!this.parsedAuthoQAndA) {
            this.parsedAuthoQAndA = JSON.parse(this.authorizationForm);

        }
        return this.parsedAuthoQAndA;
    }

    get displayAutho(): number {
        if (!this.authorizationAmount) {
            return this.authorizationLimit;
        }

        return this.authorizationAmount;
    }

    contractLanguage: any;


}

export class Authorization {
    description: string;
    authorizedAmount: number;
    outOfPocketAmount: number;
    partsCost: number;
    completeDate?: Date;
    warnings?: AuthorizationReviewTrigger[];
    questionsAndAnswers: QuestionAndAnswer[];
    additionalNotes: string;
    customerApprovesOutOfPockets?: boolean;
    id: string;
    costLines: CostLine[];

    get authorizedAmountFinal() {
        if (!this.warnings) {
            return this.authorizedAmount;
        }
        const warningCount = this.warnings.filter(i => !i.override).length;
        if (warningCount > 0) {
            return 0;
        }


        return this.authorizedAmount;
    }

    get outOfPocketAmountFinal() {
        if (!this.warnings) {
            return this.outOfPocketAmount;
        }
        const warningCount = this.warnings.filter(i => !i.override).length;
        if (warningCount > 0) {
            return (this.outOfPocketAmount ?? 0) + (this.authorizedAmount ?? 0);
        }

        return this.outOfPocketAmount ?? 0;
    }
}

export class QuestionAndAnswer {
    question: string;
    answer: any;
    type?: string;
}

