import { Component, ElementRef, ViewChild, Inject } from '@angular/core';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { PropertyMetaModel } from '@cogent/shared/models/common/property-meta.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Address, Dictionary, PromotionCodeSummary, WebQuote } from '@upkeeplabs/models/cogent';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { SingleAddressEntryComponent } from '@cogent/client/shared/components/misc/single-address-entry/single-address-entry.component';
import { CoverageType, PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { GoogleTagManagerService } from '@cogent/client/shared/services/google-tag-manager.service';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
} from '@angular/material/dialog';
import { QuotePromoComponent } from "@cogent/client/shared/components/sales/quote-promo/quote-promo.component";
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PlanOfferingsModule } from '@cogent/client/shared/components/plans-and-coverage/plan-offerings1/plan-offerings.module';
import { CustomerFooterComponent } from "@cogent/client/shared/components/customer-portal/customer-footer/customer-footer.component";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskDirective } from 'ngx-mask';


declare var fbq;

@Component({
    selector: 'app-new-quote',
    templateUrl: './quote2.component.html',
    styleUrl: './quote2.component.scss',
    standalone: true,
    imports: [
        MatTabsModule,
        SingleAddressEntryComponent,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,
        MatExpansionModule,
        MatIconModule,
        MatProgressSpinnerModule,
        PlanOfferingsModule,
        CustomerFooterComponent,
        CommonModule,
        MatButtonModule,
        NgxMaskDirective
    ]
})
export class Quote2Component {
    //newPolicy: NewPolicyModel = new NewPolicyModel();
    propertyMeta: PropertyMetaModel;
    settingValues: boolean;
    plans: PlanClient[];
    selectedPlan: PlanClient;
    @ViewChild('address-entry') addressEl: ElementRef;
    @ViewChild('nameEl') nameEl: ElementRef;
    @ViewChild('emailEl') emailEl: ElementRef;
    @ViewChild('phoneEL') phoneEL: ElementRef;
    selectedIndex = 0;
    addressComlete = false;
    quote: WebQuote = new WebQuote();
    saving = false;
    isMonthly = true;
    promotionCode: PromotionCodeSummary;
    @ViewChild('addressEntry') addressEntry: SingleAddressEntryComponent;
    quoteStep = 'Address';
    secondDetailVisible = false;
    faqVisible = false;
    id = UtilitiesService.newid();
    loading = false;
    version: string;
    quoteConfig: Dictionary;
    layout: 1 | 2 = 1
    isLegacy = true;
    showRealEstateOrHomeowner = false;

    constructor(private customerRepository: CustomerRepositoryService,
        private missionService: MissionService,
        private dialog: MatDialog,
        private router: Router,
        private googleTag: GoogleTagManagerService,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogsService,
        private planApi: PlanApiService,
    ) { }

    ngOnInit(): void {
        if (document.getElementById('appHeader')) {
            document.getElementById('appHeader').style.display = 'none';
            document.getElementById('appHeader').style.height = '0px';
        }
        if (document.getElementById('app-content')) {
            document.getElementById('app-content').style.paddingTop = '0px';
            document.getElementById('app-content').style.paddingBottom = '0px';
        }


        this.quote.address = new Address();
        this.quote.address.id = UtilitiesService.newid();
        this.quote.id = UtilitiesService.newid();
        this.quote.queryParameters = JSON.stringify(UtilitiesService.getQueryParams());

        this.activatedRoute.params.subscribe(params => {
            if (params.version) {
                this.version = params.version;
            }
            if (params.source) {
                this.quote.source = params.source;
            }
        });
        this.activatedRoute.queryParams.subscribe(queryParams => {
            if (queryParams.layout) {
                this.layout = queryParams.layout
            }
        })
        this.customerRepository.getLandingPageConfig().then(i => {
            this.quoteConfig = i
        });
        this.updateSelection().then(r => r)
    }

    getFirstAddress() {
        this.addressEntry.getFirstOption();
    }

    get bgImage() {
        if (this.isGiveaway) {
            return `url(${this.quoteConfig?.jsonValue?.giveAwayImageUrl})`;
        }
        return `url(${this.quoteConfig?.jsonValue?.imageUrl})`;
    }

    get bgImage2() {
        if (this.isGiveaway) {
            return `url(${this.quoteConfig?.jsonValue?.giveAwayImageUrl2})`;
        }
        return `url(${this.quoteConfig?.jsonValue?.imageUrl2})`;
    }

    get isGiveaway() {
        return this.version === 'give-away';
    }

    get title() {
        switch (this.version) {
            case 's1':
            case 's2':
                return 'Simplifying  Homeownership';
            case 'w1':
                return 'How would you like to never worry about home repairs again?';
            case 'w2':
            case 'w3':
                return 'Never worry about home repairs again';
            case 't1':
                return 'WE SELL TIME.';
            case 'c1':
                return 'Let us take care of your <span class="orange-type">home repairs.</span>';
            case 'd1':
                return 'We do <span class="orange-type">home repairs.</span>';


        }

        return 'Simplifying Homeownership';
    }
    pixelTrackCustom(value, additionalParams?) {
        try {
            if (fbq) {
                fbq("trackCustom", value, additionalParams);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async addressCompleteChange(address: Address) {
        this.quote.address = address;
        this.propertyMeta = await this.customerRepository.getPropertyMeta(this.quote.address.address1, this.quote.address.postalCode);
        this.settingValues = true;
        this.quote.address.squareFeet = this.propertyMeta.sqft;
        this.quote.address.dwellingType = this.propertyMeta.useCode;
        this.quote.address.latitude = this.propertyMeta.lat;
        this.quote.address.longitude = this.propertyMeta.lon;
        this.quote.address.yearBuilt = this.propertyMeta.yearBuilt;
        this.settingValues = false;
        // await this.updateSelection();
        // this.pixelTrackCustom("GetQuote");
        // this.googleTag.sendData('quoteStep1');
        // if (this.plans.length > 0) {
        //     this.pixelTrackCustom("InCoverageArea");
        //     this.quoteStep = 'Contact';
        //     document.getElementById('customer-name').focus();
        // } else {
        //     this.dialogService.alert('none', 'Sorry, we don\'t have coverage in your area.');
        //     this.pixelTrackCustom("OutOfCoverageArea");
        //     this.addressEntry.reset();
        // }
    }

    getAdjustmentAmount(plan: PlanClient) {
        if (!this.promotionCode) {
            return 0;
        }

        if (this.promotionCode.priceAdjustmentType === 'Flat') {
            return this.isMonthly ? this.promotionCode.priceAdjustment / plan.monthsOfCoverage : this.promotionCode.priceAdjustment;
        } else if (this.promotionCode.priceAdjustmentType === "Percent") {
            return parseFloat((this.promotionCode.priceAdjustment * (this.isMonthly ? plan.basePriceRecurring : plan.basePrice)).toFixed(2));
        }
    }


    scrollToHomescription() {
        document.getElementById(this.id).scrollTo(0, window.innerHeight);
    }

    scrollToFAQs() {
        document.getElementById(this.id).scrollTo(0, window.innerHeight * 2);
    }

    scrollToStartPosition() {
        document.getElementById(this.id).scrollTo(0, 0);
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }


    get isHome() {
        return document.getElementById(this.id).scrollTop < window.innerHeight;
    }

    setScrollButtons() {
        const position = document.getElementById(this.id).scrollTop;
        if (position < window.innerHeight) {
            this.secondDetailVisible = false;
            this.faqVisible = false;
        } else if (position < window.innerHeight * 2) {
            this.secondDetailVisible = true;
            this.faqVisible = false;
        } else {
            this.secondDetailVisible = false;
            this.faqVisible = true;
        }
    }

    scrollToSecondStep() {
        const classElement = document.getElementsByClassName('quote-second');
        if (classElement.length > 0) {
            classElement[0].scrollIntoView({ behavior: 'smooth' });
        }
    }

    async updateSelection() {
        if (this.settingValues) {
            return;
        }

        const age = new Date().getFullYear() - this.quote.address.yearBuilt;

        this.loading = true

        const coverageType = this.quote.coverageType === 'RealEstate' ? CoverageType.RealEstate : CoverageType.Homeowner;
        this.plans = await this.planApi.getOfferedPlans(coverageType,
            this.quote.address.dwellingType as any,
            this.quote.address.postalCode,
            this.quote.address.squareFeet,
            age,
            null,
            this.quote.address.latitude,
            this.quote.address.longitude,
            null,
            false);
        this.plans = this.plans.filter(i => i.showOnWeb);

        this.loading = false

        if (this.quote.promotionCode) {
            const code = await this.customerRepository.getPromotionCode(this.quote.promotionCode, CoverageType.Homeowner);
            this.promotionCode = code;
            if (!code) {
                this.dialogService.alert('Not Found', 'Sorry, that promotion code could not be found');
            } else {
                for (const plan of this.plans) {
                    plan.promotionAmount = this.getAdjustmentAmount(plan);
                }
            }
        }
    }


    getQuoteNext() {
        if (!this.quote.address.isFilledOut) {
            document.getElementById('address-entry').focus()
            this.missionService.showWarningToast('Please enter your address');
            return;
        }

        if (!this.quote.name) {
            this.nameEl.nativeElement.focus();
            this.missionService.showWarningToast('Please enter your name');
            return;
        }

        if (!this.quote.email || !UtilitiesService.validateEmail(this.quote.email)) {
            this.emailEl.nativeElement.focus();
            this.missionService.showWarningToast('Please enter your email');
            return;
        }

        if ((!this.quote.phone || !UtilitiesService.validatePhoneNumber(this.quote.phone)) && !this.isGiveaway) {
            this.phoneEL.nativeElement.focus();
            this.missionService.showWarningToast('Please enter you phone number');
            return;
        }
        this.showRealEstateOrHomeowner = true;
    }

    
    setCoverageType(coverageType: string) {
        this.quote.coverageType  = coverageType;
        this.showRealEstateOrHomeowner = false;
        
        this.save();
    }


    async save() {

        this.saving = true

        await this.updateSelection();
        this.pixelTrackCustom("GetQuote");
        this.googleTag.sendData('quoteStep1');
        if (this.plans.length > 0) {
            this.pixelTrackCustom("InCoverageArea");
            this.quoteStep = 'Contact';
            document.getElementById('customer-name').focus();
        } else {
            this.dialogService.alert('none', 'Sorry, we don\'t have coverage in your area.');
            this.pixelTrackCustom("OutOfCoverageArea");
            this.addressEntry.reset();
            this.saving = false;
            return;
        }
        this.quote.address.id = UtilitiesService.newid();
        this.quote.address.addressStandardized = false;
        this.quote.address.addressStandardizeFailed = false;
        this.quote.source = this.isGiveaway ? 'Giveaway' : '';
        if (!this.quote.address.isFilledOut) {
            document.getElementById('address-entry').focus()
            this.missionService.showWarningToast('Please enter your address');
            return;
        }

        if (!this.quote.coverageType) {
            this.missionService.showWarningToast('Please indicate how long you\'ve been in the home');
            return;
        }

        if (!this.quote.name) {
            this.nameEl.nativeElement.focus();
            this.missionService.showWarningToast('Please enter your name');
            return;
        }

        if (!this.quote.email || !UtilitiesService.validateEmail(this.quote.email)) {
            this.emailEl.nativeElement.focus();
            this.missionService.showWarningToast('Please enter your email');
            return;
        }

        if ((!this.quote.phone || !UtilitiesService.validatePhoneNumber(this.quote.phone)) && !this.isGiveaway) {
            this.phoneEL.nativeElement.focus();
            this.missionService.showWarningToast('Please enter you phone number');
            return;
        }
        this.pixelTrackCustom('SaveQuote');
        this.googleTag.sendData('quoteStep2');
        this.saving = true;

        this.quote.quoteDetails = JSON.stringify(this.plans);
        if (this.layout == 1) this.selectedIndex = 1;
        await this.customerRepository.saveWebQuote(this.quote);

        if (this.layout == 2) {
            this.selectedIndex = 2
        }

        if (this.quoteConfig.jsonValue.showPromoPopup && !this.isGiveaway) {
            this.showPromoPopup()
        } else if (this.quoteConfig.jsonValue.showGiveAwayPromoPopup && this.isGiveaway) {
            this.showPromoPopup();
        }

        this.saving = false;
    }

    currentCustomerRedirect() {
        this.router.navigateByUrl(`/`);
    }

    realEstateRedirect() {
        this.router.navigateByUrl(`/new-order`);
    }

    moveToIndex(index) {
        this.selectedIndex = index
    }

    showPromoPopup() {
        setTimeout(() => {
            let promptInfo = (this.isGiveaway ? this.quoteConfig.jsonValue.giveAwayPromoPopupInfo : this.quoteConfig.jsonValue?.promoPopupInfo)
                ?? {
                header: 'LIMITED TIME OFFER',
                description: 'Get $100 off at checkout using code: <i>MAYSAVINGS100!</i>',
                callToActionText: 'Sign Up Now!',
                altActionText: 'No, I want to pay full price',
                disclaimer: 'valid for non-real estate transactions only through May 31, 2024',
                promoCode: ''
            };
            this.dialog.open(QuotePromoComponent, { data: promptInfo }).afterClosed().subscribe(results => {
            })
        }, 3000)
    }
}


