import { NgModule } from '@angular/core';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthCallbackComponent, ImpersonateComponent } from './auth-callback';
import { LogOutComponent } from './log-out';

@NgModule({
    declarations: [
        LogOutComponent
    ],
    imports: [AuthCallbackComponent,
        AuthenticationRoutingModule,
        HttpClientModule,
        ImpersonateComponent
    ],
    exports: []
})
export class AuthenticationModule { }
