<div aflex>
    <div>
        <button class="select-range-button" mat-button block (click)="showDateRange = !showDateRange">
            <div aflex>
                <div>
                    <div class="data-label">Start Date</div>
                    <div class="date">{{startDate | date: 'shortDate'}}</div>
                    <div class="data-label" topmargin10>End Date</div>
                    <div class="date">{{endDate | date: 'shortDate'}}</div>
                </div>
                <div nogrow>
                    <mat-icon class="chev-right">chevron_right</mat-icon>
                </div>
            </div>
        </button>
    </div>
    <div nogrow style="margin-left: 0;">
        <div id="date-range-picker" [class.bottom]="location === 'bottom'" class="slide-in-up" *ngIf="showDateRange">
            <div aflex>
                <div>
                    <h2 class="sub-heading" bottommargin10>Fixed Range</h2>
                    <div style="padding-left: 20px;">
                        <app-date-range-selection [(selectedRange)]="selectedRange" [showFutureDates]="showFutureDates"
                            (selectedRangeChange)="selectedRangeChange($event)">
                        </app-date-range-selection>
                    </div>

                    <div style="max-width: 450px;" topmargin30>
                        <h2 class="sub-heading" bottommargin10>Custom Range</h2>
                        <div aflex>
                            <div>
                                <div class="data-label">Start Date</div>
                                <app-date-picker [(date)]="startDate" (dateChange)="customStartDateChange($event)">
                                </app-date-picker>
                            </div>
                            <div>
                                <div class="data-label">End Date</div>
                                <app-date-picker [(date)]="endDate" (dateChange)="customEndDateChange($event)">
                                </app-date-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <div nogrow style="margin-left: 0">
                    <button mat-button (click)="showDateRange = false">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="backdrop" *ngIf="showDateRange" (click)="showDateRange = false;"></div>