import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { debounceTime } from 'rxjs/operators';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { SearchResultModel} from '@cogent/shared/models/common/search-result.model';

@Component({
    selector: 'app-search-chip-selection',
    templateUrl: './search-chip-selection.component.html',
    styleUrls: ['./search-chip-selection.component.css']
})
export class ContractorChipSelectionComponent implements OnInit {


    allContractors: SearchResultModel[] = [];

    @Input() selectedResults: SearchResultModel[] = [];
    @Input() searchType = 'Entity';
    @Input() searchSubType = 'Contractor';
    @Input() placeholder: string;
    @Input() additionalChoices: SearchResultModel[];
    @Input() singleSelect: boolean;
    @Input() showWorkOrderView: boolean;
    @Input() showPolicyView: boolean;
    @Input() panelZIndex: number;
    @Input() panelWidth: string;
    @Input() hideAdditionalInfo: boolean;

    @Input() showEntityView: boolean;

    @Output() selectedResultsChange: EventEmitter<SearchResultModel[]> = new EventEmitter();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    searching = false;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    contractorCtrl = new UntypedFormControl();
    searchFor: string;
    filteredContractors;
    @ViewChild('contractorInput') regionInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;


    constructor(private dataAccess: DataApiService) {
        this.filteredContractors = this.contractorCtrl.valueChanges.pipe(debounceTime(500))
            .subscribe(value => {
                this.searchFor = value;
                if (value && !value.id) {
                    this.getData();
                }
            });
    }

    ngOnInit() { }

    getData() {
        this.searching = true;
        this.dataAccess.searchForTypeAndSubtype(this.searchType, this.searchSubType, this.searchFor).then(searchResults => {
            if (searchResults && this.additionalChoices) {
                searchResults = searchResults.concat(this.additionalChoices.filter(i => i.name && i.name.toLowerCase().indexOf(this.searchFor.toLowerCase()) > -1));
            } else if (this.additionalChoices) {
                searchResults = this.additionalChoices;
            }
            
            this.allContractors = searchResults;
            if(this.panelZIndex) {
                setTimeout(()=> {
                    const overlays = document.getElementsByClassName('cdk-overlay-container');
                    if(overlays && overlays.length > 0) {
                        (overlays[0] as any).style.zIndex = this.panelZIndex;
                    }
                });
            }
            this.searching = false;
        });
    }


    add(event: MatChipInputEvent): void {

        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            // Reset the input value
            if (input) {
                input.value = '';
            }

            this.contractorCtrl.setValue(null);
        }
    }

    remove(contractor: SearchResultModel): void {
        const index = this.selectedResults.indexOf(contractor);

        if (index >= 0) {
            this.selectedResults.splice(index, 1);
        }

        this.selectedResultsChange.emit(this.selectedResults);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.selectedResults || this.singleSelect) {
            this.selectedResults = [];
        }
        
        this.selectedResults.push(event.option.value);
        this.allContractors = [];
        this.regionInput.nativeElement.value = '';
        this.contractorCtrl.setValue(null);

        this.selectedResultsChange.emit(this.selectedResults);
    }
}
