import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PolicyHistoryItem {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() statusDate: Date;
    @column() status: string;
    @column() url: string;
    @column() number: bigint;
    @column() type: string;
    @column() holderId: string;
    @column() recordingLocation: string;
    @column() note: string;
    @column() createdByName: string;
    @column() createdById: string;
    @column() pinned: boolean;
    @column() completionDate: Date;
    @column() parentNoteId: string;
    @column() subType: string;
}