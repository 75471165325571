import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClosingDocumentUploadModule } from '@cogent/client/shared/components/sales/closing-document-upload/closing-document-upload.module';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';

@Component({
    selector: 'app-document-upload-route',
    templateUrl: './document-upload-route.component.html',
    styleUrls: ['./document-upload-route.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        ClosingDocumentUploadModule,
    ]
})
export class DocumentUploadRouteComponent implements OnInit {

    hasSellersDisclosures: boolean;
    hasHomeInspection: boolean;
    policySummary: PolicySummary;
    constructor(
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params=> {
            if(params.id) {
                this.policySummary = new PolicySummary();
                this.policySummary.id = params.id;
            }
        });
    }

}
