
<ng-container *ngIf="!embedded">
    <nav class="navbar navbar-default navbar-fixed-top main-menu"
        *ngIf="(isHome || isPolicyView || isBilling) && !isLogin && !hideHeader" [class.menu-animation]="isScrolled"
        [class.menu-animation-1]="isScrolled" style="z-index: 2">
        <div class="container">
            <div aflex>
                <div>
                    <div class="navbar-header">
                        <button mat-button (click)="toggleSideNav()" id="nav-toggle-button"
                            style="vertical-align: top;margin-top: 5px; margin-left: 5px;">
                            <i class="material-icons">menu</i>
                        </button>
                        <a [routerLink]="homeUrl" *ngIf="isLoggedIn" class="navbar-brand">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/logos/elevate-logo-homescriptions.svg" title="Company Logo"
                                id="companyLogo">

                        </a>
                        <a [href]="homeUrl" *ngIf="!isLoggedIn" class="navbar-brand">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/logos/elevate-logo-homescriptions.svg" title="Company Logo"
                                id="companyLogo">

                        </a>
                    </div>
                </div>
                <div nogrow class="show-gt-sm">
                    <nav style="overflow: hidden">

                        <ul *ngIf="menuItems && menuItems.length > 0" class="nav navbar-nav navbar-right"
                            style="padding-right: 20px;margin: 0; padding-top: 10px;padding-bottom: 5px;border-radius: 0 0 5px 5px;"
                            [class.white-bg]="!isScrolled">
                            <li *ngFor="let menuItem of menuItems" [id]="menuItem.id" [class.active]="menuItem.active  || isScrolledIntoView(menuItem.documentFragment)
                || (menuItem.url && menuItem.url === currentRoute)">
                                <a class="page-scroll1" style="cursor: pointer" *ngIf="menuItem.action"
                                    (click)="menuItem.action()"><span>{{menuItem.text}}</span></a>
                                <a class="page-scroll1" *ngIf="menuItem.url && menuItem.url.indexOf('https://') === -1"
                                    [routerLink]="menuItem.url"><span>{{menuItem.text}}</span></a>
                                <a class="page-scroll1" *ngIf="menuItem.url && menuItem.url.indexOf('https://') > -1"
                                    [href]="menuItem.url"><span>{{menuItem.text}}</span></a>
                                <a class="page-scroll" style="cursor: pointer" *ngIf="menuItem.documentFragment"
                                    (click)="scrollToFragment(menuItem.documentFragment, $event)"><span>{{menuItem.text}}</span></a>

                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </nav>
    <div *ngIf="!isHome && !isLogin && !isPolicyView && !isBilling && !hideHeader">
        <div id="appHeader" [class.scrolled]="isScrolled">

            <button style="vertical-align: top;" mat-button (click)="toggleSideNav()" [class.is-white]="isWhite">
                <i class="material-icons">menu</i>
            </button>

            <a *ngIf="isLoggedIn" [routerLink]="homeUrl">
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/logos/elevate-logo-homescriptions.svg" id="companyLogo">
            </a>
            <a *ngIf="!isLoggedIn" [href]="homeUrl">
                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/logos/elevate-logo-homescriptions.svg" id="companyLogo">
            </a>

            <ul *ngIf="menuItems && menuItems.length > 0" class="app-menu-items">
                <li *ngFor="let menuItem of menuItems" class="menu-item menu-item-li" [class.active]="menuItem.active  || isScrolledIntoView(menuItem.documentFragment)
      || (menuItem.url && menuItem.url === currentRoute)">
                    <a class="page-scroll1" style="cursor: pointer" *ngIf="menuItem.action"
                        (click)="menuItem.action()"><span>{{menuItem.text}}</span></a>
                    <a class="page-scroll1"
                        *ngIf="menuItem.url && menuItem.url.indexOf('https://') === -1 && menuItem.url.indexOf('http://') === -1"
                        [routerLink]="menuItem.url"><span>{{menuItem.text}}</span>
                        <mat-icon *ngIf="menuItem.key" class="more-arrow">expand_more</mat-icon>
                    </a>
                    <a class="page-scroll1" *ngIf="menuItem.url && menuItem.url.indexOf('https://') > -1"
                        [href]="menuItem.url"><span>{{menuItem.text}}</span>
                        <mat-icon *ngIf="menuItem.key" class="more-arrow">expand_more</mat-icon>
                    </a>
                    <a class="page-scroll1" *ngIf="menuItem.url && menuItem.url.indexOf('http://') > -1"
                        [href]="menuItem.url"><span>{{menuItem.text}}</span>
                        <mat-icon *ngIf="menuItem.key" class="more-arrow">expand_more</mat-icon>
                    </a>
                    <a class="page-scroll" style="cursor: pointer" *ngIf="menuItem.documentFragment"
                        (click)="scrollToFragment(menuItem.documentFragment, $event)"><span>{{menuItem.text}}</span>
                        <mat-icon *ngIf="menuItem.key" class="more-arrow">expand_more</mat-icon>
                    </a>
                    <ul class="sub-menu" *ngIf="menuItem.key === 'partnerships'">
                        <li>
                            <a href="https://www.homescriptions.com/realtor/">
                                Real Estate Professionals
                            </a>
                        </li>
                        <li>
                            <a href="https://www.homescriptions.com/service-pros">
                                Service Pros
                            </a>
                        </li>
                    </ul>

                    <ul class="sub-menu" *ngIf="menuItem.key === 'login'">
                        <li>
                            <a href="https://customers.elevatehomescriptions.com">Homeowners</a>
                        </li>
                        <li>
                            <a href="https://real-estate.elevatehomescriptions.com">Real Estate Professionals</a>
                        </li>
                        <li>
                            <a href="https://service-pros.elevatehomescriptions.com">Service Pros</a>
                        </li>
                    </ul>

                </li>

            </ul>
        </div>
    </div>

    <div id="side-nav" *ngIf="sideNavOpen">
        <h1>
            <mat-icon>menu</mat-icon> Options
        </h1>
        <div style="display: flex; flex-direction: column; height: calc(100% - 20px)">
            <ul class="mobile-menu">
                <li class="menu-item-li" *ngFor="let menuItem of menuItems">
                    <a mat-button block (click)="doMenuItem(menuItem, $event)">
                        {{menuItem.text}}


                    </a>
                    <ul class="mobile-sub-menu" *ngIf="menuItem.key === 'partnerships'">
                        <li>
                            <a href="https://www.homescriptions.com/realtor/">
                                Real Estate Professionals
                            </a>
                        </li>
                        <li>
                            <a href="https://www.homescriptions.com/service-pros">
                                Service Pros
                            </a>
                        </li>
                    </ul>
                    <ul class="mobile-sub-menu" *ngIf="menuItem.key === 'login'">
                        <li>
                            <a href="https://customers.elevatehomescriptions.com">Homeowners</a>
                        </li>
                        <li>
                            <a href="https://real-estate.elevatehomescriptions.com">Real Estate Professionals</a>
                        </li>
                        <li>
                            <a href="https://service-pros.elevatehomescriptions.com">Service Pros</a>
                        </li>
                    </ul>
                </li>
            </ul>
            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/elevate-a-icon-white.svg" class="bg-elevate-a">
        </div>
    </div>

    <div id="back-drop" (click)="sideNavOpen = false" *ngIf="sideNavOpen"></div>
</ng-container>
<div id="app-content" [class.app-content-embedded]="embedded" [class.header-hidden]="hideHeader"
    [class.entering]="enteringAnimation">
    <router-outlet></router-outlet>
</div>


<app-connect-chat></app-connect-chat>
<!-- <app-paperless-prompt></app-paperless-prompt> -->

<div *ngIf="showFunctionQuestions" class="card" id="function-question-renderer-container" [class.is-ios]="isIOS">
    <button mat-button (click)="showFunctionQuestions = false; setDocumentOverflow();" class="close-detail-button">
        <mat-icon>close</mat-icon>
    </button>
    <div id="function-question-renderer-inner-container">
        <div nogrow id="question-renderer" style="padding-top: 20px;padding-bottom: 20px;padding-left: 0;height: 100%;padding-right: 0;">
            <!-- <div class="center">
                <mat-icon style="font-size: 60px;height: 60px; width: 60px;color: #ccc;"
                    *ngIf="currentArgs.iconClass">{{currentArgs.iconClass}}</mat-icon>
                <img style="max-height: 60px;" *ngIf="currentArgs.pictureUrl" [src]="currentArgs.pictureUrl">
                <h2 class="sub-heading" style="text-align: center;" *ngIf="currentArgs.name">{{currentArgs.name}}</h2>
            </div> -->
            <div *ngIf="currentRunner && currentRunner.showActivityIndicator"
                class="center activity-indicator white-spinner">
                <mat-spinner class=" thirty" style="display: inline-block;"></mat-spinner>
                <br *ngIf="currentRunner.activityMessage">
                {{currentRunner.activityMessage}}
            </div>
            <app-function-question-renderer [offset]="false" [height]="currentArgs.height"
                [iconClass]="currentArgs.iconClass"
                [description]="currentArgs.description"
                [name]="currentArgs.name"
                [fixedSize]="currentArgs.fixedSize" [working]="currentRunner.showActivityIndicator"
                [wizardStyle]="currentArgs.wizardStyle"
                #functionQuestionRendererComponent></app-function-question-renderer>
        </div>
    </div>
</div>

<div style="display: none;">
    <app-web-socket-handler></app-web-socket-handler>
</div>
