import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StripeApiService } from '@cogent/client/shared/services/api/stripe-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { ApiService } from '@cogent/client/api';
import { InvoiceSummary, StripeCard } from '@upkeeplabs/models/cogent';
import { AccountingApiService } from '@cogent/client/shared/services/api/accounting-api.service';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';

@Component({
    selector: 'app-pay-individual-invoice',
    templateUrl: './pay-individual-invoice.component.html',
    styleUrls: ['./pay-individual-invoice.component.css'],
    standalone: true,
    imports: [CommonModule, MatTabsModule, MatButtonModule, MatIconModule, DisplayTotalModule, PaymentMethodEntryModule, MatProgressSpinnerModule, CheckCircleAnimatedComponent, PaymentMethodEntryComponent]
})
export class PayIndividualInvoiceComponent implements OnInit {

    customerId = 'dkjdskkjds';
    selectedCard: StripeCard;
    invoice: InvoiceSummary;
    saving = false;
    addingNewCard = false;
    selectedIndex = 0;
    endpoint: string;
    invoicePaid = false;
    useThisCardNextTime = true;
    removeCardAfterProcessing = false;
    // paymentValid: boolean;

    constructor(private activatedRoute: ActivatedRoute,
        private accountingApi: AccountingApiService,
        private dialog: DialogsService,
        private api: ApiService,
        private stripeApi: StripeApiService) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.accountingApi.getInvoiceToPay(params.id).then(invoice => {
                    this.invoice = invoice;
                    if (invoice.amountDue <= 0) {
                        this.dialog.alert('Paid', 'It looks like this invoice is already paid. <br><br>Thank You.');
                        this.invoicePaid = true;
                        this.selectedIndex = 1;
                    }
                });
            }
        });
        this.api.getApiEndpointDotNet().then(endpoint => {
            this.endpoint = endpoint;
        });
    }

    get invoiceAmount() {
        if (!this.invoice) {
            return 0;
        }

        return this.invoice.amountDue;
    }

    get invoiceUrl() {
        if (!this.invoice) {
            return null;
        }
        return `${this.endpoint}invoice/${this.invoice.id}/html`;
    }

    paymentValid(vaoue) { }

    newCardCreated() {
        this.makePayment();
    }

    makePayment() {
        this.saving = true;
        const charge: any = {
            stripeCardId: this.selectedCard.id,
            amount: this.invoice.amountDue,
            statementDescriptor: this.invoice.description,
            policyId: this.invoice.policyId,
            description: this.invoice.description,
            onSuccess: null,
            invoicesToPay: [
                {
                    invoiceId: this.invoice.id,
                    entityId: this.invoice.holderId,
                    amount: this.invoice.amountDue,
                    invoiceNumber: this.invoice.invoiceNumber,
                    description: this.invoice.description,
                }
            ],
            updatePolicyStripeCardId: this.useThisCardNextTime,
            setEffectiveDateIfNull: true,
            removeCardAfterProcessing: !this.selectedCard.saveCardForLater,
        };

        let wasError = false;
        this.stripeApi.createCharge2(this.invoice.holderId, charge, errors => {
            this.dialog.alert('Not Accepted', 'Sorry, your payment method was not accepted.');
            this.saving = false;
            wasError = true;
            this.selectedIndex = 0;
        }).then(() => {
            // Complete
            this.saving = false;
            if (!wasError) {
                this.selectedIndex = 1;
            }
        });
    }

}
