<div class="container" [ngStyle]="{'min-height': data ? 'min-height: calc(100vh - 60px);' : 'fit-content()'}">

    <div aflex>
        <div>
            <h1 style="font-size: 32px;" class="page-title white">
                <span *ngIf="!data; else isData">Settings</span><ng-template #isData>Update Account Contact Information?</ng-template>
            </h1>

        </div>
        <div nogrow *ngIf="!data">
            <button mat-button mat-raised-button color="primary" [disabled]="saving" (click)="save()" large>
                <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                Save</button>
                <br>
                <br>
                <a href="/delete-account-info">Delete Account</a>
        </div>
        <div nogrow *ngIf="data">
            <button mat-button matDialogClose>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div topmargin30>

        <div flex>
            <div>
                <div topmargin20></div>
                <app-basic-entity-entry [outline]="true" [hideContactMethodsEntry]="true" [canEditName]="false" [hidePreferreds]="true" [hideTimezoneEntry]="true" [showFirstAndLastName]="false" [hideInactiveBox]="true" [nameIsSearchBox]="false"
                    [entity]="currentEntity" [showPhoneNumberEntry]="true" [hideAddressEntry]="!!data" [hideWebsiteEntry]="!!data" [showSinglePhoneNumberEntry]="!!data">
                </app-basic-entity-entry>
            </div>
            <div nogrow style="width: 250px" *ngIf="!data">
                <div class="data-label">Options</div>

                <button mat-button block style="text-align: left;" (click)="showChangePassword()">
                    <mat-icon>lock</mat-icon>
                    Change Password
                </button>
            </div>
        </div>

        <div nogrow *ngIf="data" class="update-btn-container">
            <button mat-button mat-raised-button color="primary" [disabled]="saving" (click)="save()" large>
                <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                Update</button>
        </div>



    </div>
</div>
