import { Routes, RouterModule } from '@angular/router';
//import { WorkOrderComponent } from './pages/homeowner-work-order-detail/work-order';
import { AuthGuardLocalService } from '@upkeeplabs/authentication';
import { WorkOrderSurveyComponent } from '@cogent/client/apps/homeowner/service/work-order-survey/work-order-survey';
import { HomeownersHomeComponent } from '@cogent/client/apps/homeowner/home/homeowners-home.component';
import { MakePaymentComponent } from '@cogent/client/apps/homeowner/accounting/make-payment/make-payment.component';
import { FindPolicyComponent } from '@cogent/client/apps/homeowner/service/find-policy/find-policy';
import { ChoosePropertyComponent } from '@cogent/client/apps/homeowner/service/choose-property/choose-property.component';
import { RenewComponent } from '@cogent/client/apps/homeowner/sales/renew/renew.component';
import { AutoRenewComponent } from '@cogent/client/apps/homeowner/sales/auto-renew/auto-renew.component';
import { OrderComponent } from '@cogent/client/apps/homeowner/sales/order/order.component';
import { SettingsComponent } from '@cogent/client/apps/homeowner/general/settings/settings.component';
import { RequestServiceComponent } from '@cogent/client/shared/components/service/request-service/request-service';
import { ViewPolicyComponent } from '@cogent/client/apps/homeowner/general/view-policy/view-policy.component';
import { AddCoverageMenuComponent } from '@cogent/client/apps/homeowner/sales/add-coverage-menu/add-coverage-menu.component';
import { AttachPolicyComponent } from '@cogent/client/apps/homeowner/registration/attach-policy/attach-policy.component';
import { ResetPasswordComponent } from '@cogent/client/apps/homeowner/security/reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { ViewOnMyWayComponent } from '@cogent/client/apps/homeowner/service/view-on-my-way/view-on-my-way.component';
import { ErrorComponent } from '@cogent/client/apps/homeowner/general/error/error.component';
import { NewOrderComponent } from '@cogent/client/apps/homeowner/sales/new-order/new-order.component';
import { UpdateCustomerCreditCardComponent } from '@cogent/client/apps/homeowner/accounting/update-customer-credit-card/update-customer-credit-card.component';
import { CustomerOnboardingComponent } from '@cogent/client/apps/homeowner/registration/customer-onboarding/customer-onboarding.component';
import { ExistingCustomerOnboardingComponent } from '@cogent/client/apps/homeowner/registration/existing-customer-onboarding/existing-customer-onboarding.component';
import { PayIndividualInvoiceComponent } from '@cogent/client/apps/homeowner/accounting/pay-individual-invoice/pay-individual-invoice.component';
import { UploadPhotosComponent } from '@cogent/client/shared/components/service/upload-photos/upload-photos.component';
import { RealtorPortalLoginComponent } from '@cogent/client/apps/homeowner/sales/realtor-portal-login/realtor-portal-login.component';
import { DocumentUploadRouteComponent } from '@cogent/client/apps/homeowner/general/document-upload-route/document-upload-route.component';
import { AcceptAlternateTimeComponent } from '@cogent/client/apps/homeowner/service/accept-alternate-time/accept-alternate-time.component';
import { ViewCoverageComponent } from '@cogent/client/apps/homeowner/service/view-coverage/view-coverage.component';
import { CreateFollowUpComponent } from '@cogent/client/apps/homeowner/service/create-follow-up/create-follow-up.component';
import { PartsTrackingComponent } from '@cogent/client/apps/homeowner/service/parts-tracking/parts-tracking.component';
import { ApproveCashOutComponent } from '@cogent/client/apps/homeowner/service/approve-cash-out/approve-cash-out.component';
import { NotificationsComponent } from '@cogent/client/apps/homeowner/general/notifications/notifications.component';
import { PrivacyComponent } from '@cogent/client/shared/components/website-parts/privacy/privacy.component';
import { CalendarComponent } from '@cogent/client/apps/homeowner/general/calendar/calendar.component';
import { MaintenanceServicePropertyViewComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maintenance-service-property-view/maintenance-service-property-view.component';
import { MaintServicesRateServiceComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-rate-service/maint-services-rate-service.component';
import { LandingPageComponent } from '@cogent/client/apps/homeowner/general/landing-page/landing-page.component';
import { AppsComponent } from '@cogent/client/apps/homeowner/general/apps/apps.component';
import { MaintServicesRequestAddOnComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-request-add-on/maint-services-request-add-on.component';
import { PaymentReportComponent } from '@cogent/client/apps/homeowner/accounting/payment-report/payment-report.component';
import { CustomerTestComponent } from '@cogent/client/apps/homeowner/general/customer-test/customer-test.component';
import { HomeownerWorkOrderDetailTwoComponent } from '@cogent/client/apps/homeowner/service/homeowner-work-order-detail-two/homeowner-work-order-detail-two.component';
import { EnterCreditCardFromWorkflowComponent } from '@cogent/client/apps/homeowner/general/enter-credit-card-from-workflow/enter-credit-card-from-workflow.component';
import { ApproveAddOnBidComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/approve-add-on-bid/approve-add-on-bid.component';
import { CustomerRegisterComponent } from '@cogent/client/shared/components/customer-portal/register/register.component';
import { ApproveContactorProposedWorkOrderComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/approve-contactor-proposed-work-order/approve-contactor-proposed-work-order.component';
import { LoginComponent } from '@cogent/client/apps/homeowner/security/login/login.component';
import { RequestServiceQutoLoginComponent } from '@cogent/client/shared/components/customer-portal/request-service-auto-login/request-service-auto-login.component';
import { RequestDeleteAccountComponent } from '@cogent/client/shared/components/customer-portal/request-delete-account/request-delete-account.component';
import { OrderMaintenanceServiceComponent } from '@cogent/client/shared/components/maintenance-service/order-maintenance-service/order-maintenance-service.component';
import { AutoAuthenticateComponent } from '@cogent/client/apps/homeowner/security/auto-authenticate/auto-authenticate.component';
import { ContactComponent } from "@cogent/client/apps/homeowner/general/contact/contact.component";
import { MaintServicesLandingComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-landing/maint-services-landing.component';
import { Quote2Component } from "@cogent/client/apps/homeowner/sales/new-quote/quote2.component";
import { DeleteAccountComponent } from '@cogent/client/shared/components/delete-account/delete-account.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('@upkeeplabs/authentication').then(mod => mod.AuthenticationModule)
    },
    {
        path: 'work-order/:id',
        redirectTo: 'job-detail/:id'
    },
    {
        path: 'job-detail/:id',
        component: HomeownerWorkOrderDetailTwoComponent,
        data: { title: 'Job Detail' },
        canActivate: [AuthGuardLocalService],
    },
    {
        path: 'survey/:id',
        component: WorkOrderSurveyComponent,
        data: { title: 'Survey' },
    },
    {
        path: 'maintenance-service-property/:id',
        component: MaintenanceServicePropertyViewComponent,
        data: { title: 'Maintenance Services' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'maintenance-service-add-service/:id',
        data: { title: 'Add Service' },
        component: OrderMaintenanceServiceComponent,
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'maintenance-service-add-service/:id/:maintenanceServiceId',
        data: { title: 'Add Service' },
        component: OrderMaintenanceServiceComponent,
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'maintenance-service/portal-order',
        component: OrderMaintenanceServiceComponent,
        data: { title: 'Order Maintenance Services' }
    },
    {
        path: 'maintenance-service/portal-order/:propId',
        component: OrderMaintenanceServiceComponent,
        data: { title: 'Order Maintenance Services' }
    },
    {
        path: 'maintenance-service/rate-service/:id',
        component: MaintServicesRateServiceComponent,
        data: { title: 'Rate Your Service' }
    },
    {
        path: 'authenticate/:entityId/:redirectTarget',
        component: AutoAuthenticateComponent,
        data: { title: 'Auto-Authenticate' }
    },
    {
        path: 'maintenance-service',
        component: MaintServicesLandingComponent,
        data: { title: 'Get a Quote! Home Maintenance Services' }
    },
    {
        path: '',
        component: HomeownersHomeComponent,
        data: { title: 'Homeowners' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'notifications',
        component: NotificationsComponent,
        data: { title: 'Notifications' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'calendar',
        component: CalendarComponent,
        data: { title: 'Upcomming Appointments' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'approve-add-on/:id',
        component: ApproveAddOnBidComponent,
        data: { title: 'Approve Bid' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'schedule-follow-up/:id',
        component: CreateFollowUpComponent,
        data: { title: 'Schedule Follow-Up' },
    },
    {
        path: 'request-follow-up-appointment/:id',
        loadComponent: () => import('@cogent/client/apps/homeowner/service/request-installation-preferred-times/request-installation-preferred-times.component').then(r => r.RequestInstallationPreferredTimesComponent),
        data: {
            title: 'Request Follow-Up Times'
        }
    },
    {
        path: 'parts-tracking/:id',
        component: PartsTrackingComponent,
        data: { title: 'Parts Tracking' },
    },
    {
        path: 'privacy',
        component: PrivacyComponent,
        data: { title: 'Privacy' }
    },
    {
        path: 'scheduled-maintenance',
        component: OrderMaintenanceServiceComponent,
        data: { title: 'Scheduled Maintenance' }
    },
    {
        path: 'approve-cash-out/:id',
        component: ApproveCashOutComponent,
        data: { title: 'Approve Cash Out' }
    },
    {
        path: 'request-service/:id',
        component: RequestServiceComponent,
        data: { title: 'Request Service' },
    },
    {
        path: 'maintenance-service-new-order/:policyId',
        component: OrderMaintenanceServiceComponent,
        data: { title: 'Order Maintenance Services' },
    },
    {
        path: 'maintenance-service-new-order/:policyId/:maintenanceServiceId',
        component: OrderMaintenanceServiceComponent,
        data: { title: 'Order Maintenance Services' },
    },
    {
        path: 'make-payment',
        component: MakePaymentComponent,
        data: { title: 'Make Payment' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'make-payment/:customerId',
        component: MakePaymentComponent,
        data: { title: 'Make Payment' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'contact',
        component: ContactComponent,
        data: { title: 'Contact Us' }
    },
    {
        path: 'register',
        component: CustomerRegisterComponent,
        data: { title: 'Register' },
    },
    {
        path: 'find-property',
        component: FindPolicyComponent,
        data: { title: 'Find Policy' },
    },
    {
        path: 'request-service-auto',
        component: RequestServiceQutoLoginComponent,
        data: { title: 'Request Service' },
    },
    {
        path: 'approve-proposed-job/:id',
        component: ApproveContactorProposedWorkOrderComponent,
        data: { title: 'Approve Job' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'view-property/:id',
        component: ViewPolicyComponent,
        data: { title: 'View Property' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'select-property',
        component: ChoosePropertyComponent,
        data: { title: 'Select Property' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'renew/:id',
        component: RenewComponent,
        data: { title: 'Renew Subscription' },
    },
    {
        path: 'auto-renew:id',
        component: AutoRenewComponent,
        data: { title: 'Manage Auto Renewal' },
    },
    {
        path: 'real-estate-login/:id/:entityId',
        component: RealtorPortalLoginComponent,
    },
    {
        path: 'order',
        component: OrderComponent,
        data: { title: 'Order New Coverage' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'new-order',
        component: NewOrderComponent,
        data: { title: 'New Order' },
    },
    {
        path: 'settings',
        component: SettingsComponent,
        data: { title: 'Settings', },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'request-add-on/:id',
        component: MaintServicesRequestAddOnComponent,
        data: { title: 'Request Add-On' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'add-coverage-menu',
        component: AddCoverageMenuComponent,
        data: { title: 'Add Coverage' },
        canActivate: [AuthGuardLocalService],
    },
    {
        path: 'view-coverage/:id',
        component: ViewCoverageComponent,
        data: { title: 'View Item Coverage' }
    },
    {
        path: 'attach',
        component: AttachPolicyComponent,
        data: { title: 'Attach Subscription' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'view-on-my-way/:id',
        component: ViewOnMyWayComponent,
        data: { title: 'Technician En Route' },
    },
    {
        path: 'update-expiration-date/:stripeCardId/:customerId',
        component: UpdateCustomerCreditCardComponent,
        data: { title: 'Update Credit Card' }
    },
    {
        path: 'onboarding/:key',
        component: CustomerOnboardingComponent,
        data: { title: 'Customer Onboarding' }
    },
    {
        path: 'existing-onboarding/:key',
        component: ExistingCustomerOnboardingComponent,
        data: { title: 'Welcome' }
    },
    {
        path: 'pay-individual-invoice/:id',
        component: PayIndividualInvoiceComponent,
        data: { title: 'Pay Invoice' }
    },
    {
        path: 'share-photos/:id',
        component: UploadPhotosComponent,
        data: { title: 'Share Photos' },
    },
    {
        path: 'error',
        component: ErrorComponent,
        data: { title: 'Something Went Wrong' },

    },
    {
        path: 'accept-alternate/:id/:timeSlotId',
        component: AcceptAlternateTimeComponent,
        data: { title: 'Accept Alternate' }
    },
    {
        path: 'upload-closing-documents/:id',
        component: DocumentUploadRouteComponent,
        data: { title: 'Upload Closing Documents' },
    },
    {
        path: 'landing/:id',
        component: LandingPageComponent,
        data: { title: 'Landing Page' },
    },
    {
        path: 'quote-type',
        loadComponent: () => import('../../../../../apps/src/client/apps/homeowner/quote-type-selection/quote-type-selection.component').then(c => c.QuoteTypeSelectionComponent)
    },
    {
        path: 'apps',
        component: AppsComponent,
        data: { title: 'Get the Apps' }
    },
    // {
    //     path: 'quote',
    //     component: QuoteComponent,
    //     data: { title: 'Get A Quote' }
    // },
    // {
    //     path: 'quote/:version',
    //     component: QuoteComponent,
    //     data: { title: 'Get A Quote' }
    // },
    // {
    //     path: 'quote/:version/:source',
    //     component: QuoteComponent,
    //     data: { title: 'Get A Quote' }
    // },
    {
        path: 'quote',
        component: Quote2Component,
    },
    {
        path: 'quote/:version',
        component: Quote2Component,
    },
    {
        path: 'quote/:version/:source',
        component: Quote2Component,
    },
    {
        path: 'payment-report',
        component: PaymentReportComponent,
        data: { title: 'Payment Report' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'new-card/:entityId/:workFlowInstanceId',
        component: EnterCreditCardFromWorkflowComponent,
        data: { title: 'New Card' }
    },
    {
        path: 'test',
        // component: CustomerTestComponent,
        loadComponent: () => import('@cogent/client/shared/components/sales/quote-and-order/quote-and-order.component').then(c => c.QuoteAndOrderComponent),
        data: { title: 'Test Page' },
    },
    {
        path: 'delete-account',
        component: RequestDeleteAccountComponent,
        data: { title: 'Delete Account' },
        canActivate: [AuthGuardLocalService]
    },
    {
        path: 'delete-account-info',
        component: DeleteAccountComponent,
        data: { title: 'Delete Account' }
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
            tite: 'Login'
        }
    },
    { path: 'security/register', component: CustomerRegisterComponent, data: { title: 'New Account' } },
    { path: 'security/reset-password', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
    // { path: '', redirectTo: '/security/login', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {}), AutoRenewComponent],
    exports: [RouterModule]
})
export class AppRoutingModule { }
