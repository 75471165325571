<div mat-dialog-content>
  <h1 class="page-title">
    <span class="big">Cancel</span><br>

    <span class="little">
      Service Request</span>
  </h1>
  <img src="assets/images/cancel-circle.png" style="height: 70px;vertical-align: top;">

  <p topmargin30 class="info">
    Are you sure you want to cancel this service request?
  </p>
</div>

<div mat-dialog-actions style="display: block; text-align: right">
  <button mat-button [mat-dialog-close]="false">Never Mind</button>
  <button mat-button class="btn-small" [disabled]="cancelling" mat-raised-button color="primary" (click)="performCancellation()"
    cdkFocusInitial>
    <mat-spinner class="thirty" *ngIf="cancelling"></mat-spinner>
    Cancel My
    Request
  </button>
</div>