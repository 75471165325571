import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServicePaymentItemSummary {
    @keyColumn() id: string;
    @column() paymentId: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() amount: number;
    @column() invoiceId: string;
    @column() creditId: string;
    @column() memo: string;
    @column() invoiceNumber: bigint;
    @column() invoiceAmount: number;
    @column() invoiceAmountDue: number;
    @column() dueDate: Date;
    @column() policyNumber: number;
    @column() description: string;
    @column() creditNumber: number;
    @column() propertyAddress1: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() loginId: string;
    @column() paymentNumber: bigint;
    @column() propertyManagerId: string;
}