<div aflex>
    <div>
        <h2 class="sub-heading">Auto Renew</h2>
    </div>
    <div nogrow>
        <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>

    <div *ngIf="!policySummary.autoRenew">
        <p class="info"  style="max-width: 500px;">
            Your coverage will expire at the end of the contract term {{policySummary.expirationDate | date:
            'shortDate'}}. After the
            end of the contract term, your subscription will go month-to-month. You will not be charged until the end of
            your current term.
            <br>
            <br>
            Please select the payment method to use.
        </p>
        <app-payment-method-entry *ngIf="policySummary" [customerId]="policySummary.holderId"
            [(selectedCard)]="selectedCard"></app-payment-method-entry>
    </div>
    <div *ngIf="policySummary.autoRenew" topmargin30>
        <p class="info" style="max-width: 500px;">
            Your coverage will expire at the end of the contract term {{policySummary.expirationDate | date:
            'shortDate'}}. After the
            end of the contract term, your subscription coverage will end.
        </p>
        <label>Reason</label>
        <mat-radio-group [(ngModel)]="cancelAutoRenewReason">
          @for (reason of cancelAutoRenewReasons; track reason) {
            <mat-radio-button [value]="reason">{{reason}}</mat-radio-button>
          }
        </mat-radio-group>
      <mat-form-field appearance="outline" *ngIf="cancelAutoRenewReason == 'Other'">
        <input matInput [(ngModel)]="otherReasonValue">
      </mat-form-field>
    </div>


</div>

<div mat-dialog-actions>
    <div style="width: 100%;" aflex>
        <div></div>
        <div nogrow>
            <button mat-button mat-raised-button color="primary" [disabled]="!canSave" (click)="save()">
                <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                Save
            </button>
        </div>
    </div>
</div>
