<!-- <p>pay-individual-invoice works!</p>
 -->
<div class="container fancy-container hide-tabs" style="padding-top: 0">

    <mat-tab-group [(selectedIndex)]="selectedIndex">

        <mat-tab label="First">

            <div>
                <div padding>
                    <h2 class="sub-heading">Make Payment

                        <a mat-button [href]="invoiceUrl" target="_blank">
                            <mat-icon>description</mat-icon>
                            View Invoice
                        </a>
                    </h2>
                </div>

                <ng-container *ngIf="invoice && !invoicePaid">
                    <div flex padding>
                        <div class="description">
                            {{invoice.description}}
                        </div>
                        <div nogrow>
                            <app-display-total [total]="invoiceAmount" label="Amount Due"></app-display-total>
                        </div>
                    </div>



                    <div class="padding">
                        <app-payment-method-entry [customerId]="invoice.holderId" paymentDescription="Invoice Payment"
                            [paymentAmount]="invoiceAmount" [canDelete]="false" newCardButtonText="Make Payment"
                            [(addingNewCard)]="addingNewCard" [(selectedCard)]="selectedCard"  [allowOneTimeUse]="true"
                            (selectedCardChange)="paymentValid($event)" (newCardCreated)="newCardCreated()">
                        </app-payment-method-entry>
                    </div>
                    <div>
                        <!-- <mat-checkbox *ngIf="selectedCard" [(ngModel)]="useThisCardNextTime">Use this card for
                            future payments</mat-checkbox> -->
                    </div>
                    <ng-container *ngIf="!addingNewCard">
                        <div padding>

                            <button mat-button mobile-block mat-raised-button color="primary"
                                [disabled]="!selectedCard || saving" (click)="makePayment()">
                                <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                                Make Payment
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </mat-tab>
        <mat-tab label="Second">
            <div class="center">
                <div topmargin40>

                </div>
                <!-- <img style="height: 150px;" src="../../../assets/images/check-circle.png"> -->
                <app-check-circle-animated height="150px"></app-check-circle-animated>
                <h1 topmargin20>Payment Complete</h1>
                <p>Thank you for your prompt payment.</p>
            </div>
        </mat-tab>
    </mat-tab-group>




</div>