<div id="measure-element-1"></div>
<mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
    <mat-tab label="First">
        <div class="container">
            <a href="https://elevatehomescriptions.com/">
                <img class="logo"
                    src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/elevate-logo-homescriptions.svg">
            </a>
            <div class="two-column first-section">

                <div>
                    <h1>Take Your Weekends Back</h1>
                    <p>Elevate offers recurring services from house cleaning to pest control, lawn care, pool
                        maintenance, and more. We've got you covered!</p>
                    <p>Enter your address below to see pricing for services in your area.</p>
                    <div id="measure-element"></div>
                    <div [class.address-fixed]="addressFixed" [class.show-quote-details]="showQuoteDetails">
                        <div style="max-width: 500px">
                            <div *ngIf="addressFixed" class="center">
                                <app-stop-watch height="30px"></app-stop-watch><br>
                                Get a quote in seconds.
                            </div>
                            <div class="quick-quote-form">
                                <div topmargin30 style="position: relative; max-width: 500px;" *ngIf="!entityId">

                                    <div>
                                        <app-single-address-entry #addressEntry [loadPropertyMeta]="true"
                                            (addressChange)="addressCompleteChange($event)"
                                            (popupOpen)="setSelect($event)" [(editMode)]="editingAddress"
                                            [(address)]="quote.address"></app-single-address-entry>
                                    </div>
                                    <form>

                                        <mat-form-field appearance="outline">
                                            <mat-label>Your Name *</mat-label>
                                            <input matInput name="q-name" id="q-name" [(ngModel)]="quote.name">
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" style="max-width: 300px;">
                                            <mat-label>Phone # *</mat-label>
                                            <input matInput name="q-phone" type="tel" mask="(000) 000-0000" id="q-phone"
                                                [(ngModel)]="quote.phone">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" style="max-width: 450px;">
                                            <mat-label>Email *</mat-label>
                                            <input matInput name="q-email" type="email" id="q-email"
                                                [(ngModel)]="quote.email">
                                        </mat-form-field>

                                        <button [disabled]="loading" mat-button mat-raised-button color="primary" class="small-primary"
                                            (click)="next()">
                                            <mat-spinner *ngIf="loading" class="thirty"></mat-spinner>
                                            Next
                                        </button>
                                        <button mat-button class="close-quote-details-btn" (click)="showQuoteDetails = false">
                                            <mat-icon>close</mat-icon> Hide
                                        </button>
                                    </form>
                                    <button (click)="getFirstAddress()" *ngIf="editingAddress && selectVisible"
                                        mat-button mat-raised-button style="right: 5px; top: 5px; position: absolute;"
                                        class="small-primary gradient-btn">

                                        Select

                                    </button>


                                </div>

                                <div topmargin30 *ngIf="entityId">

                                    <a mat-button [routerLink]="autoHref" mat-raised-button color="primary"
                                        class="arrow-btn">
                                        See Pricing
                                    </a>
                                </div>
                            </div>
                            <div class="center show-quick-quote-form">

                                <button mat-button mat-raised-button class="primary-small small-primary"  (click)="showQuoteDetails = !showQuoteDetails" color="primary">Start</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="fluid-width-video-wrapper">
                    <lottie-player *ngIf="selectedIndex == 0"
                        src="https://lottie.host/2f2712dc-5699-45b6-9824-7dad5c5ac27e/9VDEqxGeCs.json"
                        background="transparent" speed="1" intermission="5000" loop autoplay>
                    </lottie-player>
                </div>
            </div>
            <div class="two-column section-separation" style="margin-top: 100px;">
                <div>
                    <h1>Pest Control with Elevate</h1>

                    <h2 class="call-out">WHAT’S INCLUDED:</h2>
                    <ul>
                        <li>Pests</li>
                        <li>Mosquitos</li>
                        <li>Spiders</li>
                        <li>Ants</li>
                        <li>Earwigs</li>
                        <li>Roaches</li>
                        <li>Limited mice treatment</li>
                        <li>And more!</li>
                    </ul>

                    <h2 class="call-out" style="margin-top: 50px; padding-bottom: 0;">BUG FREE GUARANTEE</h2>
                    <p>All of our treatments come with a 100% bug free re-treat guarantee!
                        If you see bugs we’ll come back out free of charge between services.</p>

                    <p>Low monthly payments. No contract. Cancel at anytime. </p>
                </div>
                <div>

                    <img class="lifestyle-img"
                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/hms-landing/elevate-2.png">
                </div>
            </div>

            <div class="two-column section-separation">
                <div>
                    <h1>Leave the Dirty Work to Us</h1>
                    <p>Tired of spending your weekends wrestling dust bunnies and scrubbing toilets?

                        With Elevate Homescriptions, you can say goodbye to the hassle of cleaning and hello to more
                        time for the things you love. <br><br>Our recurring home cleaning services, available weekly,
                        bi-weekly, or monthly, ensure your home stays sparkling without you lifting a finger. </p>
                </div>
                <div>
                    <img class="lifestyle-img"
                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/hms-landing/house-cleaning.jpg">
                </div>
            </div>


            <div class="two-column section-separation">
                <div>
                    <h1>Let’s Talk About Pool Maintenance</h1>

                    <h2 class="call-out">WHAT’S INCLUDED:</h2>
                    <p>Chemicals</p>
                    <ul>
                        <li>Complete Water Analysis</li>
                        <li>Maintain Chemical Levels</li>
                        <li>Administer Top of the Line Chemicals (basic chemicals included)</li>
                    </ul>

                    <p>Cleaning</p>
                    <ul>
                        <li>Empty Skimmer & Pump Baskets</li>
                        <li>Skim Surface and Bottom Debris</li>
                        <li>Brush Walls & Steps</li>
                        <li>Vacuum Pool Floor (as needed)</li>
                    </ul>
                </div>
                <div>

                    <img class="lifestyle-img"
                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/hms-landing/pool-picture.png">
                </div>
            </div>


            <div class="two-column section-separation">
                <div>
                    <h1>The grass is always greener with Elevate!</h1>

                    <p>Are you tired of spending your weekends sweating over the lawn mower? Introducing our Weekly Lawn
                        Mowing Service – the ultimate solution to a lush, immaculate lawn that will be the envy of the
                        neighborhood without lifting a finger. </p>

                    <p>Sign up today and elevate your lawn game to the next level.</p>
                </div>
                <div>

                    <img class="lifestyle-img"
                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/hms-landing/lawn.jpg">
                </div>
            </div>

            <div class="section-separation center">
                <h1 class="orange">Looking to take your home to the next level? Bundle your services to create a
                    Homescription!
                </h1>
                <h1>What is a Homescription?</h1>
                <p class="bold">Home Warranty + Maintenance Services</p>
                <p>Our plans help ensure you never have any worries about what needs fixing around the house. You get
                    everything
                    you need when it’s convenient for you, and there’s no need to waste time looking up repair or
                    service
                    providers.</p>

                <div flex>
                    <div flex1 class="simple-box">
                        <h2>Home Warranty Coverage</h2>
                        <p>Repair & replacement coverage for the following:</p>

                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                            Central Air Conditioning System</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                            Central Heating System</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                            Plumbing System Leaks</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                            Plumbing Stoppages</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png"> Water
                            Heaters</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                            Sprinkler System</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                            Range/Oven/Cooktop</p>
                        <mat-accordion>
                            <mat-expansion-panel hideToggle [(expanded)]="warrantyExpanded">

                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Built-In Microwaves</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Dishwashers</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Trash Compactors</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Kitchen Refrigerator & Ice Maker</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Washer & Dryer</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Electrical System</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Ceiling & Exhaust Fans</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Garage Door System</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Central Vacuum Cleaner</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Pool/Spa</p>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <a class="expansion-link" (click)="warrantyExpanded = !warrantyExpanded">
                            <span *ngIf="warrantyExpanded">Show Less</span>
                            <span *ngIf="!warrantyExpanded">Show All</span>
                        </a>

                    </div>
                    <div nogrow class="plus-sep">

                    </div>
                    <div flex1 class="simple-box">
                        <h2>Optional Maintenance Services</h2>
                        <p>Recurring &amp; one-time maintenance services</p>

                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png"> House
                            Cleaning Service</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png"> Pest
                            Control Service</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png"> Lawn
                            Mowing, Edging, and Blowing</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png"> Shrub,
                            Bush, and Tree Trimming</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png"> Lawn
                            Weed and Fertilization Service</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png"> Pool
                            Maintenance Service</p>
                        <p><img src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png"> Window
                            Cleaning</p>

                        <mat-accordion>
                            <mat-expansion-panel hideToggle [(expanded)]="servicesExpanded">

                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Semi-Annual A/C & Heater Tune-up</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Semi-Annual Appliance Tune-up</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Handyman To-Do Knockout Service</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Re-Key Service</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Carpet Cleaning</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Dryer Vent Cleaning</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Garage Door System Tune-up</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Garbage Bin Cleaning Service</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    TV Wall Mount Installation</p>
                                <p><img
                                        src="https://elevatehomescriptions.com/wp-content/uploads/2023/01/CheckIcon.png">
                                    Smart Home Tech Installation Service</p>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <a class="expansion-link" (click)="servicesExpanded = !servicesExpanded">
                            <span *ngIf="servicesExpanded">Show Less</span>
                            <span *ngIf="!servicesExpanded">Show All</span>
                        </a>


                    </div>
                </div>
            </div>

            <div class="section-separation">
                <p>If you have any questions don’t hesitate to reach out!</p>
                <p>
                    <a href="tel:602-767-0303">602-767-0303</a>
                </p>
                <p>
                    <a href="email:cs@elevatehomescriptions.com">cs&#64;elevatehomescriptions.com</a>
                </p>
            </div>
        </div>


        <!-- <button mat-button class="more-button" (click)="doScroll()" *ngIf="showMore">
            Learn <br>
            More<br>
            <mat-icon>expand_more</mat-icon>
        </button> -->

    </mat-tab>
    <mat-tab label="Second">

        <div *ngIf="selectedIndex == 1">
            <app-order-maintenance-service (goBackParent)="reset()" source="Portal" [isSubComponent]="true"
                [address]="quote.address" [quoteId]="quote.id" [quoteQueryParams]="quote.queryParameters" ></app-order-maintenance-service>
        </div>
    </mat-tab>
</mat-tab-group>
