<div class="container ">

    <div *ngIf="!cashOutOffer">
        <div class="center">
            <mat-spinner style="display: inline-block;"></mat-spinner>
        </div>
    </div>
    <mat-tab-group class="hide-tabs" *ngIf="cashOutOffer" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">
            <div class="mobile-padding">
                <div class="center">

                    <div class="image-container">
                        <img [src]="getThumbnailUrl()">
                    </div>
                    <div class="sub-heading">

                        {{cashOutOffer.itemName}}

                    </div>
                </div>

                <p class="info" topmargin40>
                    You have been offered a cash out in the amount of {{cashOutOffer.amount | currency}} in lieu of
                    repairing/replacing your
                    {{cashOutOffer.itemName}}
                    . We have just a few things to confirm and we can get that cash out processed.

                    <span *ngIf="cashOutOffer.comparableUrl">
                        <br><br>We used a comparable model to estimate the cash out amount. The comparable features can
                        be
                        viewed here:<br><br>
                        <a [href]="cashOutOffer.comparableUrl" target="_blank" mat-button mat-raised-button>
                            View Comparable
                        </a>
                    </span>
                </p>

                <div class="center" topmargin40 bottommargin40>
                    <button mat-button mat-raised-button color="primary" (click)="next()">
                        Choose Delivery Method <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>


                <div topmargin40 *ngIf="termsAndLimitationItem">

                    <div class="info">
                        Interested in the contract terms? <a
                            style="cursor: pointer;text-decoration: underline;color: rgb(85,26,139)"
                            (click)="tAndCVisible = !tAndCVisible">View</a>
                    </div>

                    <div *ngIf="tAndCVisible" class="fade-in-up">
                        <h2 topmargin30 bottommargin10>{{termsAndLimitationItem.heading}}</h2>

                        <div class="t-and-c" [innerHTML]="termsAndLimitationItem.text"></div>
                    </div>
                </div>

            </div>
        </mat-tab>
        <mat-tab label="Second">

            <div class="mobile-padding">
                <button mat-button (click)="selectedIndex = selectedIndex - 1">
                    <mat-icon>chevron_left</mat-icon> Back
                </button>

                <h2 class="sub-heading" bottommargin40>Delivery Method</h2>

                <div class="info" bottommargin40 *ngIf="!selectedCashOutType">
                    How would you like to receive your cash out?
                </div>

                <div class="type-selection-buttons">


                    <button mat-button class="toggle-button"
                        *ngIf="(!selectedCashOutType || selectedCashOutType === 'ECHECK') && cashOutOffer.cashOutEmail"
                        [class.selected]="selectedCashOutType === 'ECHECK'" (click)="selectedCashOutType = 'ECHECK'">
                        <h2>E-Check</h2>
                        <img [class.invert]="selectedCashOutType === 'ECHECK'"
                            src="../../../assets/images/e-check-icon.png">
                        <p>Get it instantly</p>
                    </button>


                    <!-- <button mat-button class="toggle-button" *ngIf="(!selectedCashOutType || selectedCashOutType === 'Venmo') && cashOut.cashOutMobileNumber"
                    [class.selected]="selectedCashOutType === 'Venmo'" (click)="selectedCashOutType = 'Venmo'">
                    <h2>Venmo</h2>
                    <img src="../../../assets/images/venmo-icon.svg">
                    <p>Get it instantly</p>
                </button> -->
                    <button mat-button class="toggle-button offset" [class.selected]="selectedCashOutType === 'Mail'"
                        (click)="selectedCashOutType = 'Mail'"
                        *ngIf="!selectedCashOutType || selectedCashOutType === 'Mail'">
                        <h2>Post Office</h2>
                        <img src="../../../assets/images/mailbox-circle.svg">
                        <p>Arrives in 7-10 business days</p>
                    </button>



                    <button mat-button (click)="selectedCashOutType = null"
                        style="white-space: normal;line-height: 14px;padding: 0;display: inline-block;min-height: 140px;vertical-align: top;margin-left: 20px"
                        *ngIf="selectedCashOutType">
                        <mat-icon style="font-size: 48px;width:48px;height: 48px">clear</mat-icon><br>
                        Choose a different Method
                    </button>



                </div>



                <div class="fade-in-up" *ngIf="selectedCashOutType === 'Mail'">

                    <p class="info" topmargin40>
                        Please verify the mailing address to send the check.
                    </p>
                    <address topmargin40 bottommargin40>
                        {{cashOutOffer.nameOnCheck}}<br>
                        {{cashOutOffer.address1}}<br>
                        <ng-container *ngIf="cashOutOffer.address2">
                            {{cashOutOffer.address2}}<br>
                        </ng-container>
                        {{cashOutOffer.city}}, {{cashOutOffer.state}} {{cashOutOffer.postalCode}}
                    </address>

                    <button mat-button mat-raised-button color="primary" (click)="next()">
                        Looks Good
                    </button>
                    &nbsp;&nbsp;
                    <button mat-button mat-raised-button (click)="badAddress()">
                        That's Not Quite Right
                    </button>
                </div>
                <div *ngIf="selectedCashOutType === 'Venmo'" class="fade-in-up">
                    <!-- <p class="info" topmargin40>
                    Please verify your mobile number. 
                    <br>
                    If you don't have a Venmo account setup with this phone number, Venmo will send you a text to allow you to quickly setup an account and 
                    receive the funds.  If you already have a Venmo account, the money will be transferred to you.
                </p>
                <address topmargin40>
                    <div class="data-label">Mobile Number</div>
                    <h2 class="sub-heading">
                        {{cashOut.cashOutMobileNumber | formatPhoneNumber}}</h2>
                </address>

                <button mat-button mat-raised-button color="primary" (click)="next()">
                    Looks Good
                </button>
                &nbsp;&nbsp;
                <button mat-button mat-raised-button (click)="badAddress()">
                    That's Not Quite Right
                </button> -->
                </div>

                <div *ngIf="selectedCashOutType === 'ECHECK'" class="fade-in-up">
                    <p class="info" topmargin40>
                        Please verify your email address to send the e-check.

                    </p>
                    <address topmargin40 bottommargin40>
                        <div class="data-label">Email Address</div>
                        <h2 class="sub-heading">
                            {{cashOutOffer.cashOutEmail}}</h2>
                    </address>

                    <div aflex>
                        <button mat-button mat-raised-button color="primary" (click)="next()">
                            Looks Good
                        </button>
                        &nbsp;&nbsp;
                        <button mat-button mat-raised-button (click)="badAddress()">
                            That's Not Right
                        </button>
                    </div>


                </div>
            </div>
        </mat-tab>
        <mat-tab label="Third">
            <div class="mobile-padding">

                <button mat-button (click)="selectedIndex = selectedIndex - 1;selectedCashOutType=null">
                    <mat-icon>chevron_left</mat-icon> Back
                </button>

                <div *ngIf="selectedCashOutType === 'Mail'">

                    <div class="center" bottommargin40>
                        <img class="confirm-img" src="../../../assets/images/mailbox-circle.svg">
                        <h2 class="sub-heading">Mail</h2>
                    </div>
                    <div class="info center">
                        We will mail you you a check in the amount of {{cashOutOffer.amount | currency}} to:
                    </div>
                    <address topmargin40 style="padding-left: 20px;">
                        {{cashOutOffer.nameOnCheck}}<br>
                        {{cashOutOffer.address1}}<br>
                        <ng-container *ngIf="cashOutOffer.address2">
                            {{cashOutOffer.address2}}<br>
                        </ng-container>
                        {{cashOutOffer.city}}, {{cashOutOffer.state}} {{cashOutOffer.postalCode}}
                    </address>

                    <div class="center" topmargin40>
                        <button mat-button mat-raised-button large [disabled]="approving" (click)="approve()"
                            color="primary">
                            <mat-spinner class="thirty" *ngIf="approving"></mat-spinner>
                            Approve
                        </button>
                    </div>
                </div>
                <div *ngIf="selectedCashOutType === 'ECHECK'">

                    <div class="center" bottommargin40>
                        <img class="confirm-img" src="../../../assets/images/e-check-icon.png">
                        <h2 class="sub-heading">E-Check</h2>
                    </div>
                    <div class="info center">
                        We will send you an E-Check in the amount of {{cashOutOffer.amount | currency}} to
                        {{cashOutOffer.cashOutEmail}}.
                    </div>
                    <div class="center" topmargin40>
                        <button mat-button mat-raised-button large [disabled]="approving" (click)="approve()"
                            color="primary">
                            <mat-spinner class="thirty" *ngIf="approving"></mat-spinner>
                            Approve
                        </button>
                    </div>
                </div>
                <div *ngIf="selectedCashOutType === 'Venmo'">

                    <!-- <div class="center" bottommargin40>
                    <img class="confirm-img" src="../../../assets/images/venmo-icon.svg">
                    <h2 class="sub-heading">Venmo</h2>
                </div>
                <div class="info center">
                    We will send you a Venmo transfer of {{cashOut.amount | currency}} to {{cashOut.cashOutMobileNumber | formatPhoneNumber}}.
                </div>
                <div class="center" topmargin40>
                    <button mat-button mat-raised-button large [disabled]="approving" (click)="approve()"
                        color="primary">
                        <mat-spinner class="thirty" *ngIf="approving"></mat-spinner>
                        Approve
                    </button>
                </div> -->
                </div>
            </div>
        </mat-tab>

        <mat-tab>

            <div class="center">
                <app-check-circle-animated></app-check-circle-animated>
                <div class="check" *ngIf="cashOutOffer">
                    <div>
                        <div aflex>
                            <div>
                                Elevate Home Warranty<br>
                                259 S Orem Blvd<br>
                                Orem, UT 84058
                            </div>
                            <div nogrow>
                                <h2 bottommargin10>12345</h2>
                                <span class="data-label"> DATE</span> <span class="underline">{{today | date:
                                    'shortDate'}}</span>
                            </div>
                        </div>
                        <div aflex>
                            <div nogrow>
                                <span class="data-label">PAY TO</span>
                            </div>
                            <!-- <div class="underline">
                                {{cashOut.nameOnCheck}}<br>
                                {{cashOut.address1}} {{cashOut.address2}} {{cashOut.city}}, {{cashOut.state}}
                                {{cashOut.postalCode}}
                            </div> -->
                            <div nogrow>
                                <span class="data-label">$</span> &nbsp;<span class="number-amount">
                                    {{cashOutOffer.amount | number : '1.2-2'}}
                                </span>
                            </div>
                        </div>
                        <div aflex>
                            <div class="underline">
                                {{numberWords}}
                            </div>
                            <div nogrow>
                                <span class="data-label">DOLLARS</span>
                                <mat-icon>lock</mat-icon>
                            </div>
                        </div>
                        <div aflex>
                            <div nogrow><span class="data-label">MEMO</span></div>
                            <div class="underline">
                                Cash Out: <span *ngFor="let line of lines">
                                    {{line.itemName}}
                                </span>
                            </div>
                            <div class="underline"></div>
                        </div>
                        <div class="account-numbers">
                            |:000000000 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|:0000000000
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12345
                        </div>



                    </div>
                </div>
                <h2 class="sub-heading" style="margin-top: 160px">
                    Cash Out Approved
                </h2>
                <p class="center">
                    We've got it from here
                </p>



            </div>
        </mat-tab>
    </mat-tab-group>


</div>