import { Injectable } from "@angular/core";
import { ApiService } from '@cogent/client/api';
import { CustomFunction, CustomFunctionExport, FunctionSchedule } from "@upkeeplabs/models/cogent";
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";

@Injectable({ providedIn: 'root' })
export class CustomFunctionApiService {
    getFunctionsForCogentPolicy() {

        return this.api.getArrayNode('CustomFunction', { availableOnCogentPolicy_eq: true }, () => new CustomFunction());
    }

    getFunctionsForCogentTask() {

        return this.api.getArrayNode('CustomFunction', { availableOnCogentTask_eq: true }, () => new CustomFunction());
    }
    getEntitySummary(id: string) {
        return this.api.getSingleNode(`function/entity-summary/${id}`);
    }
    getFunctionsForCogentPurchaseOrder() {
        return this.api.getArrayNode(`CustomFunction`, { availableOnCogentPurchaseOrder_eq: true }, () => new CustomFunction())
    }

    getFunctionsForCogentJob() {

        return this.api.getArrayNode('CustomFunction', { availableOnCogentJobDetail_eq: true }, () => new CustomFunction());
    }

    getFunctionsForCogentAutho() {

        return this.api.getArrayNode('CustomFunction', { availableOnCogentAuth_eq: true }, () => new CustomFunction());
    }

    getFunctionsByTableTrigger(tableName: string) {
        return this.api.getArrayNode(`FunctionTriggerItem`, {tableName_eq: tableName, orderby: 'name'}, ()=> new CustomFunction())
    }

    getFunctionsForSMSPropertyService() {

        return this.api.getArrayNode('CustomFunction', { availableOnMaintenanceServicePropertyService_eq: true }, () => new CustomFunction());
    }

    constructor(private api: ApiService,
        private entityApi: EntityApiService) { }

    saveCustomFunction(customFunction: CustomFunction) {
        return this.api.postSingleNode(`CustomFunction`, customFunction);
    }

    manuallyRunSchedule(id: string, schedule: FunctionSchedule) {
        return this.api.postNode(`function/manually-run-scheduled?functionId=${id}`, schedule);
    }

    resumeFunction(id: string) {
        //resume-function/:id
        return this.api.postNode(`function/resume-function/${id}`, null);
    }

    initializeFunctionForImport(customFunctionExport: CustomFunctionExport) {
        return this.api.postSingleNode(`function/initialize-template-export-package`, customFunctionExport, null, () => new CustomFunctionExport());
    }

    importTemplateExportPackage(customFunctionExport: CustomFunctionExport) {
        return this.api.postNode(`function/import-template-export-package`, customFunctionExport);
    }

    getCategories() {
        return this.api.getArrayNode(`function/function-groups`);
    }

    getCustomFunctionList() {
        return this.api.getArrayNode(`CustomFunction`, { select: 'id,name,iconClass,category', orderby: 'name' }, () => new CustomFunction());
    }

    async getCreatedByUserFunctions() {
        const user = await this.entityApi.getLoggedInUser(false);

        return this.api.getArrayNode('CustomFunction', { createdById_eq: user.id, orderby: 'createdDate desc', select: 'id,name,iconClass,category,createdDate' }, () => new CustomFunction())
    }

    search(searchFor: string) {
        return this.api.getArrayNode('CustomFunction', { functionJson_like: searchFor }, () => new CustomFunction());
    }

    getCustomFunctionById(id: string) {
        return this.api.getSingleNode(`function/function-definition/${id}`, null, () => new CustomFunction());
    }

    deleteFunction(id: string) {
        return this.api.deleteNode(`CustomFunction/${id}`);
    }

    runCustomFunction(id: string, tableName: string = null, objectId: string = null, body: any = null) {
        return this.api.postNode(`function/start/${id}?id=${objectId}&tableName=${tableName}`, body);
    }
}