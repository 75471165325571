import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { InvoiceItem } from "./invoice-item.model";

@dataSource()
export class InvoiceSummary {
    @keyColumn() id: string;
    @column() invoiceNumber: number;
    @column() customerAddress1: string;
    @column() customerAddressCity: string;
    @column() customerAddressState: string;
    @column() customerAddressPostalCode: string;
    @column() customerEmail: string;
    @column() customerName: string;
    @column() amount: number;
    @column() amountDue: number;
    @column() dueDate: Date;
    @column() policyAddress1: string;
    @column() policyAddressCity: string;
    @column() policyAddressState: string;
    @column() policyAddressPostalCode: string;
    @column() companyName: string;
    @column() companyId: string;
    @column() companyAddress1: string;
    @column() companyAddressCity: string;
    @column() companyAddressState: string;
    @column() companyAddressPostalCode: string;
    @column() createdDate: Date;
    @column() holderId: string;
    @column() voidDate: Date;
    @column() policyId: string;
    @column() policyNumber: bigint;
    @column() description: string;
    @column() loginId: string;
    @column() areaId: string;
    @column() stateId: string;
    @column() areaName: string;
    @column() stateName: string;
    @column() status: string;
    @column() invoiceType: string;
    @column() customerHomeNumber: string;
    @column() customerMobileNumber: string;
    @column() exportDate: Date;
    @column() paperless: boolean;
    @column() salesCorrespondenceEmail: string;
    @column() salesCorrespondenceCCEmail: string;
    @column() propertyManagerId: string;
    @column() claimId: string;
    @column() isMonthly: boolean;
    @column() effectiveDate: Date;
    @column() policyCreatedDate: Date;
    @column() expirationDate: Date;
    @column() coverageType: string;
    @column() agentId: string;
    @column() sellerAgentId: string;
    @column() workOrderLineId: string;
    @column() policyStatus: string;
    @column() linesJson: string;
    @column() propertyManagerEmail: string;
    @column() payAfterService: boolean;
    @column() invoiceForServiceFee: boolean;
    @column() billToName: string;
    @column() billToAddress1: string;
    @column() billToAddress2: string;
    @column() billToCity: string;
    @column() billToState: string;
    @column() billToPostalCode: string;
    @column() propertyManagerLoginId?: string;
    @column() policyCanceledDate?: Date;

    get amountPaid() {
        if(this.amountDue > this.amount) {
            return 0;
        }
        return (this.amount ?? 0) - (this.amountDue ?? 0);
    }
    
    _lines: InvoiceItem[];
    get lines(): InvoiceItem[] {
        if (!this._lines) {
            try {
                this._lines = JSON.parse(this.linesJson);
                for(const line of this._lines) {
                    (line as any).subTotal = line.unitPrice * line.quantity;
                }
            } catch { }
        }
    
        return this._lines;
    }

    private static validatePhoneNumber(phoneNumber: string): boolean {
        if (/^[0-9]{10}$/.test(phoneNumber)) {
            return true;
        }
        return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phoneNumber);
    }

    get customerContactNumber() {
        if (InvoiceSummary.validatePhoneNumber(this.customerMobileNumber)) {
            return this.customerMobileNumber;
        }

        return this.customerHomeNumber;
    }











    formatedCreatedDate: string;
    formattedAmount: string;
    formattedAmountDue: string;
    formattedAmountPaid: string;
    formattedDueDate: string;


    // client-side only
    selected;
    type: string;
}

