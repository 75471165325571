import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorTrade {
    @keyColumn() id: string;
    @column() entityId: string;
    @column() tradeId: string;
    @column() deletedDate?: Date;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() onHoldStartDate: Date;
    @column() onHoldEndDate: Date;
    @column() maxCallsPerDay: number;
    @column() minCallsPerDay: number;
    @column() authorizationLimit: number;
    @column() maxDaysBetweenCalls: number;
    @column() maxOutstandingCalls: number;
    @column() laborRate: number;




    contractorName: string;
    tradeName: string;
    constructor(
        entityId = '',
        tradeId = '',
        contractorName = '',
        tradeName = '',
        id = '',
        onHoldStartDate: Date = null,
        onHoldEndDate: Date = null,
        maxCallsPerDay: number = null,
        minCallsPerDay: number = null
    ) {
        this.entityId = entityId;
        this.tradeId = tradeId;
        this.contractorName = contractorName;
        this.tradeName = tradeName;
        this.id = id;
        this.onHoldStartDate = onHoldStartDate;
        this.onHoldEndDate = onHoldEndDate;
        this.maxCallsPerDay = maxCallsPerDay;
        this.minCallsPerDay = minCallsPerDay;
    }
}