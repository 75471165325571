import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class FavorFundExpense {
    @keyColumn() id: string;
    @column() employeeId: string;
    @column() workOrderLineId: string;
    @column() premiumInvoiceId: string;
    @column() serviceFeeInvoiceId: string;
    @column() cashOutId: string;
    @column() amount: number;
    @column() exportDate: Date;
    @column() postDate: Date;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() deletedDate: Date;
    @column() notes: string;
    @column() voidPostDate: Date;
    @column() voidExportDate: Date;

    employeeName: string;
}