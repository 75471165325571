import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PromotionCodeSummary {
    @keyColumn() id: string;
    @column() adjustmentId: string;
    @column() adjustmentItemId: string;
    @column() code: string;
    @column() adjustmentName: string;
    @column() priceAdjustment: number;
    @column() priceAdjustmentType: string;
    @column() entityId: string;
    @column() entityName: string;
    @column() appliesToD2C: boolean;
    @column() appliesToRealEstate: boolean;
    @column() appliesToRenewal: boolean;
    @column() description: string;
    @column() oneTimeUse: boolean;
    @column() internalOnly: boolean;
    @column() marketingSourceId: string;
    @column() expirationDate: Date;
}