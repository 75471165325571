<h1>Renewal Portal <div style="display: inline-block;">
        <mat-spinner class="thirty" *ngIf="saving"></mat-spinner>
    </div>
</h1>

<div class="plan-info-container">
    <mat-card>
        <mat-card-header>
            <h2>Property Address</h2>
        </mat-card-header>
        <mat-card-content>
            {{policyRenewalSummary?.address1}}, {{policyRenewalSummary?.city}}, {{policyRenewalSummary?.state}}
            {{policyRenewalSummary?.postalCode}}
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <h2>Plan</h2>
        </mat-card-header>
        <mat-card-content>
            {{policyRenewalSummary?.planName}}
            <br /><br />
            <button mat-button mat-raised-button class="small-primary" color="primary"
                (click)="showPlanOptions()">Select Plan and Options<i
                    class="material-icons">keyboard_arrow_right</i></button>

        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <h2>Payment Amount</h2>
        </mat-card-header>
        <mat-card-content>
            ${{policyRenewalSummary?.basePriceMonthly + policyRenewalSummary?.optionPriceMonthly}}
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <h2>Auto Renew on {{policyRenewalSummary.expirationDate | date}}</h2>
        </mat-card-header>
        <mat-card-content>
            <mat-slide-toggle [(ngModel)]="policyRenewalSummary.autoRenew"
                (ngModelChange)="save()">{{policyRenewalSummary.autoRenew ? 'On' : "Off"}}</mat-slide-toggle>
        </mat-card-content>
    </mat-card>

    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>Payment Method</h2>
                </mat-panel-title>
                <mat-panel-description>
                    {{selectedStripeCard?.brand}} {{selectedStripeCard?.last4}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <app-payment-method-entry #paymentMethodEntry [hideSaveCardToWalletButton]="false"
                *ngIf="policyRenewalSummary && policyRenewalSummary.customerId" [hideManualCreditCardEntry]="true"
                [(savingPaymentMethod)]="savingPaymentMethod" [canDelete]="false"
                [defaultSelectedCardId]="policyRenewalSummary.stripeCardId"
                (selectedCardChange)="selectedCardChange($event)" [customerId]="policyRenewalSummary.customerId">
            </app-payment-method-entry>
        </mat-expansion-panel>
    </mat-accordion>
</div>