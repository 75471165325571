import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';

@Injectable({ providedIn: 'root' })
export class WebChatApiService {
    endChat(contactId: string) {
        return this.api.deleteNode(`users/sms/end-chat/${contactId}`);
    }
    sendMessage(id: string, message: string, contactFlowId, attachments) {
        return this.api.postSingleNode(`users/sms/web-chat-message`, {
            entityId: id,
            message,
            contactFlowId,
            attachments
        });
    }

    sendAIMessage(messages: any[]) {
        return this.api.postArrayNode(`ai`, messages);
    }

    getChatTranscript(participantToken: string) {
        return this.api.getSingleNode(`users/sms/chat-transcript/${participantToken}`, null, null, true);
    }

    getWebChatInitialContact() {
        return this.api.getSingleNode(`users/sms/get-web-chat-function`);
    }

    getContactConnectedAgent(contactId: string) {
        return this.api.getSingleNode(`users/sms/contact-connnected-agent/${contactId}`);
    }

    startOutboundSMSConversation(to: string, twilioNumber: string, contactFlowId: string) {
        return this.api.postNode(`users/sms/start-outbound-sms`, {
            to,
            twilioNumber, contactFlowId
        });
    }

    constructor(private api: ApiService) { }


}