<div (swipeleft)="swipeLeft()" (swiperight)="swipeRight()" style="overflow: hidden;">
    <mat-tab-group class="hide-tabs" (selectedIndexChange)="setHash($event)" id="pivot-tabs"
        [(selectedIndex)]="selectedIndex">
        <mat-tab label="Overview">
            <div id="policy-header">
                <div class="container no-padding" *ngIf="policySummary">
                    <span class="badge" id="policy-status-badge"
                        [class.green]="policySummary.status === 'Active'">{{policySummary.status}}</span>
                    <div class="policy-header-info">
                        <div aflex>
                            <div nogrow>
                                <img [src]="getStreetViewUrl(policySummary.id)">
                            </div>
                            <div>
                                <h1>{{policySummary.policyNumber}}</h1>
                                <p>{{policySummary.propertyAddress.address1}}
                                    {{policySummary.propertyAddress.address2}}<br>
                                    {{policySummary.propertyAddress.city}}, {{policySummary.propertyAddress.state}}
                                    {{policySummary.propertyAddress.postalCode}}
                                </p>
                            </div>
                            <div topmargin10>
                                <div flex>
                                    <div class="data-label" style="color: white">
                                        Tenants/Other Contacts:
                                    </div>
                                    <div nogrow>
                                        <button style="color: white; margin-top:-4px; padding-right:4px; min-width: 22px" mat-button (click)="selectedIndex = 3">
                                            <mat-icon style="font-size: 14pt">edit</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="tenants?.length > 0" style="max-height: 65px">
                                    <div flex *ngFor="let tenant of tenants">
                                        <span>{{tenant.name}}</span>
                                        <app-phone-link *ngIf="tenant.homeNumber?.number" [phoneNumber]="tenant.homeNumber?.number" icon="phone"></app-phone-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center">
                        <div style="padding-left: 20px; padding-right: 20px">
                            <a mat-button mat-raised-button large color="primary"
                                *ngIf="policySummary.status === 'Active'"
                                routerLink="/request-service/{{policySummary.id}}" mblock>Request
                                Service</a>
                        </div>

                    </div>
                </div>
            </div>

            <div id="backdrop" *ngIf="showRenewalMessage" (click)="showRenewalMessage = false">

            </div>

            <div id="renewal-message" class="fade-in-down" *ngIf="showRenewalMessage">
                <div class="center">
                    <img id="renew-circle" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/renew-circle.png">
                </div>
                <p class="info">
                    It looks like your subscription coverage is set to expire on
                    {{policySummary.expirationDate | date: 'shortDate'}}. Don't miss out on the peace of mind
                    knowing that we've got you covered. Renew today.
                </p>
                <a style="text-align: center" mat-button color="primary" large block mat-raised-button
                    routerLink="/renew/{{policySummary.id}}">Renew</a>

                <button (click)="showRenewalMessage = false" topmargin20 block mat-button mat-raised-button>Not
                    Now</button>
            </div>

            <div *ngIf="policySummary" class="container">

                <div class="three-column-grid">
                    <div class="about-single shadow-box">
                        <div style="display: flex; flex-direction: column; height: 100%">

                            <div style="flex: 1">
                                <div class="icon">
                                    <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/wrench-orange-blob.svg">
                                </div>
                                <h4>{{openWorkOrderCount}}</h4>
                                <p class="data-label">Open service requests.</p>
                            </div>
                            <div style="flex: none">
                                <div aflex>
                                    <div></div>
                                    <div nogrow>
                                        <a mat-button (click)="selectedIndex = 1">See More <i
                                                class="fa fa-chevron-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="about-single  shadow-box">
                        <div style="display: flex; flex-direction: column; height: 100%">

                            <div style="flex: 1">
                                <div class="icon">
                                    <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/credit-card-orange-blob.svg">
                                </div>
                                <h4>{{balanceDue | currency : 'USD': 'symbol-narrow' : '1.0-0'}}</h4>
                                <p class="data-label">Unpaid Invoices</p>

                                <div *ngIf="currentStripeCardLoaded">

                                    <div aflex>
                                        <div>
                                            <div *ngIf="currentStripeCard">
                                                <div *ngIf="currentStripeCard.card && currentStripeCard.card.brand">
                                                    <img class="card-icon" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/visa.png"
                                                        *ngIf="currentStripeCard.card.brand.toLowerCase() === 'visa'">
                                                    <img class="card-icon" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mastercard.png"
                                                        *ngIf="currentStripeCard.card.brand.toLowerCase() === 'mastercard'">
                                                    <img class="card-icon" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/discover.png"
                                                        *ngIf="currentStripeCard.card.brand.toLowerCase() === 'discover'">
                                                    <img class="card-icon"
                                                        src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/americanexpress.png"
                                                        *ngIf="currentStripeCard.card.brand.toLowerCase() === 'amex'">
                                                </div>

                                                {{currentStripeCard.card.brand}} {{currentStripeCard.card.last4}}
                                            </div>
                                            <div *ngIf="!currentStripeCard">
                                                No Payment Method Entered
                                            </div>
                                        </div>
                                        <div nogrow>
                                            <button mat-button (click)="editPaymentMethod()">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style="flex: none">
                                <div aflex>
                                    <div></div>
                                    <div nogrow>
                                        <a mat-button (click)="selectedIndex = 2">See More <i
                                                class="fa fa-chevron-right" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div class="about-single  shadow-box">
                        <div style="display: flex; flex-direction: column; height: 100%">

                            <div style="flex: 1">
                                <div class="icon">
                                    <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/contact-page-orange-blob.svg">
                                </div>
                                <h4 style="font-size: 40px;line-height: 40px;">{{policySummary.holder.name}}</h4>
                                <address *ngIf="policySummary.holderAddress">
                                    {{policySummary.holderAddress.address1}}<br
                                        *ngIf="policySummary.holderAddress.address2">
                                    {{policySummary.holderAddress.address2}}<br>
                                    {{policySummary.holderAddress.city}}, {{policySummary.holderAddress.state}}
                                    {{policySummary.holderAddress.postalCode}}
                                </address>
                            </div>
                            <div style="flex: none">
                                <div aflex>
                                    <div></div>
                                    <div nogrow>
                                        <a mat-button routerLink="/settings">Settings <i class="fa fa-chevron-right"
                                                aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="about-single shadow-box" *ngIf="extraCommands && extraCommands.length > 0">
                    <div class="data-label">Options</div>
                    <button mat-button *ngFor="let command of extraCommands" (click)="executeCommand(command)">
                        <mat-icon>{{command.iconClass}}</mat-icon>
                        {{command.label}}
                    </button>
                </div>
                <div class="two-column-grid" style="margin-top: 30px">
                    <div class="about-single shadow-box">
                        <div class="icon">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/description-orange-blog.svg">
                        </div>
                        <div aflex topmargin30>
                            <div>
                                <h1 id="plan-name">{{policySummary.planName}}

                                </h1>
                            </div>
                            <div nogrow>
                                <a target="_blank" mat-button [href]="getContractUrl">
                                    <mat-icon>description</mat-icon>
                                    View Contract
                                </a>
                            </div>
                        </div>
                        <div>
                            <span class="data-label">Effective:</span>
                            {{policySummary.effectiveDate | date: 'shortDate'}} -
                            {{policySummary.expirationDate | date: 'shortDate'}}
                            <div *ngIf="policySummary.autoRenew">
                                Your coverage will auto-renew at the end of the initial term.<br>
                                <button mat-button mat-raised-button (click)="changeAutoRenewal()">Don't Auto
                                    Renew</button>
                            </div>
                            <div *ngIf="policySummary && !policySummary.autoRenew" topmargin40>
                                Your coverage will expire at the end of the contract term {{policySummary.expirationDate
                                | date: 'shortDate'}}.<br>
                                <button mat-button mat-raised-button (click)="changeAutoRenewal()"
                                    *ngIf="!policySummary.doNotRenew">Auto Renew
                                    Me</button>
                            </div>

                        </div>

                        <div topmargin20>
                            <a style="text-align: center" *ngIf="policySummary.canRenew" mat-button color="primary"
                                large mobile-block mat-raised-button routerLink="/renew/{{policySummary.id}}">Renew</a>
                        </div>
                        <div topmargin30 *ngIf="policySummary.options && policySummary.options.length > 0">

                            <div class="data-label">Options</div>
                            <ul>
                                <li *ngFor="let option of policySummary.options">{{option.salesItemName}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="about-single shadow-box">
                        <div aflex>
                            <div nogrow>
                                <app-circle-widget [percent]="availablePrepaidServiceFeesPct"
                                    [number]="availablePrepaidServiceFeesS" *ngIf="prepaidServiceFees"
                                    label="Available of {{prepaidServiceFees.length}}">
                                </app-circle-widget>
                            </div>
                            <div class="center">
                                <div class="icon">
                                    <span>
                                        <img style="height: 69px;" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/redeem-transparent.png">

                                    </span>
                                </div>
                                <br>
                                <h1 topmargin30 style="font-size: 24px;">Pre-Paid Service Fees</h1>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="two-column-grid" style="margin-top: 30px">
                    <div class="about-single shadow-box" *ngIf="policySummary.coverageType === 'RealEstate'">
                        <div class="icon">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/home-lock-orange-blob.svg">
                        </div>
                        <div topmargin30></div>
                        <app-document-upload [hideIcon]="true" [policySummary]="policySummary"></app-document-upload>

                    </div>
                    <div></div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Service Requests">
            <div id="repair-header">
                <div class="container" *ngIf="policySummary" aflex>
                    <div>
                        <h1 class="page-title"><span class="big">Service</span><br><span class="little">Requests</span>
                        </h1>
                    </div>
                    <div nogrow class="policy-header-info right">
                        <div aflex>

                            <div>
                                <h1>{{policySummary.policyNumber}}</h1>
                                <p>{{policySummary.propertyAddress.address1}}
                                    {{policySummary.propertyAddress.address2}}<br>
                                    {{policySummary.propertyAddress.city}}, {{policySummary.propertyAddress.state}}
                                    {{policySummary.propertyAddress.postalCode}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" id="service-requests-container">
                <div *ngIf="workOrders && workOrders.length === 0" style="margin-top: 163px;text-align: center;">
                    <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/inbox-circle.png" style="height: 150px">
                    <p topmargin20 bottommargin20>
                        It looks like you don't have any service requests. Got a problem? Maybe we can help. Start a
                        service
                        request
                        today.
                    </p>
                    <a mat-button mat-raised-button color="primary" routerLink="/select-property" mobile-block large>New
                        Service Request</a>
                </div>


                <div topmargin40>
                    <a class="service-request-item" *ngFor="let workOrder of workOrders"
                        routerLink="/work-order/{{workOrder.id}}">
                        <div aflex>
                            <div nogrow style="width: 100px;">

                                <img *ngFor="let line of workOrder.lines"
                                    [style.margin-left]="getThumbnailMargin(line, workOrder)"
                                    src="{{getThumbnailUrl(line)}}" class="item-thumbnail" />
                            </div>
                            <div>
                                <span class="badge">{{workOrder.status}}</span>
                                <h3>{{workOrder.itemName}}</h3>
                                <p>Opened: {{workOrder.createdDate | date:'shortDate'}} - {{workOrder.propertyAddress}}
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Payment History">
            <div id="payment-header">
                <div class="container" aflex>
                    <div>
                        <h1 class="page-title"><span class="big">Accounting</span><br><span class="little">Items</span>
                        </h1>
                    </div>
                    <div nogrow *ngIf="policySummary" class="policy-header-info right">
                        <div aflex>

                            <div>
                                <h1>{{policySummary.policyNumber}}</h1>
                                <p>{{policySummary.propertyAddress.address1}}
                                    {{policySummary.propertyAddress.address2}}<br>
                                    {{policySummary.propertyAddress.city}}, {{policySummary.propertyAddress.state}}
                                    {{policySummary.propertyAddress.postalCode}}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="container" *ngIf="policySummary" id="payment-container">
                <div class="about-single shadow-box">
                    <div class="icon">
                        <span><i class="fa fa-balance-scale" aria-hidden="true"></i></span>
                    </div>
                    <h4 id="more-balance">{{balanceDue | currency : 'USD': 'symbol-narrow' : '1.0-0'}}</h4>
                    <p class="center-text">Unpaid Invoices</p>
                    <div flex *ngIf="balanceDue">
                        <div></div>
                        <div nogrow>
                            <a mat-button [href]="statementUrl" target="_blank">
                                <mat-icon>description</mat-icon>
                                View Statement
                            </a>
                            <a mobile-block mat-button mat-raised-button color="primary"
                                routerLink="/make-payment/{{policySummary.holderId}}">Make
                                Payment
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <h1 topmargin30 class="page-title"><span class="big">Invoices</span> </h1>
                <div class="data-label" *ngIf="pastDueInvoices.length > 0">Past Due</div>

                <a mat-button block class="flex service-request-item" *ngFor="let invoice of pastDueInvoices"
                    [href]="getInvoiceHtmlUrl(invoice)" target="_blank">

                    <div aflex>
                        <div nogrow>
                            <mat-icon class="invoice-icon">insert_drive_file</mat-icon>
                        </div>
                        <div>
                            <h3>Amount: {{invoice.amount | currency}} - Due: {{invoice.amountDue | currency}} </h3>
                            <p>Opened: {{invoice.date | date:'shortDate'}} - {{invoice.memo}}</p>
                        </div>

                    </div>
                </a>


                <div class="data-label" *ngIf="paidInvoices.length > 0" topmargin10>Paid</div>

                <a mat-button block class="flex service-request-item" *ngFor="let invoice of paidInvoices"
                    [href]="getInvoiceHtmlUrl(invoice)" target="_blank">

                    <div aflex>
                        <div nogrow>
                            <mat-icon class="invoice-icon">insert_drive_file</mat-icon>
                        </div>
                        <div>
                            <h3>Amount: {{invoice.amount | currency}} - Due: {{invoice.amountDue | currency}} </h3>
                            <p>Opened: {{invoice.date | date:'shortDate'}} - {{invoice.memo}}</p>
                        </div>

                    </div>
                </a>


                <div class="data-label" *ngIf="unpaidInvoices.length > 0" topmargin10>Due Later</div>

                <a mat-button block class="flex service-request-item" *ngFor="let invoice of unpaidInvoices"
                    [href]="getInvoiceHtmlUrl(invoice)" target="_blank">

                    <div aflex>
                        <div nogrow>
                            <mat-icon class="invoice-icon">insert_drive_file</mat-icon>
                        </div>
                        <div>
                            <h3>Amount: {{invoice.amount | currency}} - Due: {{invoice.amountDue | currency}} </h3>
                            <p>Opened: {{invoice.date | date:'shortDate'}} - {{invoice.memo}}</p>
                        </div>

                    </div>
                </a>


                <div topmargin30></div>
                <h1 class="page-title">
                    <span class="big">Payments</span><br>
                </h1>

                <a mat-button block class="flex service-request-item" *ngFor="let payment of payments"
                    [href]="getPaymentHtmlUrl(payment)" target="_blank">

                    <div aflex>
                        <div nogrow>
                            <mat-icon class="invoice-icon">account_balance_wallet</mat-icon>
                        </div>
                        <div>
                            <h3>Amount: {{payment.amount | currency}}</h3>
                            <p>Opened: {{payment.date | date:'shortDate'}} - {{payment.memo}}</p>
                        </div>
                    </div>
                </a>

            </div>
        </mat-tab>
        <mat-tab label="Tenants">
            <div id="tenant-header">
                <div class="container" aflex>
                    <div>
                        <h1 class="page-title"><span class="big">Tenants/Other Contacts</span>
                        </h1>
                    </div>
                </div>
            </div>
            <div class="container" id="tenant-container">
                <button mat-button (click)="addTenant()" style="margin-top:20px;">
                    <mat-icon>add</mat-icon> New Tenant
                </button>

                <div style="min-width: 650px; max-width: 660px;">
                    <div class="table">
                        <div class="table-header">
                            <div flex1>Name</div>
                            <div flex1>Email</div>
                            <div flex1>Phone</div>
                            <div nogrow style="width: 70px"></div>
                        </div>

                        <div class="table-row" *ngFor="let tenant of tenants">
                            <div flex1>
                                <input [(ngModel)]="tenant.name">
                            </div>
                            <div flex1>
                                <input [(ngModel)]="tenant.email">
                            </div>
                            <div flex1>
                                <input [(ngModel)]="tenant.homeNumber.number" mask="(000) 000-0000">
                            </div>
                            <div nogrow style="width: 70px">
                                <button mat-button (click)="deleteTenant(tenant)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div topmargin20 style="text-align: center;">
                        <button mat-button mat-raised-button color="primary" (click)="saveTenants()"
                            [disabled]="savingTenants && (tenants?.length>0 || tenantsToDelete?.length>0)">
                            <mat-spinner *ngIf="savingTenants" class="thirty"></mat-spinner> Save
                        </button>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>