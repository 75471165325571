import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, AfterViewInit} from '@angular/core';

@Component({
    selector: 'app-customer-footer',
    templateUrl: './customer-footer.component.html',
    styleUrls: ['./customer-footer.component.scss'],
    standalone: true
})
export class CustomerFooterComponent{

    constructor() { }

    currentYear = new Date().getFullYear()
}
