import { SearchResultModel } from "../common/search-result.model";

export class CustomerCareWallBoardConfig {

    items: WallBoardItem[] = [];

}

export class WallBoardItem {
    type: 'EMPLOYEE-OF-THE-MONTH' | 'VIDEO' | 'TRIVIA' | 'GENERIC-HTML' | 'IN-QUEUE-INTERRUPT' | 'WIG_SCORE' | 'RAIN' | 'BIRTHDAY';
    animationType: 'simpleFadeAnimation' | 'moveUp' | 'moveDown';
    displayFor: number;
    enabled: boolean = true;
    intermissionBefore: number;
    intermissionAfter: number;

    contentUrl: string;
    employeeOfTheMonth: SearchResultModel;
    employeeOfTheMonthDescription: string;
    htmlContent: string;

    description: string;
    interruptThreshold: number;

    get descriptionDisplay() {
        return this.description ?? this.type;
    }

    clearFirstName() {
        delete this._employeeOfTheMonthFirstName;
    }

    private _employeeOfTheMonthFirstName: string;
    get employeeOfTheMonthFirstName() {
        if (!this._employeeOfTheMonthFirstName) {
            const idx = this.employeeOfTheMonth?.name?.indexOf(' ');
            if (idx > 0) {
                this._employeeOfTheMonthFirstName = this.employeeOfTheMonth.name.substring(0, idx);
            }
        }

        return this._employeeOfTheMonthFirstName;
    }
}